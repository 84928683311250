import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ExpertiseModel } from '../../shared/models/expertise.model';
import { MetaDataNameModel } from '../../shared/models/metaDataName.model';

@Component({
  selector: 'app-expertises',
  templateUrl: './expertises.component.html',
  styleUrls: ['../skills/skills.component.scss']
})
export class ExpertisesComponent implements OnInit {
  @Output() expertiseEvent = new EventEmitter();
  @Output() addExpertise = new EventEmitter();

  @Input() expertises: MetaDataNameModel[];
  @Input() canEdit: boolean;
  @Input() error: boolean;

  removable = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  onAdd(): void {
    this.addExpertise.emit();
  }

  remove(mySelections): void {
    const index = this.expertises.indexOf(mySelections);
    if (index >= 0) {
      this.expertises.splice(index, 1);
    }
    this.expertiseEvent.emit(this.expertises);
  }
}
