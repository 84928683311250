import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from 'ngx-file-drop';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';
import { UploadService } from '../../../../shared/services/services/upload.service';
import { IUploadURL } from '../../../../shared/models/uploadUrl.model';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { DocumentModel } from '../../../../shared/models/document.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, mergeMap } from 'rxjs/operators';

const maxFileSize = 15000000;
@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnChanges, OnDestroy {
  @Input() candidateData: CandidateModel;
  cv: DocumentModel;
  additionalDocuments: DocumentModel[];
  private subscriptions = [];
  cvFileName: string;

  documentUpload: FormGroup;

  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider,
    private uploadService: UploadService,
    private spinner: NgxSpinnerService
  ) {
    this.documentUpload = this.fb.group({});
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.additionalDocuments = [];
    this.cv = this.candidateData?.cvDocument;
    this.candidateData?.documents.forEach((document) => {
      this.additionalDocuments.push({
        id: Math.random(),
        name: document.name,
        url: document.url,
        mimeType: document.mimeType,
        _id: document._id,
      });
    });
  }

  selectCvFile(event: any): void {
    if (
      event.target.files[0]?.name.split('.').pop() === 'pdf' &&
      event.target.files[0]?.size <= maxFileSize
    ) {
      this.cvFileName = event.target.files[0]?.name;
      this.uploadFile('cv', event.target.files[0]);
    } else {
      alert('file must be pdf and less than 15MB');
    }
  }

  selectAdditionalFiles(event: any): void {
    const files: any[] = event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file?.name.split('.').pop() === 'pdf' && file?.size <= maxFileSize) {
        this.uploadFile('additional', file);
      } else {
        alert('pdf files must be less than 15MB');
        break;
      }
    }
  }
  removeFile(): void {
    this.cv = {
      name: '',
      url: '',
      mimeType: '',
    };
  }

  removeAdditionalFile(id: number): void {
    this.additionalDocuments = this.additionalDocuments.filter((document) => {
      return document.id != id;
    });
  }

  public droppedAdditional(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.name.split('.').pop() === 'pdf' && file.size <= maxFileSize) {
            this.uploadFile('additional', file);
          } else {
            alert('file must be pdf and less than 15MB');
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }

  public droppedCv(files: NgxFileDropEntry[]): void {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (file.name.split('.').pop() === 'pdf' && file.size <= maxFileSize) {
            this.uploadFile('cv', file);
          } else {
            alert('file must be pdf and less than 15MB');
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
  }
  updateCandidate(): void {
    const data: CandidateModel = {
      cvDocument: this.cv,
      documents: this.additionalDocuments,
    };
    const candidateObj: IUpdateCandidate = {
      id: localStorage.getItem('candidateId'),
      candidateObj: data,
    };
    this.subscriptions.push(
      this.techfaceProvider.updateCandidate(candidateObj).subscribe(() => {
        this.spinner.hide();
      })
    );
  }
  private uploadFile(type: string, file: any): void {
    this.spinner.show();
    let url: IUploadURL;
    this.subscriptions.push(
      this.uploadService
        .getUploadUrl(file.name)
        .pipe(
          map((urlObj: IUploadURL) => {
            if (urlObj) {
              url = urlObj;
              return urlObj;
            }
          }),
          mergeMap(() =>
            this.uploadService.uploadFile(
              url.uploadUrl,
              this.renameFile(file, file.name),
              url.contentType
            )
          )
        )
        .subscribe((uploadedFile) => {
          if (uploadedFile.url) {
            if (type === 'cv') {
              this.cv = {
                name: file.name,
                url: url.fetchUrl,
                mimeType: file.type,
              };
            } else {
              if (
                this.additionalDocuments.find(
                  (document) => document.name === file.name
                ) === undefined
              ) {
                this.additionalDocuments.push({
                  id: Math.random(),
                  name: file.name,
                  url: url.fetchUrl,
                  mimeType: file.type,
                });
              }
            }
            this.updateCandidate();
            this.spinner.hide();
          }
        })
    );
  }
  private renameFile(originalFile: File, newName: string): File {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }
  public fileOver(event): void { }

  public fileLeave(event): void { }

  submit(): void {
    this.spinner.show();
    this.updateCandidate();
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
