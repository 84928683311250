import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { NavigationService } from '../../shared/services/navigation/navigation.service';

@Component({
  selector: 'app-queries-page',
  templateUrl: './queries-page.component.html',
  styleUrls: ['./queries-page.component.scss']
})
export class QueriesPageComponent implements OnInit {

  queryId = '610c02bf66a9ed5a2cc93e9d';
  //queryId = null;
  constructor(private navigationService: NavigationService) {
  }

  ngOnInit(): void {
  }

  clickFn(): void {
    if (this.queryId === null || this.queryId === undefined) {
      this.navigationService.navigateByUrl('queries/new');
    } else {
      this.navigationService.navigateByUrl(`queries/edit/${this.queryId}`);
    }
  }

}
