import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../shared/models/user.model';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { BasePageComponent } from '../base-page/base-page.component';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { UserService } from '../../shared/services/services/user.service';
import { map, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserResponseModel } from '../../shared/models/userResponse.model';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordPageComponent
  extends BasePageComponent
  implements OnInit, OnDestroy
{
  loginError = false;
  // login form
  emailForm: FormGroup;
  passwordForm: FormGroup;

  hasToken = false;
  token: string;

  subscriptions = [];
  /**
   *
   * @param fb FormBuilder
   * @param navigationService service to navigation
   * @param route active router
   */
  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    public route: ActivatedRoute,
    private techfaceProvider: TechfaceProvider,
    private userService: UserService
  ) {
    super(route);
  }

  /**
   * login form default value and validation
   */
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.hasToken = !!params.token;
      this.token = params.token;
    });

    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}/
            ),
          ],
        ],
        repeatPassword: [
          '',
          [
            Validators.required,
          ],
        ],
      },
      { validator: this.confirmedValidator('password', 'repeatPassword') }
    );
  }

  confirmedValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  /**
   * return username/email from login form
   */
  get email(): AbstractControl {
    return this.emailForm.get('email');
  }

  /**
   * return password from login form
   */
  get password(): AbstractControl {
    return this.passwordForm.get('password');
  }

  get repeatPassword(): AbstractControl {
    return this.passwordForm.get('repeatPassword');
  }

  /**
   * when click login to submit username/email and password
   * if there is username/email and password set username in
   * local storage and navigate to dashboard page
   * @param form
   */
  async submit(form: FormGroup): Promise<void> {
    this.loginError = false;
    if(!form.valid) return;
    if (form.value.email) {
      await this.userService.requestPasswordReset(form.value.email)
    } else if (form.value.password) {
      await this.userService.confirmPasswordReset(this.token, form.value.password)
    }
    this.navigationService.navigateByUrl('login');
  }
  backToLogin(): void {
    this.navigationService.navigateByUrl('login');
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
