import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LockChanges } from '@ngrx/store-devtools/src/actions';
import { identity } from 'rxjs';
import { take } from 'rxjs/operators';
import { SkillExpertiseStatus } from '../../../../shared/enums/skillExpertiseStatus.enum';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { CandidateExpertiseModel } from '../../../../shared/models/candiddateExpertise.model';
import { CandidateSkillModel } from '../../../../shared/models/candiddateSkill.model';
import { SkillExpertiseByCategoryModel } from '../../../../shared/models/skillExpertiseByCategory.model';
import { SkillsExpertisesModel } from '../../../../shared/models/skillsExpertise.model';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';
import { CandidateService } from '../../../../shared/services/services/candidate.service';

@Component({
  selector: 'app-new-skill-expertise',
  templateUrl: './new-skill-expertise.component.html',
  styleUrls: ['./new-skill-expertise.component.scss'],
})
export class NewSkillExpertiseComponent implements OnChanges, OnDestroy {
  @Input() allDataByCategory: SkillExpertiseByCategoryModel[];
  @Input() type: string;
  @Input() candidateSkillExpertise: CandidateModel;
  @Output() closeEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();

  searchText: string;

  items: string[] = [];
  skillItems: CandidateSkillModel[] = [];
  expertiseItems: CandidateExpertiseModel[] = [];

  candidate: CandidateModel;
  private subscriptions = [];
  constructor(
    private techfaceProvider: TechfaceProvider,
    private candidateService: CandidateService
  ) {}

  ngOnChanges(): void {
    this.setSkillsAndExpertiseSelectedItems();

  }
  /**
   * close add new skill or expertise data
   */
  onClickClose(): void {
    this.closeEvent.emit(false);
  }
  /**
   * select new item
   * @param items
   * @param type of the item
   */
  onSelectItem(items: SkillsExpertisesModel[], type: string, category: any): void {
    console.log(items, category)
    this.allDataByCategory
      .filter(c => c.categoryId == category.categoryId)
      .forEach(c => { c.skillsExpertises = c.skillsExpertises
        .map(ske => { return {...ske, selected: !!items.find(i => i.id == ske.id)} } ) })

    this.setSkillsAndExpertiseSelectedItems();

    switch (type) {
      case 'skill': {
        this.setSkillItems();
        break
      }
      case 'expertise': {
        this.setExpertiseItems();
        break
      }
    }
  }

  /**
   * add new items call update candidate
   */
  onAddData(): void {
    this.saveEvent.emit(this.type == "skill" ? this.skillItems : this.expertiseItems)
    if (localStorage.getItem('candidateId')) {
      this.updateCandidate();
    } else {
      this.onClickClose();
    }
  }

  /**
   * create candidat object then update candidate with new skills and expertises
   */
  updateCandidate(): void {
    const candidateObj: IUpdateCandidate = {
      id: localStorage.getItem('candidateId'),
      candidateObj: this.candidate,
    };

    this.subscriptions.push(
      this.candidateService
        .updateCandidate(candidateObj)
        .subscribe((response) => {
          if (response) {
            this.onClickClose();
          }
        })
    );
  }


  setSkillItems(): void {
    this.candidate = {
      skills: this.skillItems,
    };
  }



  setExpertiseItems(): void {
    this.candidate = {
      expertises: this.expertiseItems,
    };
  }



  /**
   * set arrays from skills and expertises for selected items
   */
  setSkillsAndExpertiseSelectedItems(): void {
    this.skillItems = [];
    this.expertiseItems = []
    this.allDataByCategory.forEach((category) => {
      category.skillsExpertises.forEach((item) => {
        if (item.selected) {
          if (this.type === 'skill') {
            this.skillItems.push(this.bindSkill(item));
          } else if (this.type === 'expertise') {
            this.expertiseItems.push(this.bindExpertise(item));
          }
        }
      });
    });
    console.log(this.skillItems)
  }

  bindSkill(item): CandidateSkillModel {
    return {
      _id: item.id,
      skillId: item.id,
      isfavorite: item?.isfavorite,
      categoryId: item?.category,
      confirmationStatus: item.confirmationStatus,
    };
  }

  bindExpertise(item): CandidateExpertiseModel {
    return {
      _id: item.id,
      expertiseId: item.id,
      isfavorite: item?.isfavorite,
      categoryId: item?.category,
      confirmationStatus: item.confirmationStatus,
    };
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
