<div class="dashboard-container">
  <div class="dashboard-title">
    <div class="greeting">
      <h2>Hello, {{ firstName }} !</h2>
      <p>Good to see you again.</p>
    </div>
    <div class="company-logo">
      <img
        *ngIf="companyInfo?.logo"
        src="{{ publicBaseUrl }}{{ companyInfo?.logo?.url }}"
      />
      <span>{{ companyInfo?.fullCompanyName }}</span>
    </div>
  </div>

  <div class="card query-overview-container">
    <h2 class="card-title">Query Overview</h2>
    <div class="card-body">
      <div class="app-queries-summary-container">
        <app-queries-summary></app-queries-summary>
      </div>
      <app-latest-query></app-latest-query>
    </div>
  </div>
</div>
