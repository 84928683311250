import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IMatch } from '../../interfaces/match.interface';
import { UpdateCompanyModel } from '../../models/updateCompany.model ';
import { QueryCandidateIdsModel } from '../../models/queryCandidateIds.model';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';
import { CompanyCandidateIdsModel } from '../../models/comapnyCandidateIds.model';

export interface IMatchesResponse {
  matches: IMatch[];
}
export interface IMatchResponse {
  match: IMatch;
}
@Injectable({
  providedIn: 'root',
})
export class MatchesService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private microserviceName = 'matches';
  private readonly route = '/';
  private readonly query = 'query';
  private readonly company = 'company';
  private readonly candidate = 'candidate';
  private readonly communication = 'communication';

  constructor(private http: HttpClient) {
    super();
  }

  public getMatchesByQueryId(queryId: string): Observable<IMatchesResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.query}${this.route}${queryId}`;
    return this.http.get<IMatchesResponse>(url);
  }

  public getMatchesByQueriesIdAndCompanyId(queriesId: string[], companyId: string | null): Observable<IMatchesResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}queries`;
    return this.http.post<IMatchesResponse>(url, { queriesIds: queriesId, companyId: companyId });
  }

  public getMatchByQueryIdAndCandidateId(
    queryCandidateIds: QueryCandidateIdsModel
  ): Observable<IMatchResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.query}${this.route}${queryCandidateIds.queryId}`;
    return this.http.get<IMatchResponse>(url);
  }

  public getMatchByComapnyId(companyId: string): Observable<IMatchesResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.company}${this.route}${companyId}`;
    return this.http.get<IMatchesResponse>(url);
  }

  public getMatchByCandidateId(
    candidateId: string
  ): Observable<IMatchesResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.candidate}${this.route}${candidateId}`;
    return this.http.get<IMatchesResponse>(url);
  }

  public updateCompanyStatus(
    updateCompany: UpdateCompanyModel
  ): Observable<IMatchResponse> {
    // tslint:disable-next-line: max-line-length
    const url = `${this.server}${this.microserviceName}${this.route}${this.communication}${this.route}${updateCompany.queryId}${this.route}${updateCompany.candidateId}`;
    return this.http.put<IMatchResponse>(url, updateCompany.companyStatus);
  }

  public updateMatch(
    matchId: string,
    matchBody: any
  ): Observable<IMatchResponse> {
    // tslint:disable-next-line: max-line-length
    const url = `${this.server}${this.microserviceName}${this.route}${matchId}`;
    return this.http.put<IMatchResponse>(url, { match: matchBody });
  }

  public addMsg(newMsg, matchId: string): Observable<IMatchResponse> {
    // tslint:disable-next-line: max-line-length
    const add = 'add';
    const msg = 'msg';
    const url = `${this.server}${this.microserviceName}${this.route}${add}${this.route}${msg}${this.route}${matchId}`;
    return this.http.post<IMatchResponse>(url, newMsg);
  }
}
