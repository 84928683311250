<div class="certificates-container">
  <span>Thank you, {{ candidateCertificates.firstName }}!</span>
  <p style="margin-bottom: 2em;">
    We are currently working on a solution to retrieve certificates automatically from your documents. In the meantime, please add them manually.
  </p>
  <form [formGroup]="certificates" #myForm="ngForm">
    <div formArrayName="certificates">
      <div
        *ngFor="
          let certificate of certificates.get('certificates')['controls'];
          let i = index
        "
      >
        <div class="title-remove">
          <div class="title">CERTIFICATE {{ i + 1 }}</div>
          <div class="remove" (click)="onclickRemove(i)">x</div>
        </div>
        <div class="certificates-wrapper" [formGroupName]="i">
          <div class="line">
            <div>
              <label>Title</label>
              <div>
                <input
                  matInput
                  formControlName="certificateName"
                  placeholder="Please enter certificate title"
                />
              </div>
            </div>
          </div>

          <div class="line">
            <div class="date-from">
              <label>Date</label>
              <div>
                <div>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="from"
                      id="from"
                      name="from"
                      matInput
                      [max]="maxDate"
                      [matDatepicker]="picker"
                      placeholder="Choose a date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker startView="year"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="line">
            <div>
              <label>Institute</label>
              <div>
                <input
                  matInput
                  formControlName="customInformation"
                  placeholder="Please enter institute"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="add-btn btn btn-secondary" (click)="onClickCertificate()">
        Add certificate
      </button>
    </div>
  </form>
</div>
