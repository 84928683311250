import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { IQueriesSummary } from '../../../shared/interfaces/queriesSummary.interface';
import { SharedDataService } from '../../../shared/services/services/shared-data.service';

@Component({
  selector: 'app-queries-summary',
  templateUrl: './queries-summary.component.html',
  styleUrls: ['./queries-summary.component.scss'],
})
export class QueriesSummaryComponent implements OnInit {
  openQueriesNumber: number;
  matchesQueriesNumber: number;
  activeConversationQueriesNumber: number;
  queriesSummaryData: IQueriesSummary[];

  constructor(
    private navigationService: NavigationService,
    private sharedDataService: SharedDataService
  ) {}

  ngOnInit(): void {
    this.getOpenQueriesCount();
    this.getMatchesQueriesCount();
    this.getActiveConversationQueriesCount();
  }

  /**
   * click find query button
   */
  onClickFindQuery(): void {
    this.navigationService.navigateByUrl('queries/new');
  }
  /**
   * get open queries number.
   */
  getOpenQueriesCount(): void {
    this.sharedDataService.currentOpenQueriesCount.subscribe(
      (openQueriesCount) => {
        this.openQueriesNumber = openQueriesCount;
        this.getQueriesSummary();
      }
    );
  }

  /**
   * get matches queries number.
   */
  getMatchesQueriesCount(): void {
    this.sharedDataService.currentMatchesQueriesCount.subscribe(
      (matchesQueriesCount) => {
        this.matchesQueriesNumber = matchesQueriesCount;
        this.getQueriesSummary();
      }
    );
  }

  /**
   * get active conversation queries number.
   */
  getActiveConversationQueriesCount(): void {
    this.sharedDataService.currentActiveConversationQueriesCount.subscribe(
      (activeConversationQueriesCount) => {
        this.activeConversationQueriesNumber = activeConversationQueriesCount;
        this.getQueriesSummary();
      }
    );
  }

  /**
   * get queries summary contain
   * total number of active queries
   * total matches with company
   * totl number of open conversation
   */
  getQueriesSummary(): void {
    this.queriesSummaryData = [
      { number: this.openQueriesNumber, querySummaryDetails: 'ACTIVE QUERIES' },
      { number: this.matchesQueriesNumber, querySummaryDetails: 'MATCHES' },
      {
        number: this.activeConversationQueriesNumber,
        querySummaryDetails: 'ACCEPTED MATCHES',
      },
    ];
  }
}
