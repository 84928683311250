import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'app-candidate-sign-in-email',
  templateUrl: './candidate-sign-in-email.component.html',
  styleUrls: ['./candidate-sign-in-email.component.scss'],
})
export class CandidateSignInEmailComponent implements OnInit {
  signInForm: FormGroup;
  @Output() displayEmitter = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService
  ) {}

  ngOnInit(): void {
    this.signInForm = this.fb.group({
      name: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  submit(form: FormGroup): void {
    if (form.valid) {
      const emitValue = {
        display: false,
        email: form.value.email,
        firstName: form.value.name,
        lastName: form.value.lastName,
      };
      this.displayEmitter.emit(emitValue);
    }
  }
  navigateToLogin(): void {
    this.navigationService.navigateByUrl('login');
  }
  forgotPassword(): void {
    this.navigationService.navigateByUrl('forgot-password');
  }
}
