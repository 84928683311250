import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ICandidateCompany, ICandidateRepeatCompany } from '../../../shared/interfaces/candidateCompany.interface';
import { DurationService } from '../../../shared/services/duration/duration.service';

@Component({
  selector: 'app-cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.scss']
})
export class CvComponent implements OnInit, AfterViewChecked {
  @Input() cv: ICandidateCompany[];
  @Input() canSeeCandidate: boolean;

  public companiesByName: ICandidateRepeatCompany[];

  constructor(private durationService: DurationService, private cdref: ChangeDetectorRef) {

  }

  ngOnInit(): void {
  }


  ngAfterViewChecked(): void {
    if (this.cv) {
      this.companiesByName = this.setCompanyWithCommonName();
      this.setDurationOfCompany();
    }
    this.cdref.detectChanges();
  }

  /**
   * calculate duration between to and from dates
   */
  setDurationOfCompany(): void {
    this.companiesByName.forEach(company => {
      const companyWithTotalDays = [];
      company.companyDetails.forEach(companyDetails => {
        let totalDays = this.durationService.getTotalDays(new Date(companyDetails.from), companyDetails.to ? new Date(companyDetails.to): new Date());
        if (company.companyDetails.length > 1) {
          companyWithTotalDays.push({
            companyName: company.companyName,
            days: totalDays
          });
          totalDays = this.sumDaysOfSameCompany(companyWithTotalDays);
        }
        company.totalDuration = this.durationService.getDateDiff(totalDays);

      });
    });
  }

  /**
   * set one company name with common role
   */
  setCompanyWithCommonName(): ICandidateRepeatCompany[] {
    return this.cv.reduce((company, cur) => {
      const commonName = company.reduce((companies, uniqueCompany, index) => {
        return (uniqueCompany.companyName === cur.companyName) ? index : companies;
      }, -1);
      if (commonName >= 0) {
        company[commonName].companyDetails = company[commonName].companyDetails.concat(cur.companyDetails);
      } else {
        const finalCompanyData = {
          companyName: cur.companyName,
          companyDetails: [cur.companyDetails]
        };
        company = company.concat([finalCompanyData]);
      }
      return company;
    }, []);

  }

  /**
   * calaculate dates of different roles for common company
   * @param companyWithDays
   */
  sumDaysOfSameCompany(companyWithDays): any {
    return companyWithDays.reduce((accumulator, current) => accumulator + current.days, 0);
  }

}
