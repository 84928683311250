<div class="basic-info-container" *ngIf="basicInfo">
  <form #basicInfoForm="ngForm" name="basicInfoForm" class="example-form">
    <div class="basic-info-wrapper">

        <div class="form-group">
          <div>
            <label> Position / Job title * </label>

            <mat-form-field class="example-full-width" appearance="outline">
              <input [(ngModel)]="basicInfo.jobTitle" id="jobTitle" name="jobTitle" #jobTitle="ngModel" matInput
                (input)="onChange()" placeholder="Please Enter">
              <mat-hint *ngIf="error.jobTitle === false">Please enter a Position / Job title</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <label> Languages * </label>
          <div>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select [compareWith]="compareMetadata" [(ngModel)]="basicInfo.languages" id="languages"
                name="languages" bindLabel="languages" multiple (selectionChange)="onChange()"
                placeholder="Please Select">
                <mat-option *ngFor="let language of languageMetaData" [value]="language">
                  {{language.name}}
                </mat-option>
              </mat-select>
              <mat-hint *ngIf="error.languages === false">Please select a language</mat-hint>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <div>
            <label> Industry * </label>
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-select [compareWith]="compareMetadata" [(ngModel)]="basicInfo.industries" id="industry" name="industry"
                bindLabel="industry" (selectionChange)="onChange()" multiple placeholder="Please Select"
                #industry="ngModel">
                <mat-option *ngFor="let industry of industryMetaData" [value]="industry">
                  {{industry.name}}
                </mat-option>
              </mat-select>
              <mat-hint *ngIf="error.industries === false">Please select an industry</mat-hint>

            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <label> Starting Date
          </label>
          <div>
            <mat-form-field class="example-full-width" appearance="outline">
              <input [(ngModel)]="basicInfo.startDate" #startDate="ngModel" id="startDate" name="startDate"
                (dateChange)="onChange()" matInput [matDatepicker]="picker" [min]="startDate"
                placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker [startAt]="startDate"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <label> Work Pensum *</label>
          <div>
            <ng5-slider
            [formControl]="availabilty"
            [options]="options"
          ></ng5-slider>
          </div>
        </div>
        <div class="form-group">
          <label> Application Deadline
          </label>
          <div>
            <mat-form-field class="example-full-width" appearance="outline">
              <input [(ngModel)]="basicInfo.applicationDeadline" #applicationDeadline="ngModel" id="applicationDeadline"
                name="applicationDeadline" (dateChange)="onChange()" matInput [matDatepicker]="picker2"
                placeholder="Choose a date">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
    </div>
  </form>
</div>