export enum candidateProfileData {
  PERSONAL_INFO = 'Personal Information',
  PROFILE_IMG_DESC = 'Profile Picture & Short Bio',
  DOCUMENT_UPLOAD = 'Document Upload',
  DOCUMENT_VALIDATION = 'Document Validation',
  SKILL_EXPERTISE = 'Skills & Expertise',
  WORK_EXPERIENCE = 'Work Experience',
  CERTIFICATION = 'Certificates',
  ADDITIONAL_INFO = 'Additional Information',
}
