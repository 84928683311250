<div class="new-skill-expertise-container">
  <div class="title-close">
    <div class="title">
      SELECT <span>{{ type }}S</span>
    </div>
    <div>
      <img
        src="assets/icons/close-x.svg"
        alt="close-icon"
        (click)="onClickClose()"
      />
    </div>
  </div>
  <div class="header-row">
    <div class="search-btn">
      <input
        type="text"
        name="search"
        [(ngModel)]="searchText"
        autocomplete="off"
        placeholder="Start typing to search..."
      />
    </div>
    <div class="new">
      <a class="btn btn-link" href="mailto:support@techface.ch">Request new {{ type }}</a>
      <button class="btn btn-primary" (click)="onAddData()">
        Add {{ type }}s
      </button>
    </div>
  </div>
  <div class="categories">
    <div
      class="category"
      *ngFor="let category of allDataByCategory | filter: searchText"
    >
      <app-new-category
        *ngIf="category.skillsExpertises.length > 0"
        (selectedItemsEmmitter)="onSelectItem($event, type, category)"
        [category]="category"
        [searchText]="searchText"
      ></app-new-category>
    </div>
  </div>
</div>
