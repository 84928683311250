<div class="candidate-summary-container card">
  <div (click)="openCandidate()" [ngClass]="{'no-image' : '!canSeeCandidate() || !candidateAndCompanyStatus?.candidate?.image'}" class="img">
    <div *ngIf="!canSeeCandidate()" class="lock-profile">
      Send connection request to candidate by clicking on this profile
    </div>
    <img *ngIf="canSeeCandidate() && candidateAndCompanyStatus?.candidate?.image"  src="{{candidateAndCompanyStatus?.candidate?.image}}">
  </div>
  <div class="candidate-summary-wrapper">
    <div *ngIf="canSeeCandidate()" class="name">{{candidateAndCompanyStatus?.candidate?.firstName}}
      {{candidateAndCompanyStatus?.candidate?.lastName}}
    </div>
    <div [ngClass]="{'name' : !canSeeCandidate(), 'title': canSeeCandidate()}">{{candidateAndCompanyStatus?.candidate?.title}}</div>
    <div *ngFor="let skill of candidateAndCompanyStatus?.skillsName" class="skill-expertise">{{skill}}</div>
    <div *ngFor="let expertise of candidateAndCompanyStatus?.expertisesName" class="skill-expertise">{{expertise}}
    </div>
    <div class="candidate-time-location">
      <div *ngIf="candidateAndCompanyStatus?.candidate?.availableFrom" class="details"><img src="../../../assets/icons/calendar.png"> <span>Available from
          {{candidateAndCompanyStatus?.candidate?.availableFrom| date:'dd.MM.YYYY'}}</span></div>
      <div *ngIf="candidateAndCompanyStatus?.candidate?.availabilty?.length == 2" class="details"><img src="../../../assets/icons/clock.png">
        <span>Work pensum: {{candidateAndCompanyStatus?.candidate?.availabilty[0]}} - {{candidateAndCompanyStatus?.candidate?.availabilty[1]}}%</span></div>
      <div *ngIf="canSeeCandidate() && candidateAndCompanyStatus?.candidate?.preferredLocation" class="details"><img
          src="../../../assets/icons/map-marker-alt.png">
        <span>{{candidateAndCompanyStatus?.candidate?.preferredLocation}}</span></div>
    </div>
  </div>
</div>
