import { Injectable } from '@angular/core';
import { BehaviorSubject, from, of } from 'rxjs';
import { MatchesService } from './matches.service';
import { concatMap, filter, map, reduce } from 'rxjs/operators';
import { CompanyStatus } from '../../enums/companyStatus.enum';
import { CandidateStatus } from '../../enums/CandidateStatus.enum';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private openQueriesCount = new BehaviorSubject(0);
  currentOpenQueriesCount = this.openQueriesCount.asObservable();

  private matchesQueriesCount = new BehaviorSubject(0);
  currentMatchesQueriesCount = this.matchesQueriesCount.asObservable();

  private activeConversationQueriesCount = new BehaviorSubject(0);
  currentActiveConversationQueriesCount =
    this.activeConversationQueriesCount.asObservable();

  private unreadMsgCount = new BehaviorSubject(0);
  currentUnreadMsgCount = this.unreadMsgCount.asObservable();

  private unreadTotalCompanyMsgsCount = new BehaviorSubject(0);
  currentUnreadTotalCompanyMsgsCount =
    this.unreadTotalCompanyMsgsCount.asObservable();

  private unreadTotalCandidateMsgsCount = new BehaviorSubject(0);
  currentUnreadTotalCandidateMsgsCount =
    this.unreadTotalCandidateMsgsCount.asObservable();
  totalUnreadMsg: number[];

  constructor(private matchService: MatchesService) {}

  changeOpenQueriesCount(count: number): void {
    this.openQueriesCount.next(count);
  }

  changeMatchesQueriesCount(count: number): void {
    this.matchesQueriesCount.next(count);
  }

  changeActiveConversationQueriesCount(count: number): void {
    this.activeConversationQueriesCount.next(count);
  }

  changeUnreadMsgsCount(count: number): void {
    this.unreadMsgCount.next(count);
  }
  changeUnreadTotalCompanyMsgsCount(count: number): void {
    this.unreadTotalCompanyMsgsCount.next(count);
  }
  changeUnreadTotalCandidateMsgsCount(count: number): void {
    this.unreadTotalCandidateMsgsCount.next(count);
  }

  getToTalComapnyMsgs(): void {
    this.totalUnreadMsg = [];
    this.matchService
      .getMatchByComapnyId(localStorage.getItem('companyId'))
      .subscribe((response) => {
        response.matches.forEach((match) => {
          this.totalUnreadMsg.push(
            match.messages.filter((msg) => msg.isCompanySeen === false).length
          );
        });
        const totalUnreadMsg = this.totalUnreadMsg.reduce(
          (acc, cur) => acc + cur,
          0
        );
        this.changeUnreadTotalCompanyMsgsCount(totalUnreadMsg);
      });
  }
  getTotalCandidateMessage(): void {
    this.matchService
      .getMatchByCandidateId(localStorage.getItem('candidateId'))
      .pipe(
        map((response) => response.matches),
        concatMap((matches) => from(matches)),
        filter(
          (match) =>
            match.companyStatus != CompanyStatus.rejected &&
            match.candidateStatus != CandidateStatus.rejected
        ),
        reduce((totalCandidateUnReadMsg, match) => {
          if (
            match.messages?.length == 0 ||
            match.messages?.reverse()[0].isCandidateSeen == false
          ) {
            totalCandidateUnReadMsg += 1;
          }
          return totalCandidateUnReadMsg;
        }, 0)
      )
      .subscribe((totalCandidateUnReadMsg) => {
        this.changeUnreadMsgsCount(totalCandidateUnReadMsg);
        this.changeUnreadTotalCandidateMsgsCount(totalCandidateUnReadMsg);
      });
  }
}
