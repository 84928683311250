<div class="messages-container">
  <div>
    <div class="name">
      <div>{{ receiverData.name }}</div>
      <div *ngIf="receiverData.unreadMsgs > 0" class="dot notification">
        {{ receiverData.unreadMsgs }}
      </div>
    </div>
    <div class="title">{{ receiverData.subName }}</div>
  </div>
  <div class="date-settings">
    <div class="date">
      {{ receiverData.lastMsgTime | date: "dd.MM.YYYY, h:mm a" }}
    </div>
  </div>
</div>