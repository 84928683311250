import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromActions from '../actions/techface.action';
import * as fromFeature from '../reducers/techface.reducer';
import { ICompanyInformation } from '../interfaces/comapny.interface';
import { IQuery } from '../interfaces/query.interface';
import { map } from 'rxjs/operators';
import { IUpdateQuery } from '../interfaces/updateQuery.interface';
import { MetaDataModel } from '../models/metaData.model';
import { MetaDataIdsModel } from '../models/metaDataIds.model';
import { take } from 'rxjs/operators';
import { IMatch } from '../interfaces/match.interface';
import { QueryCandidateIdsModel } from '../models/queryCandidateIds.model';
import { CandidatesIdsModel } from '../models/candidatesIds.model';
import { UpdateCompanyModel } from '../models/updateCompany.model ';
import { UserModel } from '../models/user.model';
import { TokenModel } from '../models/token.model';
import { UserResponseModel } from '../models/userResponse.model';
import { CandidateModel } from '../models/candidate.model';
import { IUpdateUser } from '../interfaces/updateUser.interface';
import { ICandidate } from '../interfaces/candidate.interface';
import { IUpdateCandidate } from '../interfaces/updateCandidate.interface';
import { SkillExpertiseByCategoriesModel } from '../models/skillExpertiseByCategories.model';
import 'rxjs/add/observable/of';

@Injectable({
  providedIn: 'root',
})
export class TechfaceProvider {
  constructor(public store: Store<fromFeature.AppState>) {}

  public getCompanyById(companyId: string): Observable<ICompanyInformation> {
    this.store.dispatch(fromActions.onGetCompanyById({ payload: companyId }));
    return this.store.pipe(select(fromFeature.selectCompany));
  }

  public getQueriesByCompanyById(companyId: string): Observable<IQuery[]> {
    this.store.dispatch(
      fromActions.onGetQueriesByCompanyId({ payload: companyId })
    );
    return this.store.pipe(
      select(fromFeature.selectQueries),
      map((queries: IQuery[]) => {
        return queries;
      })
    );
  }

  public getQueryById(queryId: string): Observable<IQuery> {
    this.store.dispatch(fromActions.onGetQueryById({ payload: queryId }));
    return this.store.pipe(select(fromFeature.selectQuery));
  }

  public getMatchesByQueryId(queryId: string): Observable<IMatch[]> {
    this.store.dispatch(
      fromActions.onGetMatchesByQueryId({ payload: queryId })
    );
    return this.store.pipe(
      select(fromFeature.selectMatches),
      map((matches: IMatch[]) => {
        return matches;
      })
    );
  }

  public getMatchByQueryAndCandidateIds(
    queryAndCandidateIds: QueryCandidateIdsModel
  ): Observable<IMatch> {
    this.store.dispatch(
      fromActions.onGetMatchByQueryAndCandidateIds({
        payload: queryAndCandidateIds,
      })
    );
    return this.store.pipe(
      select(fromFeature.selectMatch),
      map((match: IMatch) => {
        return match;
      })
    );
  }

  public getCandidateById(candidateId: string): Observable<CandidateModel> {
    this.store.dispatch(
      fromActions.onGetCandidateById({ payload: candidateId })
    );
    return this.store.pipe(
      select(fromFeature.selectCandidate),
      map((candidate: CandidateModel) => {
        return candidate;
      })
    );
  }

  public getCandidatesByIds(
    candidatesIds: CandidatesIdsModel
  ): Observable<CandidateModel[]> {
    this.store.dispatch(
      fromActions.onGetCandidatesByIds({ payload: candidatesIds })
    );
    return this.store.pipe(
      select(fromFeature.selectCandidates),
      map((candidate: CandidateModel[]) => {
        return candidate;
      })
    );
  }

  public addCandidate(candidateData: CandidateModel): Observable<ICandidate> {
    this.store.dispatch(fromActions.onAddCandidate({ payload: candidateData }));
    this.store.pipe(select(fromFeature.selectAddCandidate)).subscribe();
    return this.store.pipe(select(fromFeature.selectAddCandidate));
  }

  public getAllMetaData(): Observable<MetaDataModel> {
    const metadata = this.store.pipe(select(fromFeature.selectAllMetaData));
    metadata.subscribe(data => {
      if (!data) {
        this.store.dispatch(fromActions.onGetMetaData());
      }
    })
    return metadata;
  }



  public getAllSkillExprtiseByCategory(): Observable<SkillExpertiseByCategoriesModel> {
    const response = this.store.pipe(select(fromFeature.selectSkillExpertiseByCategories));
    response.subscribe( data => {
       if(!data) {
        this.store.dispatch(fromActions.onGetSkillExpertiseByCatefgories());
      }}
    )
   return this.store.pipe(select(fromFeature.selectSkillExpertiseByCategories));
  }
  /*

  public getMetadataByIds(metaDataId: MetaDataIdsModel): Observable<MetaDataModel> {
    this.store.dispatch(fromActions.onGetMetadataByIds({ payload: metaDataId }));
    return this.store.pipe(select(fromFeature.selectMetaData), map((metaData: MetaDataModel) => {
      return metaData;
    }));
  }

  public getQueryMetadataByIds(metaDataId: MetaDataIdsModel): Observable<MetaDataModel> {
    this.store.dispatch(fromActions.onGetQueryMetadataByIds({ payload: metaDataId }));
    return this.store.pipe(select(fromFeature.selectQueryMetaData), map((metaData: MetaDataModel) => {
      return metaData;
    }));
  }
*/
  public updateCompanyStatus(
    updateCompany: UpdateCompanyModel
  ): Observable<IMatch> {
    this.store.dispatch(
      fromActions.onUpdateCompanyStatus({ payload: updateCompany })
    );
    return this.store.pipe(
      select(fromFeature.selectMatch),
      map((match: IMatch) => {
        return match;
      })
    );
  }
  // login
  public signIn(userLogin: UserModel): Observable<UserResponseModel> {
    this.store.dispatch(fromActions.signIn({ payload: userLogin }));
    return this.store.pipe(
      select(fromFeature.selectFeatureLogin),
      map((user: UserResponseModel) => {
        return user;
      })
    );
  }

  // token
  public getToken(userLogin: UserModel): Observable<TokenModel> {
    this.store.dispatch(fromActions.getToken({ payload: userLogin }));
    return this.store.pipe(
      select(fromFeature.selectToken),
      map((token: TokenModel) => {
        return token;
      })
    );
  }

  public createQuery(queryData: IQuery): Observable<IQuery> {
    this.store.dispatch(fromActions.onCreateNewQuery({ payload: queryData }));
    return this.store.pipe(
      select(fromFeature.selectQuery),
      map((query: IQuery) => {
        return query;
      })
    );
  }

  public updateQueryById(updateQuery: IUpdateQuery): Observable<IQuery> {
    this.store.dispatch(fromActions.onUpdateQuery({ payload: updateQuery }));
    return this.store.pipe(
      select(fromFeature.selectUpdateQuery),
      map((query: IQuery) => {
        return query;
      })
    );
  }
  public addUser(user: UserModel): Observable<UserResponseModel> {
    this.store.dispatch(fromActions.onAddUser({ payload: user }));
    return this.store.pipe(
      select(fromFeature.selectAddUser),
      map((user: UserResponseModel) => {
        return user;
      })
    );
  }

  public addUserError(): Observable<any> {
    return this.store.pipe(select(fromFeature.selectAddUserError));
  }
  public updateUserById(
    updateUser: IUpdateUser
  ): Observable<UserResponseModel> {
    this.store.dispatch(fromActions.onUpdateUser({ payload: updateUser }));
    this.store.pipe(select(fromFeature.selectUpdateUser));
    return this.store.pipe(select(fromFeature.selectUpdateUser));
  }

  public updateCandidate(
    updateCandidate: IUpdateCandidate
  ): Observable<CandidateModel> {
    this.store.dispatch(
      fromActions.onUpdateCandidate({ payload: updateCandidate })
    );
    this.store.pipe(select(fromFeature.selectUpdateCandidate));
    return this.store.pipe(select(fromFeature.selectUpdateCandidate));
  }

  public signInError(): Observable<any> {
    return this.store.pipe(select(fromFeature.selectSignInError));
  }

  public getEmailByCandidateId(candidateId: string): Observable<{ userEmail: string }> {
    this.store.dispatch(fromActions.onGetEmailByCandidateId({ payload: candidateId }));
    return this.store.pipe(select(fromFeature.selectEmailByCandidateId));
  }
  /*
  public getMetaData(): Observable<MetaDataModel> {
    this.store.dispatch(fromActions.onGetMetadata());
    return this.store.pipe(select(fromFeature.selectMetaData), map((metadata: MetaDataModel) => {
      return metadata;
    }));
  }
  */
  /*
  public getMetaDataById(metadataByIds: MetaDataIdsModel): Observable<MetaDataModel> {
    this.store.dispatch(fromActions.onGetMetadataByIds({ payload: metadataByIds }));
    return this.store.pipe(select(fromFeature.selectMetaDataById), map((metadata: MetaDataModel) => {
      return metadata;
    }));
  }
  */
}
