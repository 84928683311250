<div class="receiver-data-container">
  <div class="image"><img src="assets/icons/user.svg" /></div>
  <div class="name">
    <div class="receiver-name">{{ receiverData?.name }}</div>
    <div class="receiver-title-location">
      {{ receiverData?.subName }} ({{ receiverData?.location }})
    </div>
  </div>
  <div
    *ngIf="receiverData?.canConfirm"
    class="accept"
    (click)="onClick(acceptMatch)"
  >
    Accept Match <img src="assets/icons/green-true.svg" />
  </div>
  <div
    *ngIf="receiverData?.canReject"
    class="discard"
    (click)="onClick(discardMatch)"
  >
    Discard Match <img src="assets/icons/red-false.svg" />
  </div>
</div>
