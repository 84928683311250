import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TechfaceConfigService } from '../../../techface.config';
import { Observable } from 'rxjs';
import { UserResponseModel } from '../../models/userResponse.model';
import { UserModel } from '../../models/user.model';
import { IUpdateUser } from '../../interfaces/updateUser.interface';
import { TokenModel } from '../../models/token.model';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';
type SignInResponse = {
  user: UserResponseModel;
};

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private user = 'user';
  private readonly userRoute = '/';
  private readonly signin = 'signIn';
  private readonly token = 'token';

  constructor(
    private http: HttpClient,
    @Inject(TechfaceConfigService) private config
  ) {
    super();

    this.server = config.apiGateway;
  }

  getToken(data: UserModel): Observable<TokenModel> {
    const url = `${this.server}${this.user}${this.userRoute}${this.token}`;
    return this.http.post<TokenModel>(url, data);
  }

  signIn(data: UserModel): Observable<SignInResponse> {
    const url = `${this.server}${this.user}${this.userRoute}${this.signin}`;
    return this.http.post<SignInResponse>(url, data);
  }

  addUser(data): Observable<SignInResponse> {
    const add = 'add';
    const url = `${this.server}${this.user}${this.userRoute}${add}`;
    return this.http.post<SignInResponse>(url, data);
  }

  updateUser(userData: IUpdateUser): Observable<SignInResponse> {
    const update = 'update';
    const url = `${this.server}${this.user}${this.userRoute}${update}${this.userRoute}${userData.id}`;
    return this.http.put<SignInResponse>(url, userData.userObj);
  }

  async requestPasswordReset(email: string): Promise<any> {
    const passwordReset = 'reset-password';
    const url = `${this.server}${this.user}${this.userRoute}${passwordReset}`;
    return this.http.post<any>(url, { email }).toPromise<any>();
  }

  async confirmPasswordReset(token: string, password: string): Promise<any> {
    const passwordReset = 'reset-password';
    const url = `${this.server}${this.user}${this.userRoute}${passwordReset}${this.userRoute}${token}`;
    return this.http.post<any>(url, { password }).toPromise<any>();
  }

  public getEmailByCandidateId(candidateId: string): Observable<{ userEmail: string }> {
    const url = `${this.server}${this.user}${this.userRoute}candidate${this.userRoute}${candidateId}`;
    return this.http.get<any>(url);
  }
}
