import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { QueryResponseModel } from '../../models/queryResponse.model';
import { IUpdateQuery } from '../../interfaces/updateQuery.interface';
import { IQuery } from '../../interfaces/query.interface';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';

export interface IQueriesResponse {
  queries: QueryResponseModel[];
}
export interface IQueryResponse {
  query: QueryResponseModel;
}

@Injectable({
  providedIn: 'root',
})
export class QueriesService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private microserviceName = 'queries';
  private query = 'query';
  private company = 'company';
  private readonly route = '/';

  constructor(private http: HttpClient) {
    super();
  }

  public getQueriesByCompanyId(
    companyId: string
  ): Observable<IQueriesResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.company}${this.route}${companyId}`;
    return this.http.get<IQueriesResponse>(url);
  }

  public getQueryById(queryId: string): Observable<IQueryResponse> {
    const url = `${this.server}${this.query}${this.route}${queryId}`;
    return this.http.get<IQueryResponse>(url);
  }

  public createNewQuery(data: IQuery): Observable<IQueryResponse> {
    const url = `${this.server}${this.query}`;
    return this.http.post<IQueryResponse>(url, data);
  }

  public updateNewQuery(updateQuery: IUpdateQuery): Observable<IQueryResponse> {
    const url = `${this.server}${this.query}${this.route}${updateQuery.id}`;
    return this.http.put<IQueryResponse>(url, updateQuery.queryObj);
  }
}
