import { Component, OnInit, Input, AfterViewChecked, OnChanges } from '@angular/core';
import { MetaDataNameModel } from '../../../shared/models/metaDataName.model';
import { IMetaDataStatus } from '../../../shared/interfaces/candidate.interface';
import { MetaDataModel } from '../../../shared/models/metaData.model';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';

@Component({
  selector: 'app-skills-expertise',
  templateUrl: './skills-expertise.component.html',
  styleUrls: ['./skills-expertise.component.scss']
})
export class SkillsExpertiseComponent implements OnChanges {
  @Input() title: string;
  @Input() topMatchMetaData: IMetaDataStatus[];
  @Input() candidateMetaData: IMetaDataStatus[];
  @Input() isCandidateMyself: boolean;


  constructor() { }

  ngOnChanges(): void {
  }

}
