import { Component } from '@angular/core';
import { BasePageComponent } from '../base-page/base-page.component';
import { ActivatedRoute } from '@angular/router';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { ICompanyInformation } from '../../shared/interfaces/comapny.interface';
import { environment } from '../../../environments/environment';
import { SharedDataService } from '../../shared/services/services/shared-data.service';

@Component({
  selector: 'app-dashboad-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
})
export class DashboardPageComponent extends BasePageComponent {
  firstName: string;
  companyInfo: ICompanyInformation;
  publicBaseUrl = environment.modeso.publicAssetsBaseurl;

  constructor(
    public route: ActivatedRoute,
    private techfaceProvider: TechfaceProvider,
    private sharedDataService: SharedDataService
  ) {
    super(route);
  }

  ngOnInit(): void {
    this.firstName = localStorage.getItem('firstname');
    this.bindingCompanyInfoData();
    this.sharedDataService.getToTalComapnyMsgs();
  }
  /**
   * binding data of company information data
   */
  bindingCompanyInfoData(): void {
    this.techfaceProvider
      .getCompanyById(localStorage.getItem('companyId'))
      .subscribe((companyInfo) => {
        if (companyInfo) {
          this.companyInfo = {
            ...companyInfo
          };
        }
      });
  }
}
