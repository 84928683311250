<div class="new-query-container">

    <div class="header">
        <h1 *ngIf="isCreateMode"> Create new query</h1>
        <div class="edit-title">
            <h1 *ngIf="!isCreateMode"> Edit query</h1>
        </div>
        <div class="controls">
            <button *ngIf="query.status !== 'ACTIVE'" [disabled]="!checkFormValid(query.status, false)" (click)="saveBtn()" class="btn btn-secondary">
                <mat-spinner *ngIf="savingProgress" diameter="15" style="display: inline-block; margin-right: 0.7em;"></mat-spinner>
                <img *ngIf="!savingProgress" src="../../../assets/icons/save.png">
                <span>Save</span>
            </button>
            <button *ngIf="query.status === 'ACTIVE'" class="btn btn-primary" (click)="archiveQuery()">Archive Query</button>
            <button *ngIf="query.status !== 'ACTIVE'" [disabled]="!checkFormValid(query.status, false)" class="btn btn-primary" (click)="saveAndPublishBtn()">Save and Publish</button>
        </div>
    </div>
    
    <div class="content">
        <div class="column1">
            <div class="card">
                <h4 class="card-title">BASIC INFORMATION</h4>
                <div class="basic-info-overview">
                    <app-basic-info [basicInfo]="basicInfoData" [languageMetaData]="allLanguages"
                        [industryMetaData]="allIndustries" (basicInfoEvent)="getBasicInfo($event)" [error]="basicInfoErrors">
                    </app-basic-info>
                </div>
            </div>
            <div class="card">
                <h4 class="card-title">JOB DESCRIPTION *</h4>
                <div class="job-description-overview">
                    <app-job-description [description]="descriptionData" (newItemEvent)="getDescripttion($event)" [error]="descriptionError">
                    </app-job-description>
                </div>
            </div>
        </div>
        <div class="column2">
            <div class="card">
                <h4 class="card-title">SKILLS * </h4>
                <div class="skills-overview">
                    <app-skills *ngIf="query" [skills]="query.skills"  [canEdit]="query.status === 'DRAFT' || isCreateMode"
                        (skillEvent)="getSkills($event)" (addSkills)="addSkills()"  [error]="skillsError">
                    </app-skills>
                </div>
            </div>
            <div class="card">
                <h4 class="card-title">EXPERTISES *</h4>
                <div class="expertise-overview">
                    <app-expertises *ngIf="query" [expertises]="query.expertises" [canEdit]="query.status === 'DRAFT' || isCreateMode"
                        (expertiseEvent)="getExpertise($event)" (addExpertise)="addExpertise()" [error]="expertiseError">
                    </app-expertises>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isDisplayNew">
    <div class="overlay"></div>
    <div class="new">
      <app-new-skill-expertise
        *ngIf="newSkillsMetadata"
        #newSkillExpertise
        [allDataByCategory]="newSkillsMetadata"
        [candidateSkillExpertise]="skillExpertise"
        [type]="type"
        (closeEvent)="displayNewItem($event, '')"
        (saveEvent)="selectSkillsExpertise($event)"
      ></app-new-skill-expertise>
    </div>
  </div>