<div class="additional-information-container">
  <form
    [formGroup]="additionalInformation"
    #myForm="ngForm"
    class="additional-information-wrapper"
  >
    <div class="line">
      <div class="line-title">AVAILABILITY</div>
      <div>
        <label>Available form</label>
        <div>
          <div>
            <mat-form-field appearance="outline">
              <input
                formControlName="availableFrom"
                id="availableFrom"
                name="availableFrom"
                matInput
                [min]="minDate"
                [matDatepicker]="picker"
                placeholder="Choose a date"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker startView="year"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="location">
        <label>Location</label>
        <div>
          <input
            matInput
            formControlName="preferredLocation"
            placeholder="Please enter location"
          />
        </div>
      </div>
      <div>
        <label>Work Permit</label>
        <mat-select
          id="workPermit"
          formControlName="workPermit"
          name="workPermit"
          bindLabel="workPermit"
          placeholder="Please Select"
        >
          <mat-option
            *ngFor="let data of workPermitData; let i = index"
            [value]="data"
          >
            {{ data }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="line-work-pensum">
      <div class="line-title">WORK PENSUM</div>

      <div>
        <label>Work Pensum</label>
        <ng5-slider
          [formControl]="availabilty"
          [options]="options"
        ></ng5-slider>
      </div>
      <div class="error-msg">{{ errorMsg }}</div>
    </div>
  </form>
</div>
