import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import {
  IAboutCandidate,
  IBasicInfoCandidate,
  ICandidate,
  IMetaDataStatus,
} from '../../shared/interfaces/candidate.interface';
import { CandidateModel } from '../../shared/models/candidate.model';
import { MetaDataNameModel } from '../../shared/models/metaDataName.model';
import { ICandidateCompany } from '../../shared/interfaces/candidateCompany.interface';
import { CandidateSkillModel } from '../../shared/models/candiddateSkill.model';
import { CandidateExpertiseModel } from '../../shared/models/candiddateExpertise.model';
import { CertificateModel } from '../../shared/models/certificate.model';
import { DocumentModel } from '../../shared/models/document.model';
import { UpdateCompanyModel } from '../../shared/models/updateCompany.model ';
import { ActivatedRoute } from '@angular/router';
import { GetMetaDataService } from '../../shared/services/metaData/metaData.service';
import { MetaDataIdModel } from '../../shared/models/metaDataById.model';
import { CompanyStatus } from '../../shared/enums/companyStatus.enum';
import { CompanyCandidateStatusModel } from '../../shared/models/companyCandidateStatus.model';
import { switchMap, take, takeLast, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { IQuery } from '../../shared/interfaces/query.interface';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { SharedDataService } from '../../shared/services/services/shared-data.service';
import { ConversationComponent } from '../../components/conversation/conversation/conversation.component';
import { timer } from 'rxjs';
import { AutoRefreshTimer } from '../../shared/enums/autoRefreshTimer.enum';

@Component({
  selector: 'app-candidate-page',
  templateUrl: './candidate-page.component.html',
  styleUrls: ['./candidate-page.component.scss'],
})
export class CandidatePageComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  queryId: string;
  candidateId: string;
  aboutCandidateObj: IAboutCandidate;
  basicInfoOfCandidateObj: IBasicInfoCandidate;
  queryJobTitle: string;
  cvObj: ICandidateCompany[] = [];
  certificatesObj: CertificateModel[] = [];
  docsObj: DocumentModel[] = [];
  topMatchSkills: IMetaDataStatus[] = [];
  candidateSkills: IMetaDataStatus[] = [];
  topMatchExpertises: IMetaDataStatus[] = [];
  candidateExpertises: IMetaDataStatus[] = [];
  candidateAndCompanyStatus: CompanyCandidateStatusModel;
  candidate: CandidateModel;
  companyName: string;

  metadataSub = null;
  steps = [];
  activeStep = 0;
  source = timer(AutoRefreshTimer.dueTime,AutoRefreshTimer.periodOrScheduler);
  unreadMsgs: number;
  @ViewChild('conversation', { static: false }) conversation: ConversationComponent;
  constructor(
    private techfaceProvider: TechfaceProvider,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private sharedDataService: SharedDataService
  ) {
    if (this.isCandidateMyself()) {
      this.steps = ['Overview', 'My Messages'];
    } else {
      this.steps = ['Overview', 'My Messages'];
    }
  }

  @HostBinding('unload')
  ngOnInit(): void {
    this.techfaceProvider.getAllMetaData();
    this.sharedDataService.getTotalCandidateMessage();
    this.route.params.subscribe((params) => {
      this.queryId = params.queryId;
      this.candidateId =
        localStorage.getItem('candidateId') ?? params.candidateId;
      this.getCandidateCompanyStatus();
      this.getCandidateData();
      if (localStorage.getItem('companyId')) this.getCompanyData(localStorage.getItem('companyId'));
    });
    this.source.subscribe(() => {this.sharedDataService.currentUnreadMsgCount.subscribe((num) => {
      this.unreadMsgs = num > 0 ? num : 0;      
    });    
  });
  }
  getCompanyData(companyId) {
    this.techfaceProvider
      .getCompanyById(companyId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((company) => {
        if (!company) return;
        this.companyName = company.fullCompanyName;
      });
  }


  ngOnDestroy() {
    this.onDestroy.next();
  }

  isCandidateMyself(): boolean {
    return this.candidateId === localStorage.getItem('candidateId');
  }

  /**
   * return true if company can see candidate image,
   * name and title if company status and candidate status is CONFIRMED
   */
  canSeeCandidate(): boolean {
    return (
      this.isCandidateMyself() ||
      this.candidateAndCompanyStatus?.candidateStatus ===
        CompanyStatus.confirmed
    );
  }

  isDiscarded(): boolean {
    return (
      this.candidateAndCompanyStatus?.companyStatus === CompanyStatus.rejected
    );
  }

  communicationRequested(): boolean {
    return (
      this.candidateAndCompanyStatus?.companyStatus === CompanyStatus.confirmed
    );
  }

  navigateToEdit(index: number) {
    this.navigationService.navigateByUrl(`me/edit/${index}`);
  }

  /**
   * get candidate and comapny status by query and candidate ids from match lib
   */
  getCandidateCompanyStatus(): void {
    this.techfaceProvider
      .getMatchesByQueryId(this.queryId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((matches) => {
        if (matches) {
          const match = matches
            .filter((m) => m.candidateId === this.candidateId)
            .filter(
              (m) => m.matchedQueries.findIndex((q) => q === this.queryId) > -1
            );

          if (match.length > 0) {
            this.candidateAndCompanyStatus = {
              candidateStatus: match[0].candidateStatus,
              companyStatus: match[0].companyStatus,
            };
          }
        }
      });
  }

  /**
   * get candidate data by id and bind to components
   */
  getCandidateData(): void {
    this.techfaceProvider
      .getCandidateById(this.candidateId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((candidate) => {
        if (candidate) {
          this.candidate = candidate;
          if (this.isCandidateMyself()) this.getMetaDataName(candidate, null);
          else this.getQueryById(candidate);
          this.bindAboudCandidateData(candidate);
          this.bindCandidateCV(candidate);
          this.bindCandidateCertificates(candidate);
          this.bindCandidateDocuments(candidate);
        }
      });
  }

  /**
   * bind aboutCandidateObj to send it to AboutCanddiate component
   * @param candidate
   */
  bindAboudCandidateData(candidate: CandidateModel): void {
    this.aboutCandidateObj = {
      firstName: candidate.firstName,
      lastName: candidate.lastName,
      email: candidate.email,
      image: candidate.image,
      title: candidate.title,
      imageDescription: candidate.imageDescription,
    };
  }

  /**
   * bind basicInfoObj to send it to BasicInfo component
   * @param candidate
   * @param language
   */
  bindBasicInfoOfCandidateData(
    candidate: CandidateModel,
    language: string[]
  ): void {
    this.basicInfoOfCandidateObj = {
      languages: language,
      availabilty: candidate.availabilty,
      availabiltyFrom:
        candidate?.availabilty?.length > 0 ? candidate.availabilty[0] : 0,
      availabiltyTo:
        candidate?.availabilty?.length > 0 ? candidate.availabilty[1] : 0,
      availableFrom: candidate.availableFrom,
      preferredLocation: candidate.preferredLocation,
      workPermit: candidate.workPermit,
    };
  }

  /**
   * bind Candidate CV to send it to Cv component
   * @param candidate
   */
  bindCandidateCV(candidate: CandidateModel): void {
    this.cvObj = (candidate?.cv?.map((cv) => {
      return {
        companyName: cv.company!,
        companyDetails: {
          role: cv.role!,
          from: cv.from!,
          to: cv.to,
          location: cv.location!,
        },
      }
    }) || [])
    .sort((a, b) => new Date(b.companyDetails.from).getTime() - new Date(a.companyDetails.from).getTime())
  }

  /**
   * bind Candidate Certificates to send it to Certificates component
   * @param candidate
   */
  bindCandidateCertificates(candidate: CandidateModel): void {
    candidate.certificates.forEach((certificate) => {
      this.certificatesObj.push({
        certificateName: certificate.certificateName,
        stanfordUniversity: certificate.stanfordUniversity,
        customInformation: certificate.customInformation,
        from: certificate.from,
        to: certificate.to,
      });
    });
  }

  /**
   * bind Candidate Documents to send it to Documents component
   * @param candidate
   */
  bindCandidateDocuments(candidate: CandidateModel): void {
    this.docsObj.push(candidate.cvDocument);
    candidate.documents.forEach((document: any) => {
      this.docsObj.push({
        name: document.name,
        url: document.url,
      });
    });
  }

  /**
   * get Query By id to get Skills and expertises of the query and matches it with
   * candidate skills and expertise to get "TOP MATCHES"
   * @param candidate
   */
  getQueryById(candidate: CandidateModel): void {
    this.techfaceProvider
      .getQueryById(this.queryId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((query) => {
        if (query) {
          this.queryJobTitle = query.jobTitle;
          this.getMetaDataName(candidate, query);
        }
      });
  }

  /**
   * get MetaData Name to get name of skills expertise and languages
   * @param candidate
   */
  getMetaDataName(candidate: CandidateModel, query: IQuery): void {
    if (this.metadataSub) return;

    this.metadataSub = this.techfaceProvider
      .getAllMetaData()
      .pipe(takeUntil(this.onDestroy))
      .subscribe((metadata) => {
        if (metadata) {
          const languages = candidate.languages.map(
            (lang) =>
              metadata.languages.find((l) => l._id === lang.languageId).name
          );
          this.bindBasicInfoOfCandidateData(candidate, languages);

          const skills: IMetaDataStatus[] = candidate.skills
            .filter(
              (cs) =>
                metadata.skills.findIndex((m) => m._id === cs.skillId) > -1
            )
            .map((cs) => {
              return {
                id: cs.skillId,
                name: metadata.skills.find((m) => m._id === cs.skillId).name,
                status: cs.confirmationStatus || 'notConfirmed',
                isfavorite: !!cs.isfavorite,
              };
            });

          const expertises: IMetaDataStatus[] = candidate.expertises
            .filter(
              (cs) =>
                metadata.expertises.findIndex((m) => m._id === cs.expertiseId) >
                -1
            )
            .map((cs) => {
              return {
                id: cs.expertiseId,
                name: metadata.expertises.find((m) => m._id === cs.expertiseId)
                  .name,
                status: cs.confirmationStatus || 'notConfirmed',
                isfavorite: !!cs.isfavorite,
              };
            });

          /*
        this.query.languages = query.languages.map((idObj) => metadata.languages.find(m => m._id === idObj.languageId)).map(o => o.name);
        this.query.skills = query.skills.map((idObj) => metadata.skills.find(m => m._id === idObj.skillId)).map(o => o.name);
        this.query.expertises = query.expertises.map((idObj) => metadata.expertises.find(m => m._id === idObj.expertiseId)).map(o => o.name);
        */

          if (!query) {
            this.candidateSkills = skills.filter((it) => !it.isfavorite);
            this.topMatchSkills = skills.filter((it) => it.isfavorite);
            this.candidateExpertises = expertises.filter(
              (it) => !it.isfavorite
            );
            this.topMatchExpertises = expertises.filter((it) => it.isfavorite);
          } else {
            this.topMatchSkills = skills.filter(
              (s) => query.skills.findIndex((qs) => qs.skillId === s.id) > -1
            );
            this.candidateSkills = skills.filter(
              (s) => query.skills.findIndex((qs) => qs.skillId === s.id) < 0
            );

            this.topMatchExpertises = expertises.filter(
              (s) =>
                query.expertises.findIndex((qs) => qs.expertiseId === s.id) > -1
            );
            this.candidateExpertises = expertises.filter(
              (s) =>
                query.expertises.findIndex((qs) => qs.expertiseId === s.id) < 0
            );
          }
          /*
        (this.topMatchExpertises as IMetaDataStatus[]) =
          this.setExpertiseObj((this.topMatchExpertises as CandidateExpertiseModel[]), metadata.expertises);
        (this.candidateExpertises as IMetaDataStatus[]) =
          this.setExpertiseObj((this.candidateExpertises as CandidateExpertiseModel[]), metadata.expertises);
        */
        }
      });
  }

  /**
   * set skill obj to contain id , name and status to send it to skills and expertise component
   * @param skills skills
   * @param metadataSkills metaData skills
   */
  setSkillObj(
    skills: CandidateSkillModel[],
    metadataSkills: MetaDataNameModel[]
  ): IMetaDataStatus[] {
    const skillArray = [];
    if (skills) {
      skills.forEach((skill) => {
        metadataSkills.forEach((metaData) => {
          if (skill.skillId === metaData._id) {
            skillArray.push({
              id: skill.skillId,
              name: metaData.name,
              status: skill.confirmationStatus,
            });
          }
        });
      });
      return skillArray;
    }
  }

  /**
   * et expertise obj to contain id , name and status to send it to skills and expertise component
   * @param expertises
   * @param metadataExpertises
   */
  setExpertiseObj(
    expertises: CandidateExpertiseModel[],
    metadataExpertises: MetaDataNameModel[]
  ): IMetaDataStatus[] {
    const expertiseArray = [];
    if (expertises) {
      expertises.forEach((expertise) => {
        metadataExpertises.forEach((metaData) => {
          if (expertise.expertiseId === metaData._id) {
            expertiseArray.push({
              id: expertise.expertiseId,
              name: metaData.name,
              status: expertise.confirmationStatus,
            });
          }
        });
      });
      return expertiseArray;
    }
  }

  /**
   * define between toMatch and rest of candidate skills
   * @param candidateSkills
   * @param querySkills
   * @param isUnion
   */
  setkindOfSkills(
    candidateSkills: CandidateSkillModel[],
    querySkills: MetaDataIdModel[],
    isUnion: boolean
  ): CandidateSkillModel[] {
    const uniqueQuerySkills = new Set(
      querySkills.map((querySkill) => querySkill.skillId)
    );
    return candidateSkills.filter(
      (
        (set) => (candidateSkill) =>
          isUnion === set.has(candidateSkill.skillId)
      )(uniqueQuerySkills)
    );
  }

  /**
   * define between toMatch and rest of candidate expertises
   * @param candidateExpertises
   * @param queryExpertises
   * @param isUnion
   */
  setkindOfExpertises(
    candidateExpertises: CandidateExpertiseModel[],
    queryExpertises: MetaDataIdModel[],
    isUnion: boolean
  ): CandidateExpertiseModel[] {
    const uniqueQueryExpertise = new Set(
      queryExpertises.map((expertises) => expertises.expertiseId)
    );
    return candidateExpertises.filter(
      (
        (set) => (candidateExpertise) =>
          isUnion === set.has(candidateExpertise.expertiseId)
      )(uniqueQueryExpertise)
    );
  }

  /**
   * click of start communication or discard buttons
   * @param companyStatus
   */
  companyStatusClick(companyStatus: string): void {
    this.techfaceProvider
      .getEmailByCandidateId(this.candidateId)
      .pipe(takeUntil(this.onDestroy),
        switchMap((data) => {
          console.log("CandidatePageComponent:companyStatusClick:getEmailByCandidateId", companyStatus, data)
          if (data && data.userEmail) {
            const updateCompanyObj: UpdateCompanyModel = {
              queryId: this.queryId,
              candidateId: this.candidateId,
              companyStatus: {
                companyStatus,
                candidateEmail: data.userEmail,
                candidateFirstname: this.candidate.firstName,
                companyName: this.companyName,
                userId: localStorage.getItem('userId'),
                queryTitle: this.queryJobTitle,
              },
            };
            return this.techfaceProvider.updateCompanyStatus(updateCompanyObj);
          }
          return of(null);
        })
      )
      .pipe(takeUntil(this.onDestroy))
      .subscribe((match) => {
        if (!match) return;
        this.candidateAndCompanyStatus = {
          candidateStatus: match.candidateStatus,
          companyStatus: match.companyStatus,
        };
        this.navigationService.navigateByUrl(`queries/${this.queryId}`);
      });

  }

  onClick(step: number) {
    this.activeStep = step;
    if (this.activeStep === 1) {
      this.conversation.getMatches();
    }
  }
}
