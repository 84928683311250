import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { CandidateService } from '../../../../shared/services/services/candidate.service';

@Component({
  selector: 'app-documten-validation',
  templateUrl: './document-validation.component.html',
  styleUrls: ['./document-validation.component.scss'],
})
export class DocumentValidationComponent implements OnInit, OnChanges {
  @Input() candidateData: CandidateModel;
  validNewDocuments = false;
  constructor(private candidateService: CandidateService) {

  }

  ngOnInit(): void {

  }
  ngOnChanges(): void {
    if (this.candidateData?.cvDocument) {
      this.candidateService.getCandidateById(localStorage.getItem('candidateId')!)
        .subscribe(response => {
          if (!response) return
          this.validNewDocuments = response.CandidateObject.validating!
          this.candidateData = response.CandidateObject
        });
    }
  }
}
