import { Component, OnInit } from '@angular/core';
import { DisplayEmailModel } from '../../shared/models/displayEmailModel.model';
import { DisplayPasswordModel } from '../../shared/models/displayPasswordModel.model';
import { CandidateUserModel } from '../../shared/models/user.model';

@Component({
  selector: 'app-sign-in-candidate-page',
  templateUrl: './sign-in-candidate-page.component.html',
  styleUrls: ['./sign-in-candidate-page.component.scss'],
})
export class SignInCandidatePageComponent implements OnInit {
  displayEmail = true;
  displayPassword = false;
  displayConfirm = false;
  candidateData: CandidateUserModel;
  constructor() {}

  ngOnInit(): void {}

  setDispalyEmail(value: DisplayEmailModel): void {
    this.displayEmail = value.display;
    this.displayPassword = !value.display;
    this.candidateData = {
      email: value.email,
      firstName: value.firstName,
      lastName: value.lastName,
      password: undefined,
    };
  }
  setDispalyPassword(value: DisplayPasswordModel): void {
    this.displayPassword = value.display;
    this.displayConfirm = !value.display;
    this.candidateData.password = value.password;
  }
}
