<div *ngIf="data" class="chatting-area-container">
  <app-receiver-data
    (getStatusEmit)="changeStatus($event)"
    [receiverData]="data?.receiverData"
  ></app-receiver-data>
  <div id="oldMsg" class="old-msg-area">
    <div *ngFor="let msg of data?.conversationData">
      <app-single-chat [msg]="msg"></app-single-chat>
    </div>
  </div>
  <div
    [ngClass]="{ disable: !data?.receiverData?.canSend }"
    class="NgxEditor__Wrapper"
  >
    <ngx-editor 
      [(ngModel)]="msgText"
      id="msgText"
      [editor]="editor"
      [placeholder]="'Type here...'"
      [disabled]="!data?.receiverData?.canSend"
    >
      <div (click)="onClickSend()">
        <img src="assets/icons/send-button.svg" alt="send-icon" /></div
    ></ngx-editor>
  </div>
</div>
