import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent {
  /**
   * route when call base component page
   */
  constructor(public route: ActivatedRoute) {
    this.route.params.subscribe((params: Params) => {
      localStorage.setItem('lang', params['lang']);
    });
  }
}
