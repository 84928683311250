import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { PageRouterComponent } from './page-router.component';
import { NavigationAuthGuard } from '../shared/services/guards/NavigationAuthGuard.service';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { HeaderSideNavComponent } from '../components/header-side-nav/header-side-nav.component';
import { SharedModule } from '../shared/module/shared/shared.module';
import { QueriesPageComponent } from './queries-page/queries-page.component';
import { CompanyInformationComponent } from './../components/dashboard/company-information/company-information.component';
import { BasePageComponent } from './base-page/base-page.component';
import { LatestQueryComponent } from '../components/dashboard/latest-query/latest-query.component';
import { QueriesSummaryComponent } from './../components/dashboard/queries-summary/queries-summary.component';
import { FaqPageComponent } from './faq-page/faq-page.component';
import { MatTableModule } from '@angular/material/table';
import { MatGridListModule } from '@angular/material/grid-list';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgxEditorModule } from 'ngx-editor';
import { ToastrModule } from 'ngx-toastr';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng5SliderModule } from 'ng5-slider';
import { ChattingAreaComponent } from '../components/conversation/chatting-area/chatting-area.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TechfaceEffects } from '../shared/effects/techface.effects';
import * as fromReducer from '../shared/reducers/techface.reducer';
import { TechfaceConfigService, ITechfaceConfig } from '../techface.config';
import { QueryOverviewComponent } from './../components/query/query-overview/query-overview.component';
import { CandidateSummaryComponent } from './../components/query/candidate-summary/candidate-summary.component';
import { QueryPageComponent } from './query-page/query-page.component';
import { CandidatePageComponent } from './candidate-page/candidate-page.component';
import { AboutCandidateComponent } from './../components/candidate/about-candidate/about-candidate.component';
import { CandidateProfileOverviewComponent } from './../components/candidate/candidate-profile-overview/candidate-profile-overview.component';
import { DocumentsComponent } from './../components/candidate/documents/documents.component';
import { CvComponent } from './../components/candidate/cv/cv.component';
import { CertificateComponent } from './../components/candidate/certificate/certificate.component';
import { SkillsExpertiseComponent } from './../components/candidate/skills-expertise/skills-expertise.component';
import { NewQueryPageComponent } from './new-query-page/new-query-page.component';
import { BasicInfoComponent } from '../components/basic-info/basic-info.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SkillsComponent } from '../components/skills/skills.component';
import { ExpertisesComponent } from '../components/expertises/expertises.component';
import { JobDescriptionComponent } from '../components/job-description/job-description.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { SignInCandidatePageComponent } from './sign-in-candidate-page/sign-in-candidate-page.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { CandidateSignInEmailComponent } from '../components/candidate-sign-in/candidate-sign-in-email/candidate-sign-in-email.component';
import { CandidateSignInPasswordComponent } from '../components/candidate-sign-in/candidate-sign-in-password/candidate-sign-in-password.component';
import { CandidateSignInConfirmComponent } from '../components/candidate-sign-in/candidate-sign-in-confirm/candidate-sign-in-confirm.component';
import { CreateProfileComponent } from '../pages/candidate/create-profile/create-profile.component';
import { PersonalInfoComponent } from '../components/as-candidate/create-profile/personal-info/personal-info.component';
import { ProfileImageDescriptionComponent } from '../components/as-candidate/create-profile/profile-image-description/profile-image-description.component';
import { DocumentUploadComponent } from '../components/as-candidate/create-profile/document-upload/document-upload.component';
import { DocumentValidationComponent } from '../components/as-candidate/create-profile/document-validation/document-validation.component';
import { SkillExpertiseComponent } from '../components/as-candidate/create-profile/skill-expertise/skill-expertise.component';
import { NewSkillExpertiseComponent } from '../components/as-candidate/create-profile/new-skill-expertise/new-skill-expertise.component';
import { NewCategoryComponent } from '../components/as-candidate/create-profile/new-category/new-category.component';
import { WorkExperienceComponent } from '../components/as-candidate/create-profile/work-experience/work-experience.component';
import { CertificatesComponent } from '../components/as-candidate/create-profile/certificates/certificates.component';
import { AdditionalInformationComponent } from '../components/as-candidate/create-profile/additional-information/additional-information.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModesoAuthConfig } from '@modeso/modeso-lib-auth-fe';
import { ModesoAuthModule } from '@modeso/modeso-lib-auth-fe';
import { environment } from '../../environments/environment';
import { AuthHandler } from '../shared/iterceptors/authentication.session.interceptors';
import { DashboardGuard } from '../shared/services/guards/DashboardGuard';
import { ConversationComponent } from '../components/conversation/conversation/conversation.component';
import { MessagesComponent } from '../components/conversation/messages/messages.component';
import { ReceiverDataComponent } from '../components/conversation/receiver-data/receiver-data.component';
import { SingleChatComponent } from '../components/conversation/single-chat/single-chat.component';
import { CompanyMsgsPageComponent } from './company-msgs-page/company-msgs-page.component';

const routes: Routes = [
  {
    path: '',
    component: PageRouterComponent,
    canActivate: [NavigationAuthGuard],
    children: [
      {
        path: ':lang/dashboard',
        component: DashboardPageComponent,
        canActivate: [DashboardGuard],
      },
      {
        path: ':lang/queries',
        children: [
          {
            path: '',
            component: QueriesPageComponent,
          },
          {
            path: 'new',
            component: NewQueryPageComponent,
          },
          {
            path: ':queryId',
            children: [
              {
                path: '',
                component: QueryPageComponent,
              },
              {
                path: 'edit',
                component: NewQueryPageComponent,
              },
              {
                path: 'candidate/:candidateId',
                component: CandidatePageComponent,
              },
            ],
          },
        ],
      },
      {
        path: ':lang/faq',
        component: FaqPageComponent,
      },
      {
        path: ':lang/msg',
        component: CompanyMsgsPageComponent,
      },
      {
        path: ':lang/me',
        children: [
          {
            path: '',
            component: CandidatePageComponent,
          },
          {
            path: 'edit',
            children: [
              {
                path: '',
                component: CreateProfileComponent,
              },
              {
                path: ':step',
                component: CreateProfileComponent,
              },
            ],
          },
        ],
      },
      {
        path: '**',
        redirectTo: 'en-us/dashboard',
        pathMatch: 'full',
      },
    ],
  },
];
const authServiceConfig: ModesoAuthConfig = {
  apiGateway: environment.modeso.apiGateway,
  serviceId: 'auth service',
};

@NgModule({
  declarations: [
    PageRouterComponent,
    DashboardPageComponent,
    HeaderSideNavComponent,
    QueriesPageComponent,
    FaqPageComponent,
    BasePageComponent,
    CompanyInformationComponent,
    LatestQueryComponent,
    QueriesSummaryComponent,
    QueryOverviewComponent,
    CandidateSummaryComponent,
    QueryPageComponent,
    CandidatePageComponent,
    AboutCandidateComponent,
    CandidateProfileOverviewComponent,
    BasicInfoComponent,
    DocumentsComponent,
    CvComponent,
    CertificateComponent,
    SkillsExpertiseComponent,
    NewQueryPageComponent,
    SkillsComponent,
    ExpertisesComponent,
    JobDescriptionComponent,
    SignInCandidatePageComponent,
    SignInComponent,
    CandidateSignInEmailComponent,
    CandidateSignInPasswordComponent,
    CandidateSignInConfirmComponent,
    CreateProfileComponent,
    PersonalInfoComponent,
    ProfileImageDescriptionComponent,
    DocumentUploadComponent,
    DocumentValidationComponent,
    SkillExpertiseComponent,
    NewSkillExpertiseComponent,
    NewCategoryComponent,
    WorkExperienceComponent,
    CertificatesComponent,
    AdditionalInformationComponent,
    ConversationComponent,
    MessagesComponent,
    ChattingAreaComponent,
    ReceiverDataComponent,
    SingleChatComponent,
    CompanyMsgsPageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatTableModule,
    MatIconModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    AngularEditorModule,
    NgxEditorModule,
    NgxFileDropModule,
    NgxSpinnerModule,
    DragDropModule,
    Ng2SearchPipeModule,
    Ng5SliderModule,
    ModesoAuthModule.forRoot(authServiceConfig),
    ToastrModule.forRoot(),
    StoreModule.forFeature(fromReducer.techfaceFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([TechfaceEffects]),
    StoreModule.forRoot([]),
    EffectsModule.forRoot([TechfaceEffects]),
  ],
  exports: [RouterModule],
})
export class PagesRouterModule {
  public static forRoot(
    config: ITechfaceConfig
  ): ModuleWithProviders<PagesRouterModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: TechfaceConfigService,
          useValue: config,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'de-CH' },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
      ],
    };
  }
}
