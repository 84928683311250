import { HttpRequestOptions } from './../models/HttpRequestOptions';
import { HttpParams, HttpHeaders } from '@angular/common/http';

export class BaseApiService {
  constructor() {}

  // setHttpOptions(): HttpRequestOptions {
  //   let defaultHeaders = new HttpHeaders();
  //   defaultHeaders = defaultHeaders.append('Accept', 'application/json');
  //   const requestOptions = { headers: defaultHeaders, withCredentials: true };
  //   return requestOptions;
  // }
}
