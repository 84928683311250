import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  //language
  lang: string;

  constructor(private router: Router) { }

  /**
   * get language from local storage and navigate with correct language
   * @param navigateTo
   */
  navigateByUrl(navigateTo: string) {
    this.lang = localStorage.getItem('lang');
    this.router.navigateByUrl(this.lang + '/' + navigateTo);
  }
}
