import swal from 'sweetalert2';

import { Component, enableProdMode, ModuleWithComponentFactories, OnInit, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IQuery } from '../../shared/interfaces/query.interface';
import { IUpdateQuery } from '../../shared/interfaces/updateQuery.interface';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { BasePageComponent } from '../base-page/base-page.component';
import { BasicInfoModel } from '../../shared/models/basicInfo.model';
import { QueriesService } from '../../shared/services/services/queries.service';
import { MetaDataIdsModel } from '../../shared/models/metaDataIds.model';
import { MetaDataNameModel } from '../../shared/models/metaDataName.model';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { Avaibality } from '../../shared/enums/availability.enum';
import { Types } from '../../shared/enums/types.enum';
import { SkillExpertiseByCategoryModel } from '../../shared/models/skillExpertiseByCategory.model';
import { SkillExpertiseByCategoriesModel } from '../../shared/models/skillExpertiseByCategories.model';
import { CandidateExpertiseModel } from '../../shared/models/candiddateExpertise.model';
import { skipLast } from 'rxjs/operators';
import { Location } from '@angular/common'

@Component({
  selector: 'app-new-query-page',
  templateUrl: './new-query-page.component.html',
  styleUrls: ['./new-query-page.component.scss']
})
export class NewQueryPageComponent extends BasePageComponent implements OnInit {
  basicInfoData: BasicInfoModel;
  metadata: any;
  savingProgress = false;
  desiredStatus = "DRAFT";
  descriptionData = '';
  query: any = {
    companyId: localStorage.getItem("companyId")
  };
  updateQuery: IUpdateQuery;
  isCreateMode: boolean;
  queryId: string;
  id: any;

  // result from the metadata
  skillsExpertisesByCategory: SkillExpertiseByCategoriesModel;
  allLanguages: MetaDataNameModel[] = [];
  allIndustries: MetaDataNameModel[] = [];

  // result from api
  languagesArray: MetaDataNameModel[] = [];
  industryArray: MetaDataNameModel[] = [];

  // intial value for MetaData by ids
  languagesIds = [];
  industryIds = [];

  d

  constructor(
    public route: ActivatedRoute,
    private navigationService: NavigationService,
    private techfaceProvider: TechfaceProvider,
    private queriesService: QueriesService,
    private location: Location) {
    super(route);
  }
  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.queryId = paramMap.get('queryId');
      this.isCreateMode = !this.queryId;
      this.getQuery();
    });
    this.getAllMetadata();
  }

  getAllMetadata(): void {
    this.techfaceProvider
      .getAllSkillExprtiseByCategory()
      .subscribe((skillExpertise) => {
        if (skillExpertise) {
          this.skillsExpertisesByCategory = {
            expertiseByCategory: skillExpertise.expertiseByCategory.filter(c => c.skillsExpertises.length > 0),
            skillByCategory: skillExpertise.skillByCategory.filter(c => c.skillsExpertises.length > 0)
          }
        }
      })

    this.techfaceProvider.getAllMetaData().subscribe(metadata => {
      if (metadata) {
        this.allLanguages = metadata.languages.map(languages => {
          return { id: languages._id, languageId: languages._id, name: languages.name };
        });
        this.allIndustries = metadata.industries.map(industry => {
          return { id: industry._id, industryId: industry._id, name: industry.name };
        });

        this.metadata = metadata;
        this.bindQueryMetadata(metadata)
      }
    });

  }

  getQuery(): void {

    if (this.queryId) {
      this.techfaceProvider.getQueryById(this.queryId).subscribe(query => {
        if (query) {
          this.bindQuery(query);
        }
      });
    } else {
      this.basicInfoData = {
        industries: [],
        jobTitle: "",
        languages: [],
        availabilityNeeded: [20, 80],
      }
      this.bindingQuery({
        companyId: localStorage.getItem("companyId"),
        description: "",
        expertises: [],
        industries: [],
        jobTitle: "",
        name: "",
        languages: [],
        skills: [],
        status: "DRAFT",
      });

    }

  }

  bindQuery(query): void {
    this.bindingQuery({ ...query, industries: query.industry });
    this.bindQueryMetadata(this.metadata);
    this.bindingBasicInfoData(query);
    this.bindingDescription(query);
  }

  // Binding data
  bindingQuery(query: IQuery): void {
    this.query = {
      companyId: query.companyId,
      status: query.status,
      name: query.jobTitle,
      jobTitle: query.jobTitle,
      industry: query.industries,
      availabilityNeeded: query.availabilityNeeded,
      languages: query.languages,
      startDate: query.startDate,
      applicationDeadline: query.applicationDeadline,
      description: query.description,
      skills: query.skills,
      expertises: query.expertises,
    };
  }


  availabilityRegex = new RegExp('Work Pensum: ([1-9]0) - ([1-9]0{1,2})%')
  bindingBasicInfoData(query: IQuery): void {

    const availabilityNeeded = this.availabilityRegex.exec(query.availabilityNeeded);
    this.basicInfoData = {
      jobTitle: query.jobTitle,
      industries: this.industryArray,
      availabilityNeeded: availabilityNeeded?.length == 3 ?
        availabilityNeeded.slice(1).map(av => Number.parseInt(av)) :
        [20, 80],
      languages: this.languagesArray,
      startDate: query.startDate,
      applicationDeadline: query.applicationDeadline,
    };
  }

  bindingDescription(query: IQuery): void {
    this.descriptionData = query.description;
  }

  bindQueryMetadata(metadata) {
    if (!metadata) return;

    this.query.skills = this.query.skills ? this.query.skills.map(skill => {
      return {
        id: skill.skillId, skillId: skill.skillId,
        name: metadata.skills.find(md => md._id === skill.skillId).name
      };
    }) : [];

    this.query.expertises = this.query.expertises ? this.query.expertises.map(expertise => {
      return {
        id: expertise.expertiseId, expertiseId: expertise.expertiseId,
        name: metadata.expertises.find(md => md._id === expertise.expertiseId).name
      };
    }) : [];

    this.languagesArray = this.query.languages ? this.query.languages.map(language => {
      return {
        id: language.languageId, languageId: language.languageId,
        name: metadata.languages.find(md => md._id === language.languageId).name
      };
    }) : [];

    this.industryArray = this.query.industry ? this.query.industry.map(industry => {
      return {
        id: industry.industryId, industryId: industry.industryId,
        name: metadata.industries.find(md => md._id === industry.industryId).name
      };
    }) : [];

    this.bindingBasicInfoData(this.query)
  }

  getBasicInfo(newItem: BasicInfoModel): void {
    this.query.name = newItem.jobTitle;
    this.query.jobTitle = newItem.jobTitle;
    this.query.industry = newItem.industries;
    this.query.availabilityNeeded = `Work Pensum: ${newItem.availabilityNeeded[0]} - ${newItem.availabilityNeeded[1]}%`;
    this.query.startDate = newItem.startDate;
    this.query.applicationDeadline = newItem.applicationDeadline;
    this.query.languages = newItem.languages;

    this.checkBasicInfo(this.desiredStatus, true);
  }

  getSkills(skills: MetaDataNameModel): void {
    this.query.skills = skills;
    this.checkSkills(this.desiredStatus, true);

  }
  skillsError: boolean = false;
  checkSkills(desiredStatus: string, showErrors: boolean) {
    const error = !(desiredStatus !== "ACTIVE" || !!this.query.skills && this.query.skills.length >= 3 && this.query.skills.length <= 8)
    if (showErrors) this.skillsError = error;
    return !error;
  }

  getExpertise(expertises: MetaDataNameModel): void {
    this.query.expertises = expertises;
    this.checkExpertise(this.desiredStatus, true);
  }

  expertiseError: boolean = false;
  checkExpertise(desiredStatus: string, showErrors: boolean) {
    const error = !(desiredStatus !== "ACTIVE" || !!this.query.expertises && this.query.expertises.length >= 3 && this.query.expertises.length <= 8)
    if (showErrors) this.expertiseError = error;
    return !error;
  }

  getDescripttion(description: string): void {
    this.query.description = description;
    this.checkDescription(this.desiredStatus, true);
  }
  descriptionError: boolean = false;
  checkDescription(desiredStatus: string, showErrors: boolean) {
    const error = !(desiredStatus !== "ACTIVE" || !!this.query.description && this.query.description.length >= 50 && this.query.description.length <= 5000)
    if (showErrors) this.descriptionError = error;
    return !error;
  }
  onCreate(forwardToDashboard: boolean = false): void {
    this.queriesService.createNewQuery(this.query).subscribe(({ query }) => {
      this.savingProgress = false;

      if (forwardToDashboard)
        this.navigationService.navigateByUrl('/');
      else
        this.navigationService.navigateByUrl(`queries/${query._id}/edit`);

    });
  }

  onUpdate(forwardToDashboard: boolean = false): void {
    const updateQuery: IUpdateQuery = {
      id: this.queryId,
      queryObj: this.query,
    };
    this.queriesService.updateNewQuery(updateQuery).subscribe((query) => {
      this.savingProgress = false;
      if (forwardToDashboard)
        this.navigationService.navigateByUrl('/');
    });
  }

  saveBtn(): void {
    this.desiredStatus = this.query.status;
    if (!this.checkFormValid(this.desiredStatus, true))
      return;

    this.savingProgress = true;
    if (this.isCreateMode) {
      this.query.status = 'DRAFT';
      this.onCreate();
    } else {
      this.onUpdate();
    }
  }

  checkFormValid(desiredStatus: string, showErrors: boolean) {
    const basicInfo = this.checkBasicInfo(desiredStatus, showErrors);
    const description = this.checkDescription(desiredStatus, showErrors);
    const expertises = this.checkExpertise(desiredStatus, showErrors);
    const skills = this.checkSkills(desiredStatus, showErrors)
    return basicInfo && description && expertises && skills;
  }

  basicInfoErrors: { [key: string]: boolean } = {}
  checkBasicInfo(desiredStatus: String, showErrors: boolean) {
    let errors: any = { jobTitle: this.query.jobTitle && this.query.jobTitle.length > 3 };
    if (desiredStatus === "ACTIVE") {

      errors = {
        jobTitle: this.query.jobTitle && this.query.jobTitle.length > 3,
        languages: !!this.query.languages && !!this.query.languages.length,
        industries: !!this.query.industry && !!this.query.industry.length,
        availabilityNeeded: !!this.query.availabilityNeeded,
      }
    }
    if (showErrors) {
      this.basicInfoErrors = errors;
    }
    return Object.values(errors).every(b => b === true)
  }

  archiveQuery(): void {
    this.desiredStatus = "ARCHIVED"
    this.query.status = this.desiredStatus;
    this.onUpdate(true);
  }

  saveAndPublishBtn(): void {
    this.desiredStatus = "ACTIVE"
    if (!this.checkFormValid('ACTIVE', true))
      return;

    this.query.status = 'ACTIVE';
    if (this.isCreateMode) {
      this.onCreate(true);
    } else {
      this.onUpdate(true);
    }
  }

  isDisplayNew = false;
  type = 'skill';
  newSkillsMetadata: SkillExpertiseByCategoryModel[];
  skillExpertise: any;
  /**
   * call function when select new item from skill or expertise page
   * @param isDisplay :display new skill-expertise page or not
   * @param type skill or expertise
   */
  displayNewItem(isDisplay: boolean, type: string): void {
    this.isDisplayNew = isDisplay;
    this.type = type;

    if (isDisplay) {
      this.skillExpertise = type == "skill" ?
        this.query.skills : this.query.expertises;

      this.newSkillsMetadata = (type == "skill" ?
        this.skillsExpertisesByCategory.skillByCategory :
        this.skillsExpertisesByCategory.expertiseByCategory)

        .map(c => {
          return {
            ...c, skillsExpertises: c.skillsExpertises
              .map(ske => { return { ...ske, id: ske._id, selected: !!this.skillExpertise.find(se => se.id == ske._id) } })
          }
        });
    }
  }

  selectSkillsExpertise(skillsExpertise: CandidateExpertiseModel[]) {
    if (this.type == "skill") {
      this.query.skills = skillsExpertise;
    } else {
      this.query.expertises = skillsExpertise;
    }
    this.bindQueryMetadata(this.metadata)
  }

  addExpertise(): void {
    this.displayNewItem(true, 'expertise')
  }
  addSkills(): void {
    this.displayNewItem(true, 'skill')
  }

  onClickBack(): void {
    this.location.back()
  }
}

