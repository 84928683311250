import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-single-chat',
  templateUrl: './single-chat.component.html',
  styleUrls: ['./single-chat.component.scss'],
})
export class SingleChatComponent {
  @Input() msg: any;
  msgText = '';
  constructor() {}
}
