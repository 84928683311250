import { Component, OnInit, ViewChild } from '@angular/core';
import { AutoRefreshTimer } from '../../shared/enums/autoRefreshTimer.enum';
import { SharedDataService } from '../../shared/services/services/shared-data.service';
import { timer } from 'rxjs';
import { ConversationComponent } from '../../components/conversation/conversation/conversation.component';

@Component({
  selector: 'app-company-msgs-page',
  templateUrl: './company-msgs-page.component.html',
  styleUrls: ['./company-msgs-page.component.scss'],
})
export class CompanyMsgsPageComponent implements OnInit {
  @ViewChild('conversation', { static: false }) conversation: ConversationComponent;

  source = timer(AutoRefreshTimer.dueTime,AutoRefreshTimer.periodOrScheduler);

  constructor(private sharedDataService: SharedDataService) {}

  ngOnInit(): void {
    this.source.subscribe(() => {
    this.sharedDataService.getToTalComapnyMsgs();
    this.conversation.getMatches();
    });
  }
}
