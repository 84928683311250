import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SkillExpertiseByCategoryModel } from '../../../../shared/models/skillExpertiseByCategory.model';
import { SkillsExpertisesModel } from '../../../../shared/models/skillsExpertise.model';
@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
})
export class NewCategoryComponent implements OnInit {
  @Input() category: SkillExpertiseByCategoryModel;
  @Input() searchText: string;
  @Output() selectedItemsEmmitter = new EventEmitter();

  items: SkillsExpertisesModel[] = [];
  isDisplay = false;

  constructor() {}

  ngOnInit(): void {
    this.getItemsAndSelectedItems();
  }

  /**
   * push items in items array and selected Items array
   */
  getItemsAndSelectedItems(): void {
    this.category.skillsExpertises.forEach((item) => {
      this.items.push(this.bindItem(item));
    });
  }

  /**
   * on display and hide each category
   */
  onClickDisplay(): void {
    this.isDisplay = !this.isDisplay;
  }

  /**
   * call onSelect function when check or uncheck item
   * @param item
   */
  onSelect(item: SkillsExpertisesModel): void {
    item.selected = !item.selected
    const selectedItems = this.items.filter(i => i.selected)
    this.selectedItemsEmmitter.emit(selectedItems);
  }

 
  bindItem(item: SkillsExpertisesModel): SkillsExpertisesModel {
    return {
      id: item._id,
      name: item.name,
      type: item.type,
      selected: item.selected,
      category: item.category,
      isfavorite: item.isfavorite,
    };
  }

}
