import { Injectable } from '@angular/core';
import { CompanyResponseModel } from '../../models/companyResponse.model';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ICompanyInformation } from '../../interfaces/comapny.interface';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';

export interface ICompaniesResponse {
  companies: CompanyResponseModel[];
}
export interface ICompanyResponse {
  CompanyObject: ICompanyInformation;
}

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private microserviceName = 'company';
  private readonly companyRoute = '/';

  constructor(private http: HttpClient) {
    super();
  }

  public getCompanyById(companyId: string): Observable<ICompanyResponse> {
    const url = `${this.server}${this.microserviceName}${this.companyRoute}${companyId}`;
    return this.http.get<ICompanyResponse>(url);
  }
}
