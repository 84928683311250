<div class="content">
  <h1>Set a password</h1>
  <form
    [formGroup]="passwordForm"
    #myForm="ngForm"
    (ngSubmit)="submit(passwordForm)"
    id="login-form"
  >
    <div class="email">
      <label> Email </label>
      <div>{{ candidateData.email }}</div>
    </div>
    <div class="form-group">
      <label> Password* </label>
      <div>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="password"
            placeholder="Please enter password"
            name="password"
            id="password"
            formControlName="password"
          />
          <mat-error class="error-msg" id="password-invalid">min. length of 12 characters, containing at least one upper- and lower case letter, and a number</mat-error>
          <mat-hint>min. length of 12 characters, containing at least one upper- and lower case letter, and a number</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <label> Repeat Password* </label>
      <div>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="password"
            placeholder="Please repeat password"
            name="repeat-password"
            id="repeat-password"
            formControlName="repeatPassword"
          />
          <mat-error class="error-msg" id="repeat-password-invalid">
            Password doesn't match</mat-error
          >
        </mat-form-field>
        <div class="error-msg">{{ userErrorMsg }}</div>
      </div>
    </div>

    <div class="submit-btn">
      <button class="btn btn-primary" type="submit" [disabled]="formButtonDisabled()">Proceed</button>
    </div>
  </form>
</div>
