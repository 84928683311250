import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { SharedDataService } from '../../shared/services/services/shared-data.service';

@Component({
  selector: 'app-header-side-nav',
  templateUrl: './header-side-nav.component.html',
  styleUrls: ['./header-side-nav.component.scss'],
})
export class HeaderSideNavComponent implements OnInit {
  id: string;
  candidateId: string;
  companyId: string;
  unreadMsgs: number;
  /**
   * set id depend on navigation
   * @param navigationService service to navigation
   */
  constructor(
    private navigationService: NavigationService,
    private sharedDataService: SharedDataService
  ) { }

  ngOnInit(): void {
    this.id = this.getUrl();
    this.companyId = localStorage.getItem('companyId');
    this.candidateId = localStorage.getItem('candidateId');
      this.sharedDataService.currentUnreadTotalCompanyMsgsCount.subscribe(
        (num) => {
          this.unreadMsgs = num > 0 ? num : 0;
        }
      );
  }
  /**
   * click to navigate
   * in case of logout remove username/email from local storage
   * @param id
   */
  onClick(id: string): void {
    this.id = id;
    switch (id) {
      case 'dashboard':
        this.navigationService.navigateByUrl('dashboard');
        break;
      case 'queries':
        this.navigationService.navigateByUrl('queries');
        break;
      case 'faq':
        this.navigationService.navigateByUrl('faq');
        break;
      case 'logout':
        localStorage.clear();
        this.navigationService.navigateByUrl('login');
        break;
      case 'me':
        this.navigationService.navigateByUrl('me');
        break;
      case 'msg':
        this.navigationService.navigateByUrl('msg');
        break;
      default:
        return;
    }
  }

  getUrl(): string {
    if (window.location.href.indexOf('/dashboard') > -1) {
      return 'dashboard';
    } else if (window.location.href.indexOf('/queries') > -1) {
      return 'queries';
    } else if (window.location.href.indexOf('/faq') > -1) {
      return 'faq';
    } else if (window.location.href.indexOf('/me') > -1) {
      return 'me';
    }
  }
}
