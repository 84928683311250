import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { ICompanyInformation } from '../interfaces/comapny.interface';
import { IQuery } from '../interfaces/query.interface';
import * as fromActions from '../actions/techface.action';
import { IMatch } from '../interfaces/match.interface';
import { ICandidate } from '../interfaces/candidate.interface';
import { MetaDataModel } from '../models/metaData.model';
import { UserResponseModel } from '../models/userResponse.model';
import { IUpdateQuery } from '../interfaces/updateQuery.interface';
import { TokenModel } from '../models/token.model';

import { IUpdateUser } from '../interfaces/updateUser.interface';
import { IUpdateCandidate } from '../interfaces/updateCandidate.interface';
import { SkillExpertiseByCategoriesModel } from '../models/skillExpertiseByCategories.model';

export const techfaceFeatureKey = 'modesoTechfaceProject';
export const adapter: EntityAdapter<ICompanyInformation> =
  createEntityAdapter<ICompanyInformation>({
    selectId: selectCompanyId,
  });
export const selectFeature = (state: AppState) => state.modesoTechfaceProject;

export const selectFeatureCompanies = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const selectFeatureQueries = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export function selectCompanyId(a: ICompanyInformation): string {
  return a.companyId;
}
export function selectQueriesByCompanyId(a: IQuery): string {
  return a.companyId;
}
export interface FeatureState extends EntityState<ICompanyInformation> {
  companyId: string;
  companyObject: ICompanyInformation;
  queryMetaDataObject: MetaDataModel;
  allMetaDataObject: MetaDataModel;
  candidateObject: ICandidate;
  candidatesObject: ICandidate[];
  matchObject: IMatch;
  queriesObject: IQuery[];
  queryObject: IQuery;
  matchesObject: IMatch[];
  login: UserResponseModel;
  updataQuery: IUpdateQuery;
  metaDatabyId: MetaDataModel;
  token: TokenModel;
  metaDataObject: MetaDataModel;
  updataUser: IUpdateUser;
  addCandidateObject: ICandidate;
  addUser: UserResponseModel;
  addUserError: any;
  updataCandidate: IUpdateCandidate;
  uploadUrl: string;
  skillExpertiseByCategories: SkillExpertiseByCategoriesModel;
  signInError: any;
  email: { userEmail: string };
}
export const selectCompany = createSelector(
  selectFeatureCompanies,
  (state: FeatureState) => state.companyObject
);
export const selectQueries = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.queriesObject
);

export const selectMatch = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.matchObject
);
export const selectMatches = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.matchesObject
);
export const selectCandidate = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.candidateObject
);
export const selectAddCandidate = createSelector(
  selectFeature,
  (state: FeatureState) => state.addCandidateObject
);

export const selectCandidates = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.candidatesObject
);

export const selectAddUserError = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.addUserError
);

export const selectQueryMetaData = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.queryMetaDataObject
);

export const selectAllMetaData = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.allMetaDataObject
);

export const selectSkillExpertiseByCategories = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.skillExpertiseByCategories
);

export const selecUploadUrl = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.uploadUrl
);

export const selectQuery = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.queryObject
);

export const selectEmailByCandidateId = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.email
);

export const selectToken = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.token
);

export const selectFeatureUser = createSelector(
  selectFeature,
  (state: FeatureState) => state
);

export const selectFeatureLogin = createSelector(
  selectFeature,
  (state: FeatureState) => state.login
);
export const selectSignInError = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.signInError
);
export const selectUpdateQuery = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.queryObject
);
export const selectAddUser = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.addUser
);
export const selectUpdateUser = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.login
);
export const selectUpdateCandidate = createSelector(
  selectFeatureQueries,
  (state: FeatureState) => state.candidateObject
);

export const selectMetaData = createSelector(
  selectFeatureCompanies,
  (state: FeatureState) => state.metaDataObject
);
export const selectMetaDataById = createSelector(
  selectFeatureCompanies,
  (state: FeatureState) => state.metaDatabyId
);

export interface AppState {
  modesoTechfaceProject: FeatureState;
}

export const { selectAll, selectEntities, selectIds, selectTotal } =
  adapter.getSelectors();

export const initialState: FeatureState = adapter.getInitialState({
  companyId: null,
  companyObject: null,
  queryObject: null,
  candidateObject: null,
  metaDataObject: null,
  allMetaDataObject: null,
  matchObject: null,
  queryMetaDataObject: null,
  queriesObject: [],
  matchesObject: [],
  candidatesObject: [],
  login: null,
  query: null,
  langauagesMetaData: [],
  metaDatabyId: null,
  updataQuery: null,
  token: null,
  updataUser: null,
  addCandidateObject: null,
  addUser: null,
  addUserError: null,
  updataCandidate: null,
  uploadUrl: null,
  signInError: null,
  skillExpertiseByCategories: null,
  email:null
});

const companiesReducer = createReducer(
  initialState,
  on(fromActions.onGetCompanyById, (state) => ({
    ...state,
    companyObject: null,
  })),
  on(fromActions.onGetCompanyByIdFail, (state) => ({ ...state })),
  on(fromActions.onGetCompanyByIdSuccess, (state, action) => {
    return { ...state, companyObject: action.payload };
  }),

  on(fromActions.onGetQueriesByCompanyId, (state) => ({
    ...state,
    queriesObject: null,
  })),
  on(fromActions.onGetQueriesByCompanyIdFail, (state) => ({ ...state })),
  on(fromActions.onGetQueriesByCompanyIdSuccess, (state, action) => {
    return { ...state, queriesObject: action.payload };
  }),

  on(fromActions.onGetQueryById, (state) => ({ ...state, queryObject: null })),
  on(fromActions.onGetQueryByIdFailed, (state) => ({ ...state })),
  on(fromActions.onGetQueryByIdSuccessfully, (state, action) => {
    return { ...state, queryObject: action.payload };
  }),

  on(fromActions.onGetMatchesByQueryId, (state) => ({
    ...state,
    matchesObject: null,
  })),
  on(fromActions.onGetMatchesByQueryIdFail, (state) => ({ ...state })),
  on(fromActions.onGetMatchesByQueryIdSuccess, (state, action) => {
    return { ...state, matchesObject: action.payload };
  }),

  on(fromActions.onGetSkillExpertiseByCatefgories, (state) => ({ ...state })),
  on(fromActions.onGetSkillExpertiseByCatefgoriesFailed, (state) => ({
    ...state,
  })),
  on(
    fromActions.onGetSkillExpertiseByCatefgoriesSuccessfully,
    (state, action) => {
      return { ...state, skillExpertiseByCategories: action.payload };
    }
  ),
  on(fromActions.onGetCandidateById, (state) => ({
    ...state,
    candidateObject: null,
  })),
  on(fromActions.onGetCandidateByIdFail, (state) => ({ ...state })),
  on(fromActions.onGetCandidateByIdSuccess, (state, action) => {
    return { ...state, candidateObject: action.payload };
  }),

  on(fromActions.onGetCandidatesByIds, (state) => ({
    ...state,
    candidatesObject: null,
  })),
  on(fromActions.onGetCandidatesByIdsFail, (state) => ({ ...state })),
  on(fromActions.onGetCandidatesByIdsSuccess, (state, action) => {
    return { ...state, candidatesObject: action.payload };
  }),

  // add new Candidate
  on(fromActions.onAddCandidate, (state) => ({
    ...state,
  })),
  on(fromActions.onAddCandidateFailed, (state) => ({ ...state })),
  on(fromActions.onAddCandidateSuccessfully, (state, action) => {
    return { ...state, addCandidateObject: action.payload };
  }),

  on(fromActions.onGetMetaData, (state) => ({ ...state })),
  on(fromActions.onGetMetaDataIsSuccessfully, (state, action) => ({
    ...state,
    allMetaDataObject: action.payload,
  })),
  on(fromActions.onGetMetaDataIsFailed, (state, action) => ({ ...state })),

  on(fromActions.onGetMetadataByIds, (state) => ({
    ...state,
    metaDataObject: null,
  })),
  on(fromActions.onGetMetadataByIdsFailed, (state) => ({ ...state })),
  on(fromActions.onGetMetadataByIdsSuccessfully, (state, action) => {
    return { ...state, metaDataObject: action.payload };
  }),

  on(fromActions.onGetQueryMetadataByIds, (state) => ({
    ...state,
    queryMetaDataObject: null,
  })),
  on(fromActions.onGetQueryMetadataByIdsFailed, (state) => ({ ...state })),
  on(fromActions.onGetQueryMetadataByIdsSuccessfully, (state, action) => {
    return { ...state, queryMetaDataObject: action.payload };
  }),

  on(fromActions.onUpdateCompanyStatus, (state) => ({
    ...state,
    matchObject: null,
  })),
  on(fromActions.onUpdateCompanyStatusFail, (state) => ({ ...state })),
  on(fromActions.onUpdateCompanyStatusSuccess, (state, action) => {
    return { ...state, matchObject: action.payload };
  }),
  on(fromActions.onAddUser, (state) => ({
    ...state,
    addUserError: undefined,
    addUser: null,
  })),
  on(fromActions.onAddUserFailed, (state, action) => ({
    ...state,
    addUserError: action.payload,
  })),
  on(fromActions.onAddUserSuccessfully, (state, action) => {
    return { ...state, addUserError: undefined, addUser: action.payload };
  }),

  on(fromActions.onGetMatchByQueryAndCandidateIds, (state) => ({
    ...state,
    matchObject: null,
  })),
  on(fromActions.onGetMatchByQueryAndCandidateIdsFail, (state) => ({
    ...state,
  })),
  on(fromActions.onGetMatchByQueryAndCandidateIdsSuccess, (state, action) => {
    return { ...state, matchObject: action.payload };
  }),

  // Log in
  on(fromActions.signIn, (state) => ({ ...state, signInError: undefined })),
  on(fromActions.onSignInSuccessfully, (state, action) => ({
    ...state,

    login: action.payload,
    error: undefined,
    signInError: undefined,
  })),
  on(fromActions.onSignInFailed, (state, action) => ({
    ...state,
    login: undefined,
    signInError: action.payload,
    error: action.payload,
  })),

  // get token
  on(fromActions.getToken, (state) => ({ ...state })),
  on(fromActions.onGetTokenSuccessfully, (state, action) => ({
    ...state,
    token: action.payload,
    error: undefined,
  })),
  on(fromActions.onGetTokenFailed, (state, action) => ({
    ...state,
    token: undefined,
    error: action.payload,
  })),
  // Get query by Id
  on(fromActions.onGetQueryById, (state) => ({ ...state, query: null })),
  on(fromActions.onGetQueryByIdFailed, (state) => ({ ...state })),
  on(fromActions.onGetQueryByIdSuccessfully, (state, action) => {
    return { ...state, query: action.payload };
  }),

  // add new Query
  on(fromActions.onCreateNewQuery, (state) => ({ ...state, query: null })),
  on(fromActions.onCreateNewQuerySuccessfully, (state, action) => ({
    ...state,
    query: action.payload,
  })),
  on(fromActions.onCreateNewQueryFailed, (state, action) => ({
    ...state,
    query: undefined,
    status: 'onLoadNewQueryFailed',
  })),

  // edit  Query
  on(fromActions.onUpdateQuery, (state) => ({ ...state, updataQuery: null })),
  on(fromActions.onUpdateQuerySuccessfully, (state, action) => ({
    ...state,
    query: action.payload,
  })),
  on(fromActions.onUpdateQueryFailed, (state, action) => ({
    ...state,
    query: undefined,
    status: 'onUpdateQueryFailed',
  })),
  on(fromActions.onUpdateUser, (state) => ({ ...state, updataUser: null })),
  on(fromActions.onUpdateUserFailed, (state) => ({ ...state })),
  on(fromActions.onUpdateUserSuccessfully, (state, action) => {
    return { ...state, login: action.payload };
  }),
  on(fromActions.onUpdateCandidate, (state) => ({
    ...state,
    updataCandidate: null,
  })),
  on(fromActions.onUpdateCandidateFailed, (state) => ({ ...state })),
  on(fromActions.onUpdateCandidateSuccessfully, (state, action) => {
    return { ...state, candidateObject: action.payload };
  }),

  on(fromActions.onGetEmailByCandidateId, (state) => ({
    ...state,
    email: null,
  })),
  on(fromActions.onGetEmailByCandidateIdFailed, (state) => ({ ...state })),
  on(fromActions.onGetEmailByCandidateIdSuccessfully, (state, action) => {
    return { ...state, email: action.payload };
  }),
  // Metadata
  on(fromActions.onGetMetadata, (state) => ({ ...state })),
  on(fromActions.onGetMetadataFailed, (state) => ({ ...state })),
  on(fromActions.onGetMetadataSuccessfully, (state, action) => {
    return { ...state, metaDataObject: action.payload };
  })
);

export function reducer(state: FeatureState | undefined, action: Action) {
  return companiesReducer(state, action);
}
