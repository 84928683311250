import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MetaDataIdsModel } from '../../models/metaDataIds.model';
import { MetaDataModel } from '../../models/metaData.model';
import { SkillExpertiseByCategoriesModel } from '../../models/skillExpertiseByCategories.model';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';
import { TechfaceConfigService } from '../../../techface.config';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private microserviceName = 'metadata';
  private byIds = 'byIds';

  private readonly route = '/';

  constructor(
    private http: HttpClient,
    @Inject(TechfaceConfigService) private config
  ) {
    super();

    this.server = config.apiGateway;
  }

  public getAllMetaData(): Observable<MetaDataModel> {
    const url = `${this.server}${this.microserviceName}`;
    return this.http.get<MetaDataModel>(url);
  }

  /**
   * get data all skills and expertises with this ids
   * @param skillsAndExpertisesId
   */
  public getMetadataByIds(
    metadataIds: MetaDataIdsModel
  ): Observable<MetaDataModel> {
    const url = `${this.server}${this.microserviceName}${this.route}${this.byIds}`;
    return this.http.post<MetaDataModel>(url, metadataIds);
  }

  /**
   * get data all skills and expertises with this ids
   * @param skillsAndExpertisesId
   */
  public getAllSkillExpertiseByCategories(): Observable<SkillExpertiseByCategoriesModel> {
    const categories = 'categories';
    const url = `${this.server}${this.microserviceName}${this.route}${categories}`;
    return this.http.get<SkillExpertiseByCategoriesModel>(url);
  }
}
