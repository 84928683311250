import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICandidate } from '../../interfaces/candidate.interface';
import { CandidatesIdsModel } from '../../models/candidatesIds.model';
import { BaseApiService } from './BaseApiService';
import { environment } from '../../../../environments/environment';
import { CandidateModel } from '../../models/candidate.model';
import { IUpdateCandidate } from '../../interfaces/updateCandidate.interface';
import { TechfaceConfigService } from '../../../techface.config';

export interface ICandidateResponse {
  CandidateObject: ICandidate;
}

export interface ICandidatesResponse {
  candidates: ICandidate[];
}
@Injectable({
  providedIn: 'root',
})
export class CandidateService extends BaseApiService {
  private server = environment.modeso.apiGateway;
  private microserviceName = 'candidate';
  private candidates = 'candidates';

  private readonly route = '/';

  constructor(private http: HttpClient) {
    super();
  }

  public getCandidateById(candidateId: string): Observable<ICandidateResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${candidateId}`;
    return this.http.get<ICandidateResponse>(url);
  }

  public getCandidatesByIds(
    candidateIds: CandidatesIdsModel
  ): Observable<ICandidatesResponse> {
    const url = `${this.server}${this.candidates}`;
    return this.http.post<ICandidatesResponse>(url, candidateIds);
  }

  public addCandidate(data: CandidateModel): Observable<ICandidateResponse> {
    const url = `${this.server}${this.microserviceName}`;
    return this.http.post<ICandidateResponse>(url, data);
  }

  public updateCandidate(
    body: IUpdateCandidate
  ): Observable<ICandidateResponse> {
    const url = `${this.server}${this.microserviceName}${this.route}${body.id}`;
    return this.http.put<ICandidateResponse>(url, body.candidateObj);
  }

  public getInvalidDocumentsCandidateById(candidateId: string): Observable<boolean> {
    const url = `${this.server}${this.microserviceName}${this.route}invalid-documents${this.route}${candidateId}`;
    return this.http.get<boolean>(url);
  }
}
