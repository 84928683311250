<div class="content">
  <h1>Confirm your e-mail address</h1>
  <p>
    Thank you for your registration. Please confirm your e-mail <span>{{ candidateData.email }}</span> now.
  </p>
</div>
<div class="content">
  <div class="error-msg">{{ userErrorMsg }}</div>

  <button
    class="btn btn-primary"
    (click)="onClickGoToLogin()"
  >
    Go to login
  </button>
</div>
