import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { CandidateAndCompanyStatusModel } from '../../../shared/models/candidateIdAndStatus.model';
import { CompanyStatus } from '../../../shared/enums/companyStatus.enum';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-candidate-summary',
  templateUrl: './candidate-summary.component.html',
  styleUrls: ['./candidate-summary.component.scss']
})
export class CandidateSummaryComponent implements OnChanges {
  publicBaseUrl = environment.modeso.publicAssetsBaseurl;

  @Input() public candidateAndCompanyStatus: CandidateAndCompanyStatusModel;
  @Input() public queryId: string;

  constructor(private navigationService: NavigationService) {
  }

  ngOnChanges(): void {
  }

  /**
   * return true if company status or candidate status is confirmed
   */
  canSeeCandidate(): boolean {
    return this.candidateAndCompanyStatus.candidateStatus === CompanyStatus.confirmed && this.candidateAndCompanyStatus.companyStatus === CompanyStatus.confirmed;
  }

  openCandidate() {
    this.navigationService.navigateByUrl(`queries/${this.queryId}/candidate/${this.candidateAndCompanyStatus?.candidate.id}`)
  }

}
