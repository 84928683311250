import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ICandidate } from '../../../shared/interfaces/candidate.interface';
import { IUpdateUser } from '../../../shared/interfaces/updateUser.interface';
import { CandidateModel } from '../../../shared/models/candidate.model';
import { CandidateUserModel } from '../../../shared/models/candidateUser.model ';
import { UserModel } from '../../../shared/models/user.model';
import { UserResponseModel } from '../../../shared/models/userResponse.model';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
@Component({
  selector: 'app-candidate-sign-in-password',
  templateUrl: './candidate-sign-in-password.component.html',
  styleUrls: ['./candidate-sign-in-password.component.scss'],
})
export class CandidateSignInPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  formDisabled = false;
  @Input() candidateData: CandidateUserModel;
  @Output() displayEmitter = new EventEmitter();
  private subscriptions = [];
  userErrorMsg: string;
  private UserExist = new BehaviorSubject(undefined);
  isUserExist = this.UserExist.asObservable();
  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider
  ) {}

  ngOnInit(): void {
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{12,}/
            ),
          ],
        ],
        repeatPassword: [
          '',
          [
            Validators.required,
          ],
        ],
      },
      { validator: this.confirmedValidator('password', 'repeatPassword') }
    );
  }

  confirmedValidator(controlName: string, matchingControlName: string): any {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors.confirmedValidator
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  setCandidateUser(): CandidateUserModel {
    return {
      email: this.candidateData.email,
      username:
        this.candidateData.firstName + ' ' + this.candidateData.lastName,
      password: this.passwordForm.get('password').value,
      candidateId: this.candidateData.candidateId || this.candidateData._id,
    };
  }

  setCandidateData(): CandidateModel {
    return {
      firstName: this.candidateData.firstName,
      lastName: this.candidateData.lastName,
    };
  }

  formButtonDisabled(): boolean {
    return !this.passwordForm.valid || this.formDisabled;
  }

  submit(form: FormGroup): void {
    this.formDisabled = true
    let candidateUser: CandidateUserModel = this.setCandidateUser();
    let candidateData;
    let userResponse: UserResponseModel;
    let userObj: IUpdateUser;
    let candidate: ICandidate;
    this.subscriptions.push(
      this.techfaceProvider
        .addUser(candidateUser)
        .pipe(
          map((response) => {
            if (response) {
              this.UserExist.next(false);
              candidateData = this.setCandidateData();
              userResponse = response;
              return { userResponse };
            }
          }),
          mergeMap((userResponse) =>
            userResponse
              ? this.techfaceProvider.addCandidate(candidateData)
              : EMPTY
          ),
          map((candidateResponse) => {
            if (candidateResponse) {
              candidate = candidateResponse;
              candidateUser = {
                email: candidateUser.email,
                candidateId: candidateResponse._id,
              };
              userObj = {
                id: userResponse._id,
                userObj: candidateUser,
              };
              return;
            }
          }),
          mergeMap(() =>
            candidate ? this.techfaceProvider.updateUserById(userObj) : EMPTY
          ),
          map(() => {
            const emitValue = {
              display: false,
              password: form.value.password,
            };

            this.displayEmitter.emit(emitValue);
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.techfaceProvider.addUserError().subscribe((error) => {
        if (error) {
          switch (error.status) {
            case 400: {
              this.UserExist.next(true);
              this.userErrorMsg = error.error.message;
            }
          }
        }
      })
    );
    this.isUserExist.subscribe((isExist) => {
      if (isExist) {
        setTimeout(() => {
          location.reload();
        }, 4000);
      }
    });
  }
}
