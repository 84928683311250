<div
  class="single-chat-container"
  [ngClass]="{ 'sender-container': msg.isSender === true }"
>
  <img
    *ngIf="!msg.isSender"
    class="receive"
    src="assets/icons/user.svg"
    alt="sender-icon"
  />
  <div [ngClass]="{ sender: msg.isSender === true }" class="card">
    <div id="text" class="text" [innerHTML]="msg.msgText"></div>
    <div class="date">{{ msg.timestamp | date: "dd.MM.YYYY, h:mm a" }}</div>
  </div>
  <img
    *ngIf="msg.isSender"
    class="send"
    src="assets/icons/user.svg"
    alt="receiver-icon"
  />
</div>
