<div *ngIf="(items | filter: searchText).length > 0"  class="new-category-container">
  <div (click)="onClickDisplay()" class="title-close">
    <div class="category-name">{{ category.categoryName }}</div>
    <img
      [ngClass]="{ hide: isDisplay }"
      src="assets/icons/minz.svg"
      alt="close-icon"
    />
  </div>
  <div *ngIf="isDisplay">
      <div
        class="item"
        *ngFor="let item of items | filter: searchText; let i = index"
      >
        <label class="skill-expertise-item"
          >{{ item.name }}
          <input
            type="checkbox"
            [ngModel]="item.selected"
            (ngModel)="(item[i])"
            (change)="onSelect(item)"
          />
          <span class="checkmark"></span>
        </label>
      </div>
  </div>
</div>
