import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import Debug from 'debug';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError, of } from 'rxjs';
import {
  catchError,
  retryWhen,
  concatMap,
  delay,
  take,
} from 'rxjs/operators';
import { RedirectionHandler, AuthProvider } from '@modeso/modeso-lib-auth-fe';

const debug = Debug('techface:session:AuthHandler');
@Injectable()
export class AuthHandler implements HttpInterceptor {
  refreshRequestIsRunning: boolean = false;

  constructor(
    private router: Router,
    private authProvider: AuthProvider
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let modifiedReq;

    if (!request.url.includes('storage.googleapis.com')) {
      modifiedReq = request.clone({
        headers: request.headers.set('content-language', 'en-us'),
        withCredentials: true,
      });
    } else {
      modifiedReq = request.clone({});
    }
    return next.handle(modifiedReq).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 440) {
            // Session Invalid
            this.router.navigateByUrl(
              `/${localStorage.getItem('currentLanguage')}/login`
            );
          }
        }
        return throwError(err);
      }),
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error) => {
            if (error.status === 401) {
              this.authProvider.getNewUserAccsessToken(
                localStorage.getItem('userId')
              );
              return of(error.status);
            }
            return throwError(error);
          }),
          take(2),
          delay(1000)
        );
      })
    );
  }
}
