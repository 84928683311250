import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';

import { FormsModule } from '@angular/forms';
import { TechfaceProvider } from '../../providers/techface.provider';
import { CompaniesService } from '../../services/services/companies.service';
import { HttpClientModule } from '@angular/common/http';
import { InjectedTextFeautureModule, InjectedTextPipe } from '@modeso/modeso-lib-core-fe';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { OrderByPipe } from '../../pipes/order-by.pipe'

@NgModule({
  declarations: [OrderByPipe],
  imports: [
    CommonModule,
    MatMenuModule,
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    FormsModule,
    HttpClientModule,
    InjectedTextFeautureModule,
    ReactiveFormsModule,
    MatProgressBarModule
  ],
  exports: [
    MatMenuModule,
    NgSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    FormsModule,
    HttpClientModule,
    InjectedTextFeautureModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    OrderByPipe
  ],
  providers: [TechfaceProvider, CompaniesService, InjectedTextPipe]
})
export class SharedModule {
}
