<div class="query-overview-container">
  <div class="coloum basic-information-container">
    <div class="title">BASIC INFORMATION</div>
    <div class="basic-information-wrapper">
      <div class="coloum">
        <div>
          <label>Position</label>
          <div class="info">{{query?.jobTitle}}
          </div>
        </div>
        <div>
          <label>Work pensum</label>
          <div class="info">{{query?.availabilityNeeded}}
          </div>
        </div>
      </div>
      <div class="coloum">
        <div>
          <label>Languages</label>
          <div class="info">
            <span *ngFor="let language of query?.languages; let isLast=last">{{language}}{{isLast ? '' : ', '}}
            </span>
          </div>
        </div>
        <div>
          <label>Application Deadline</label>
          <div class="info">{{query?.applicationDeadline| date:'dd.MM.YYYY'}}
          </div>
        </div>
      </div>
    </div>
    <div class="edit-description">
      <img src="../../../assets/icons/pencil.png" />
      <a (click)="editQuery()">edit query</a>
      <img src="../../../assets/icons/file-alt.png" />
      <a (click)="showJobDescription()">show job description</a>
    </div>
  </div>
  <div class="coloum skills-expertises-container">
    <div class="title">SKILLS</div>
    <div>
      <div *ngFor="let skill of query?.skills" class="skill">{{skill}}</div>
    </div>
    <div class="expertise-container">
      <div class="title">EXPERTISE</div>
      <div>
        <div *ngFor="let expertise of query?.expertises" class="expertise">{{expertise }}</div>
      </div>
    </div>
  </div>
</div>
