<div class="query-container">
  <div class="query-title">
    {{queryOverviewComponent.query?.jobTitle}}
  </div>
  <div class="query-overview-matches-container">
    <div class="query-overview-container card">
      <h2 class="card-title">Query Overview</h2>
      <app-query-overview #queryOverviewComponent></app-query-overview>
    </div>
    <div class="matches-container">
      <div>
        <div class="matches-count">{{candidateOpenMatchIds.length}}</div>
        <div class="matches-description">matches</div>
      </div>
    </div>
  </div>
  <div class="candidate-matches-container" >
    <div class="title">Open Matches</div>
    <div class="candidate">
      <div *ngIf="candidateOpenMatchIds.length === 0">No matches found.</div>
      <div class="candidate-container" *ngFor="let candidate of candidateOpenMatchIds;">
        <app-candidate-summary [queryId]="queryId" [candidateAndCompanyStatus]="candidate"></app-candidate-summary>
      </div>
    </div>
  </div>

  <div class="candidate-matches-container" *ngIf="candidateDiscardMatchIds.length > 0" >
    <div class="title">Discarded Matches</div>
    <div class="candidate">
      <div class="candidate-container" *ngFor="let candidate of candidateDiscardMatchIds;">
        <app-candidate-summary [queryId]="queryId" [candidateAndCompanyStatus]="candidate"></app-candidate-summary>
      </div>
    </div>
  </div>
</div>
