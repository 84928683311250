<div class="conversation-container">
  <div class="msg">
    <div class="card">
      <div class="title">
        <div>Active Messages</div>
        <div
          [ngClass]="{ open: isDisplayActiveMsgs === false }"
          (click)="onClickDisplay(activeType)"
        >
          <img src="assets/icons/arrow.svg" alt="icon" />
        </div>
      </div>
      <div *ngIf="isDisplayActiveMsgs">
        <button *ngFor="let a of getActiveMatches()" (click)="onClickReceiver(a.matchId, activeType)"
          style="border: 0; background:white; outline: 0; width: 100%;">
          <app-messages [receiverData]="a"></app-messages>
        </button>
      </div>
    </div>

    <div class="archived card">
      <div class="title">
        <div>Archived Messages</div>
        <div
          [ngClass]="{ open: isDisplayArchivedMsgs === false }"
          (click)="onClickDisplay(archivedType)"
        >
          <img src="assets/icons/arrow.svg" alt="icon" />
        </div>
      </div>
      <div *ngIf="isDisplayArchivedMsgs">
        <button *ngFor="let a of getArchivedMatches()" (click)="onClickReceiver(a.matchId, archivedType)"
        style="border: 0; background:white; outline: 0; width: 100%;">
          <app-messages [receiverData]="a"></app-messages>
        </button>
      </div>
    </div>
  </div>
  <div class="chat">
    <div class="card">
      <img
        *ngIf="displayBackArrow"
        class="back"
        src="assets/icons/arrow.svg"
        alt="icon"
        (click)="onClickBack()"
      />
      <app-chatting-area
        id="chat"
        (getStatusEmit)="changeStatus($event)"
        (getDataEmit)="addMessage($event)"
        [data]="selectedReceiver"
      ></app-chatting-area>
    </div>
  </div>
</div>
