import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { IMatch } from '../../shared/interfaces/match.interface';
import { QueryOverviewComponent } from '../../components/query/query-overview/query-overview.component';
import { CandidateAndCompanyStatusModel } from '../../shared/models/candidateIdAndStatus.model';
import { ActivatedRoute } from '@angular/router';
import { CompanyStatus } from '../../shared/enums/companyStatus.enum';
import { CandidatesIdsModel } from '../../shared/models/candidatesIds.model';
import { CandidateModel } from '../../shared/models/candidate.model';
import { filter, takeUntil } from 'rxjs/operators';
import { MetaDataModel } from '../../shared/models/metaData.model';
import { MetaDataIdsModel } from '../../shared/models/metaDataIds.model';
import { ICandidate } from '../../shared/interfaces/candidate.interface';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { Subject } from 'rxjs';
import { SkillExpertiseByCategoriesModel } from '../../shared/models/skillExpertiseByCategories.model';

@Component({
  selector: 'app-query-page',
  templateUrl: './query-page.component.html',
  styleUrls: ['./query-page.component.scss'],
})
export class QueryPageComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();
  queryId: string;
  matchesCount: number;
  candidateDiscardMatchIds: CandidateAndCompanyStatusModel[] = [];
  candidateOpenMatchIds: CandidateAndCompanyStatusModel[] = [];
  candidates: CandidateModel[] = [];
  matches: IMatch[] = [];
  private subscriptions:any = [];
  @ViewChild('queryOverviewComponent', { static: false })
  queryOverviewComponent: QueryOverviewComponent;

  constructor(
    private techfaceProvider: TechfaceProvider,
    private route: ActivatedRoute
  ) {}

  @HostListener('unloaded')
  ngOnInit(): void {
    this.getMatchesCount();
  }
  ngOnDestroy() {
    this.onDestroy.next();
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }

  /**
   * get matches count and call function set candidate status
   */
  getMatchesCount(): void {
    this.subscriptions.push(
      this.route.params.pipe(takeUntil(this.onDestroy)).subscribe((params) => {
      this.queryId = params.queryId;

      this.subscriptions.push(this.techfaceProvider
        .getMatchesByQueryId(this.queryId)
        .subscribe((matches) => {
          if (matches) {
            this.matches = matches;
            this.matchesCount = matches.length;
            this.getCandidates(matches);
          }
        }));
    }));
  }
  /**
   * set candidateMatch depend on candidate status
   * @param matches
   */
  setCandidateStatus(
    candidates: CandidateModel[],
    metadata: SkillExpertiseByCategoriesModel
  ): void {
    this.candidateDiscardMatchIds = [];
    this.candidateOpenMatchIds = [];

    this.matches.forEach((match) => {
      const candidate: CandidateModel = candidates.find(
        (candidate) => candidate.id === match.candidateId
      );
      if (candidate) {
        const skills: string[] = [];
        const expertises: string[] = [];
        candidate.skills.forEach((candidateSkill) => {
          metadata.skillByCategory.forEach((category) => {
            category.skillsExpertises.forEach((skill) => {
              if (candidateSkill.skillId === skill._id) {
                skills.push(skill.name);
              }
            });
          });
        });

        candidate.expertises.forEach((candidateExpertise) => {
          metadata.expertiseByCategory.forEach((category) => {
            category.skillsExpertises.forEach((expertise) => {
              if (candidateExpertise.expertiseId === expertise._id) {
                expertises.push(expertise.name);
              }
            });
          });
        });
        if (match.companyStatus === CompanyStatus.rejected || match.candidateStatus === CompanyStatus.rejected) {          
          this.setCandidateMatch(
            this.candidateDiscardMatchIds,
            match,
            candidate,
            skills,
            expertises
          );
        } else {
          this.setCandidateMatch(
            this.candidateOpenMatchIds,
            match,
            candidate,
            skills,
            expertises
          );
        }
      }
    });
  }
  /**
   * get all candidates ids
   * @param matches
   */
  getCandidateIds(matches: IMatch[]): string[] {
    return matches.map((match) => match.candidateId);
  }

  /**
   * get all candidates for this query from backend
   * @param matches
   */
  getCandidates(matches: IMatch[]): void {
    const candidatesIdsObj: CandidatesIdsModel = {
      ids: this.getCandidateIds(matches),
    };
    this.subscriptions.push(this.techfaceProvider
      .getCandidatesByIds(candidatesIdsObj)
      .pipe(takeUntil(this.onDestroy))
      .pipe(filter((candidates) => candidates != null && candidates.length > 0))
      .subscribe(this.bindCandidatesData.bind(this)));
  }
  /**
   * bind candidates data
   * @param candidates
   */
  bindCandidatesData(candidates: CandidateModel[]): void {
    const candidatesDetails: CandidateModel[] = [];

    candidates.forEach((candidate) => {
      candidatesDetails.push({
        id: candidate._id,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        email: candidate.email,
        title: candidate.title,
        image: candidate.image,
        imageDescription: candidate.imageDescription,
        preferredLocation: candidate.preferredLocation,
        availableFrom: candidate.availableFrom,
        availabilty: candidate.availabilty,
        languages: candidate.languages,
        skills: candidate.skills,
        expertises: candidate.expertises,
        certificates: candidate.certificates,
        cv: candidate.cv,
        documents: candidate.documents,
      });
    });
    this.getMetadata(candidatesDetails);
  }
  /**
   * gel all metadata name by call getMeataDataByIds endpoint using ngrx
   * @param candidates
   */
  getMetadata(candidates: CandidateModel[]): void {
    this.subscriptions.push(this.techfaceProvider
      .getAllSkillExprtiseByCategory()
      .subscribe((skillExpertise) => {
        if (skillExpertise) {
          this.setCandidateStatus(candidates, skillExpertise);
        }
      }));
  }

  /**
   * push to candidate match
   * @param candidateMatch
   * @param match
   */
  setCandidateMatch(
    candidateMatch: CandidateAndCompanyStatusModel[],
    match: IMatch,
    candidate: CandidateModel,
    skillsName: string[],
    expertisesName: string[]
  ): void {
    candidateMatch.push({
      candidate,
      companyStatus: match.companyStatus,
      candidateStatus: match.candidateStatus,
      skillsName,
      expertisesName,
    });
  }
}
