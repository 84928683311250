import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import Debug from 'debug';
import { of } from 'rxjs';
import { catchError, map, mergeMap, retry } from 'rxjs/operators';
import * as fromActions from '../actions/techface.action';
import { IQuery } from '../interfaces/query.interface';
import { IUpdateQuery } from '../interfaces/updateQuery.interface';
import { MetaDataIdsModel } from '../models/metaDataIds.model';
import { CompaniesService } from '../services/services/companies.service';
import { MetaDataService } from '../services/services/meta-data.service';
import { QueriesService } from '../services/services/queries.service';
import { MatchesService } from '../services/services/matches.service';
import { CandidateService } from '../services/services/candidate.service';
import { UpdateCompanyModel } from '../models/updateCompany.model ';
import { QueryCandidateIdsModel } from '../models/queryCandidateIds.model';
import { CandidatesIdsModel } from '../models/candidatesIds.model';
import { UserService } from '../services/services/user.service';
import { UserModel } from '../models/user.model';
import { IUpdateUser } from '../interfaces/updateUser.interface';
import { CandidateModel } from '../models/candidate.model';
import { IUpdateCandidate } from '../interfaces/updateCandidate.interface';
import { UploadService } from '../services/services/upload.service';

const debug = Debug('modeso:techface-project-application:TechfaceEffects');

@Injectable()
export class TechfaceEffects {
  constructor(
    private actions$: Actions,
    private companiesService: CompaniesService,
    private queriesService: QueriesService,
    private matchesService: MatchesService,
    private candidateService: CandidateService,
    private metaDataService: MetaDataService,
    private userService: UserService,
    private uploadService: UploadService
  ) {}

  private getCompanyById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetCompanyById.type),
      mergeMap((action: fromActions.IActionWithPayload<string>) => {
        return this.companiesService.getCompanyById(action.payload).pipe(
          // retry(1),
          map((response) => {
            return fromActions.onGetCompanyByIdSuccess({
              payload: response.CompanyObject,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetCompanyByIdFail({ payload: error }))
          )
        );
      })
    )
  );

  private getQueriesByCompanyId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetQueriesByCompanyId.type),
      mergeMap((action: fromActions.IActionWithPayload<string>) => {
        return this.queriesService.getQueriesByCompanyId(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetQueriesByCompanyIdSuccess({
              payload: response.queries,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetQueriesByCompanyIdFail({ payload: error }))
          )
        );
      })
    )
  );

  private getQueryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetQueryById.type),
      mergeMap((action: fromActions.IActionWithPayload<string>) => {
        return this.queriesService.getQueryById(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetQueryByIdSuccessfully({
              payload: response.query,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetQueryByIdFailed({ payload: error }))
          )
        );
      })
    )
  );

  private CreteNewQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onCreateNewQuery.type),
      mergeMap((action: fromActions.IActionWithPayload<IQuery>) => {
        return this.queriesService.createNewQuery(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onCreateNewQuerySuccessfully({
              payload: response.query,
            });
          }),
          catchError((error) =>
            of(fromActions.onCreateNewQueryFailed({ payload: error }))
          )
        );
      })
    )
  );

  private updatQuery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onUpdateQuery.type),
      mergeMap((action: fromActions.IActionWithPayload<IUpdateQuery>) => {
        return this.queriesService.updateNewQuery(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onUpdateQuerySuccessfully({
              payload: response.query,
            });
          }),
          catchError((error) =>
            of(fromActions.onUpdateQueryFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getMatchesByQueryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMatchesByQueryId.type),
      mergeMap((action: fromActions.IActionWithPayload<string>) => {
        return this.matchesService.getMatchesByQueryId(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetMatchesByQueryIdSuccess({
              payload: response.matches,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetMatchesByQueryIdFail({ payload: error }))
          )
        );
      })
    )
  );

  private getCandidateById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetCandidateById.type),
      mergeMap((action: fromActions.IActionWithPayload<string>) => {
        return this.candidateService.getCandidateById(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetCandidateByIdSuccess({
              payload: response.CandidateObject,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetCandidateByIdFail({ payload: error }))
          )
        );
      })
    )
  );

  private getCandidatesByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetCandidatesByIds.type),
      mergeMap((action: fromActions.IActionWithPayload<CandidatesIdsModel>) => {
        return this.candidateService.getCandidatesByIds(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetCandidatesByIdsSuccess({
              payload: response.candidates,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetCandidatesByIdsFail({ payload: error }))
          )
        );
      })
    )
  );

  private getAllMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMetaData.type),
      mergeMap((payload) => {
        return this.metaDataService.getAllMetaData().pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetMetaDataIsSuccessfully({
              payload: response,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetMetaDataIsFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getAllSkillExpertiseByCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetSkillExpertiseByCatefgories.type),
      mergeMap((payload) => {
        return this.metaDataService.getAllSkillExpertiseByCategories().pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetSkillExpertiseByCatefgoriesSuccessfully({
              payload: response,
            });
          }),
          catchError((error) =>
            of(
              fromActions.onGetSkillExpertiseByCatefgoriesFailed({
                payload: error,
              })
            )
          )
        );
      })
    )
  );

  private getMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMetadata.type),
      mergeMap((payload) => {
        return this.metaDataService.getAllMetaData().pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetMetadataSuccessfully({ payload: response });
          }),
          catchError((error) =>
            of(fromActions.onGetMetadataFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getMetadataByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMetadataByIds.type),
      mergeMap((action: fromActions.IActionWithPayload<MetaDataIdsModel>) => {
        return this.metaDataService.getMetadataByIds(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetMetadataByIdsSuccessfully({
              payload: response,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetQueryMetadataByIdsFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getMetaDataByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMetadataByIds.type),
      mergeMap((action: fromActions.IActionWithPayload<MetaDataIdsModel>) => {
        return this.metaDataService.getMetadataByIds(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetMetadataByIdsSuccessfully({
              payload: response,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetMetadataFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getQueryMetadataByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetQueryMetadataByIds.type),
      mergeMap((action: fromActions.IActionWithPayload<MetaDataIdsModel>) => {
        return this.metaDataService.getMetadataByIds(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onGetQueryMetadataByIdsSuccessfully({
              payload: response,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetQueryMetadataByIdsFailed({ payload: error }))
          )
        );
      })
    )
  );

  private updateCompanyStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onUpdateCompanyStatus.type),
      mergeMap((action: fromActions.IActionWithPayload<UpdateCompanyModel>) => {
        return this.matchesService.updateCompanyStatus(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onUpdateCompanyStatusSuccess({
              payload: response.match,
            });
          }),
          catchError((error) =>
            of(fromActions.onGetQueriesByCompanyIdFail({ payload: error }))
          )
        );
      })
    )
  );

  private getMatchesByQueryAndCandidateId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onGetMatchByQueryAndCandidateIds.type),
      mergeMap(
        (action: fromActions.IActionWithPayload<QueryCandidateIdsModel>) => {
          return this.matchesService
            .getMatchByQueryIdAndCandidateId(action.payload)
            .pipe(
              retry(1),
              map((response) => {
                return fromActions.onGetMatchByQueryAndCandidateIdsSuccess({
                  payload: response.match,
                });
              }),
              catchError((error) =>
                of(
                  fromActions.onGetMatchByQueryAndCandidateIdsFail({
                    payload: error,
                  })
                )
              )
            );
        }
      )
    )
  );
  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.getToken.type),
      mergeMap((action: fromActions.IActionWithPayload<UserModel>) => {
        return this.userService.getToken(action.payload).pipe(
          map((response) => {
            return fromActions.onGetTokenSuccessfully({ payload: response });
          }),
          catchError((error) => {
            return of(fromActions.onGetTokenFailed({ payload: error }));
          })
        );
      })
    )
  );
  // Login
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.signIn.type),
      mergeMap((action: fromActions.IActionWithPayload<UserModel>) => {
        return this.userService.signIn(action.payload).pipe(
          map((response) => {
            return fromActions.onSignInSuccessfully({ payload: response.user });
          }),
          catchError((error) => {
            return of(fromActions.onSignInFailed({ payload: error }));
          })
        );
      })
    )
  );

  private updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onUpdateUser.type),
      mergeMap((action: fromActions.IActionWithPayload<IUpdateUser>) => {
        return this.userService.updateUser(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onUpdateUserSuccessfully({
              payload: response.user,
            });
          }),
          catchError((error) =>
            of(fromActions.onUpdateUserFailed({ payload: error }))
          )
        );
      })
    )
  );
  private addCandidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onAddCandidate.type),
      mergeMap((action: fromActions.IActionWithPayload<CandidateModel>) => {
        return this.candidateService.addCandidate(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onAddCandidateSuccessfully({
              payload: response.CandidateObject,
            });
          }),
          catchError((error) =>
            of(fromActions.onAddCandidateFailed({ payload: error }))
          )
        );
      })
    )
  );

  private updateCandidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onUpdateCandidate.type),
      mergeMap((action: fromActions.IActionWithPayload<IUpdateCandidate>) => {
        return this.candidateService.updateCandidate(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onAddCandidateSuccessfully({
              payload: response.CandidateObject,
            });
          }),
          catchError((error) =>
            of(fromActions.onAddCandidateFailed({ payload: error }))
          )
        );
      })
    )
  );
  private addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.onAddUser.type),
      mergeMap((action: fromActions.IActionWithPayload<CandidateModel>) => {
        return this.userService.addUser(action.payload).pipe(
          retry(1),
          map((response) => {
            return fromActions.onAddUserSuccessfully({
              payload: response.user,
            });
          }),
          catchError((error) =>
            of(fromActions.onAddUserFailed({ payload: error }))
          )
        );
      })
    )
  );

  private getEmailByCandidateId$ = createEffect(() =>
  this.actions$.pipe(
    ofType(fromActions.onGetEmailByCandidateId.type),
    mergeMap((action: fromActions.IActionWithPayload<string>) => {
      return this.userService.getEmailByCandidateId(action.payload).pipe(
        map((response) => {
          return fromActions.onGetEmailByCandidateIdSuccessfully({
            payload: response,
          });
        }),
        catchError((error) =>
          of(fromActions.onGetEmailByCandidateIdFailed({ payload: error }))
        )
      );
    })
  )
);
}
