import { Component, OnInit, Input, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { DurationService } from '../../../shared/services/duration/duration.service';
import { CertificateModel } from '../../../shared/models/certificate.model';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit, AfterViewChecked {
  @Input() certificates: CertificateModel[];

  constructor(private durationService: DurationService, private cdref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked(): void {
    if (this.certificates) {
      this.setDurationOfCertificates();
    }
    this.cdref.detectChanges();
  }

  /**
   * calculate duration from and to dates
   */
  setDurationOfCertificates(): void {
    this.certificates.forEach(certificate => {
      const totalDays = this.durationService.getTotalDays(new Date(certificate.from), new Date(certificate.to));
      certificate.duration = this.durationService.getDateDiff(totalDays);
    });
  }

}
