<div class="login-page">
  <div class="login-image">
  </div>
  <div class="login-wrapper">
    <div class="login-form">
      <div class="login-page-title">
        <h1>Login</h1>
      </div>
      <p><strong>Welcome to techface.careers</strong></p>
      <p>Our aim is to achieve a mindset shift in recruiting for tech talents. 
        Companies should apply to candidates to fill open positions instead of the other way around.
Be found for your skills and expertise and become part of the next generation of the female tech workforce.
<a href="https://techface.ch/project-skills-finder/" target="_blank">Find out more here</a>.</p>
<p>Sign up now to join the movement or register as company to find your next talent in tech!</p>
      <form
        [formGroup]="loginForm"
        #myForm="ngForm"
        (ngSubmit)="submit(loginForm)"
        id="login-form"
      >
        <div class="form-group">
          <label>E-Mail</label>
          <div>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="username_email"
                name="username_email"
                id="username_email"
                placeholder="Please enter"
              />
              <mat-error
                class="error-msg"
                id="email-invalid"
                *ngIf="username_email.errors?.required"
              >
                enter correct email</mat-error
              >
            </mat-form-field>
          </div>
        </div>

        <div class="form-group">
          <label> Password </label>
          <div>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="password"
                placeholder="Please enter"
                name="password"
                id="password"
                formControlName="password"
              />
              <mat-error
                class="error-msg"
                id="password-invalid"
                *ngIf="password.errors?.required"
              >
                enter correct password</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="loginError" class="error-login">
          There was an error logging you in. Please check your email and
          password and try again.
        </div>
        <div class="submit-btn">
          <button class="btn btn-primary" type="submit">Login</button>
          <button class="btn btn-link" (click)="forgotPassword()">Forgot password</button>
        </div>
      </form>
    </div>
    <div class="register-now">
      <div class="register-title">
        <h1>You don't have an account yet?</h1>
      </div>
      <div>
	      <a class="btn btn-secondary" style="margin-right: 1em; margin-bottom: 1em;" href=" mailto:support@techface.ch?subject=New%20Company%20Account%20for%20TechFace%20Careers&body=Thanks%20a%20lot%20for%20signing%20up%20with%20TechFace%20Careers.%0D%0APlease%20provide%20following%20information%20for%20us%20to%20set%20up%20your%20account%3A%0D%0A%0D%0ACompany%20Name%3A%0D%0ACompany%20Website%3A%0D%0AContact%20Name%3A%0D%0AContact%20Email%20%3A%0D%0AContact%20Phone%20Number%3A%0D%0AComment%3A%0D%0A%0D%0AOnce%20we%20receive%20your%20email%2C%20we%20will%20get%20in%20touch%20with%20you%20within%202%20working%20days%20with%20further%20information. ">I'm a company</a>
        <button class="btn btn-secondary" (click)="registerAsCandidate()">I'm a candidate</button>
      </div>
      <div class="register-title">
        <h1>Any questions?</h1>
      </div>
      <div>
	       <a class="btn btn-secondary" href="mailto:support@techface.ch"> Contact us</a>
      </div>
    </div>
    <div class="links">
      <a class="btn btn-link" href="https://www.techface.ch" target="_blank">techface.ch</a>
      <a class="btn btn-link" href="https://www.techface.ch/about-us/" target="_blank">About</a>
      <a class="btn btn-link" href="https://www.techface.ch/privacy-policy/" target="_blank">Privacy Policy</a>
      <a class="btn btn-link" href="https://www.techface.ch/imprint/" target="_blank">Imprint</a>
    </div>
  </div>
</div>
