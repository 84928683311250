<div class="header-side-nav-container">
  <div class="logo">
    <a href="./">
      <img src="../../../assets/icons/TechFace.-Favicon.png" alt="" />
    </a>
  </div>
  <div class="pages">
    <a *ngIf="companyId"
      class="page"
      [ngClass]="{ selected: id === 'dashboard' }"
      (click)="onClick('dashboard')"
      >Dashboard</a
    >
    <a *ngIf="candidateId" class="page" [ngClass]="{ selected: id === 'me' }" (click)="onClick('me')"
    >Profile</a
  >
    <a
      class="page"
      [ngClass]="{ selected: id === 'faq' }"
      (click)="onClick('faq')"
      >FAQ</a
    >
    <a *ngIf="companyId"
      class="page"
      [ngClass]="{ selected: id === 'msg' }"
      (click)="onClick('msg')"
      >Messages</a
    ><a class="dot" *ngIf="unreadMsgs > 0"> {{ unreadMsgs }}</a>


  </div>

  <div class="account">
    <a mat-button href="mailto:support@techface.ch">
      <img src="../../../assets/icons/email.svg" />
    </a>
    <button mat-button [matMenuTriggerFor]="menu">
      <img src="../../../assets/icons/settings.svg" />
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="onClick('logout')">Logout</button>
    </mat-menu>
  </div>
</div>
