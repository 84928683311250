import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CandidateStatus } from '../../../shared/enums/CandidateStatus.enum';
import { CompanyStatus } from '../../../shared/enums/companyStatus.enum';
import { MsgType } from '../../../shared/enums/msgType.enum';
import { ICandidate } from '../../../shared/interfaces/candidate.interface';
import { ICompanyInformation } from '../../../shared/interfaces/comapny.interface';
import { IMatch, IMessage } from '../../../shared/interfaces/match.interface';
import { MatchCandidateIdsModel, MatchCompanyIdsModel } from '../../../shared/models/matchIds.model';
import { MatchTypeModel, MatchActionTypes } from '../../../shared/models/matchType.model';
import { MessagesModel } from '../../../shared/models/messages.model';
import { SelecetedReceiverModel } from '../../../shared/models/selectedReceiver.model';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { CandidateService, ICandidateResponse } from '../../../shared/services/services/candidate.service';
import { CompaniesService, ICompanyResponse } from '../../../shared/services/services/companies.service';
import { MatchesService } from '../../../shared/services/services/matches.service';
import { SharedDataService } from '../../../shared/services/services/shared-data.service';
import { QueriesService } from '../../../shared/services/services/queries.service';
import { IQuery } from '../../../shared/interfaces/query.interface';
import { combineLatest, timer } from 'rxjs';
import { AutoRefreshTimer } from '../../../shared/enums/autoRefreshTimer.enum';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent implements OnInit {
  @Input() candidateId: string;
  @Output() emitCompanyMatch = new EventEmitter();

  isDisplayActiveMsgs = true;
  isDisplayArchivedMsgs = false;

  matches: IMatch[] = [];
  queries: { [matchId: string]: IQuery[] } = {};
  senderData: any;
  recipientsData: { [matchId: string]: any } = {};

  selectedReceiver: SelecetedReceiverModel | null=null;;
  userCandidateOrCompanyId: string;

  activeType = MsgType.active;
  archivedType = MsgType.archived;
  displayBackArrow = false;
  unreadMsgForAllMyMsgs = 0;
  source = timer(AutoRefreshTimer.dueTime, AutoRefreshTimer.periodOrScheduler);
  constructor(
    private matchService: MatchesService,
    private techfaceProvider: TechfaceProvider,
    private companiesService: CompaniesService,
    private candidateService: CandidateService,
    private sharedDataService: SharedDataService,
    private queryService: QueriesService,
  ) { }

  ngOnInit(): void {
    this.setUserId()
    /*this.source.subscribe(() => {
      this.getMatches();
    })*/
  }

  getMatches() {
    if (Object.keys(this.recipientsData).length > 0) return;
    if (this.isCandidate()) {
      this.getCandidateMatches()
    } else {
      this.getCompanyMatches();
    }
  }

  addMessage({matchId, msgData}) {
    const match = this.matches.find(m => m._id === matchId)
    if (!match) return;
    match.messages?.push(msgData.message)
    match.messages = match.messages?.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    if (this.selectedReceiver?.receiverData?.matchId === matchId) {
      this.selectedReceiver.conversationData = [...match.messages || []]
    }
    //this.matches = this.matches
    this.setMsgUnseen(matchId)
  }

  isCandidate(): boolean {
    return localStorage.getItem('candidateId') ? true : false;
  }

  setUserId(): void {
    this.userCandidateOrCompanyId = this.isCandidate()
      ? localStorage.getItem('candidateId')
      : localStorage.getItem('companyId');
  }

 
  getLastMsg(messages: IMessage[]): IMessage {
    return (messages as any).reduce((a, b) => {
      return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
    }, 0);
  }

  getActiveMatches() {
    if (!this.matches || !(this.queries && Object.keys(this.queries).length > 0) || !(this.recipientsData && Object.keys(this.recipientsData).length > 0) || !this.senderData) return []
    return this.matches
      .filter(m => m.companyStatus !== CompanyStatus.rejected && m.candidateStatus !== CandidateStatus.rejected)
      .filter(m => this.queries[m._id!].length > 0)
      .map(m => this.mapToMsgObject(m))
      .sort((a, b) => new Date(b.lastMsgTime).getTime() - new Date(a.lastMsgTime).getTime())
  }

  getArchivedMatches() {
    if (!this.matches || !(this.queries && Object.keys(this.queries).length > 0) || !(this.recipientsData && Object.keys(this.recipientsData).length > 0) || !this.senderData) return []
    return this.matches
      .filter(m => m.companyStatus === CompanyStatus.rejected || m.candidateStatus === CandidateStatus.rejected)
      .filter(m => this.queries[m._id!].length > 0)
      .map(m => this.mapToMsgObject(m))
      .sort((a, b) => new Date(b.lastMsgTime).getTime() - new Date(a.lastMsgTime).getTime())
  }
  
  mapToMsgObject(match: IMatch) {
    const matchId = match._id!
    const lastMsg = match.messages?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())[0];
    return {
      matchId,
      name: this.getCandidateOrCompanyName(match) + ' (' + this.queries[matchId][0].name + ')',
      subName: lastMsg ? (lastMsg.msgText.length > 100 ? lastMsg.msgText.substring(0, 100) + "..." : lastMsg.msgText).replace(/<[^>]+>/g, '') : 'No messages yet.',
      lastMsgTime: lastMsg?.timestamp || this.objectIdToDate(matchId),
      unreadMsgs: this.isCandidate() ? this.getNumberUnseenCandidateMsgs(match.messages) : this.getNumberUnseenCompanyMsgs(match.messages),
    };
  }

  getCandidateOrCompanyName(match: IMatch) {
    const companyOrCandidate = this.isCandidate() ? this.recipientsData[match.companyId] : this.recipientsData[match.candidateId]
    if (!companyOrCandidate) {
      return `Deleted ${this.isCandidate() ? 'Company' : 'Candidate'}`; 
    }
    const anonymous = match.companyStatus === CompanyStatus.rejected || match.candidateStatus !== CandidateStatus.confirmed
    return companyOrCandidate.fullCompanyName ? 
      companyOrCandidate.fullCompanyName : (anonymous ? 'Candidate' : (companyOrCandidate.firstName + ' ' + companyOrCandidate.lastName))
  }

  getNumberUnseenCandidateMsgs(messages?: IMessage[]): number {
    return messages?.filter((msg) => msg.isCandidateSeen === false).length || 0;
  }

  getNumberUnseenCompanyMsgs(messages?: IMessage[]): number {
    return messages?.filter((msg) => msg.isCompanySeen === false).length || 0;
  }


  getCandidateMatches(): void {
    this.matchService
      .getMatchByCandidateId(this.userCandidateOrCompanyId)
    .pipe(
      switchMap(response => {
        this.matches = response.matches;
        return this.matches
      }),
      switchMap(_ => this.getQueriesData()),
      switchMap(_ => this.getSenderData()),
      switchMap(_ => this.getRecipientsData())
    ).subscribe(() => {
    })
  }

  getSenderData() {
    if (this.isCandidate()) {
      return this.candidateService.getCandidateById(this.userCandidateOrCompanyId).toPromise()
      .then(c => { this.senderData = c.CandidateObject })
    } else {
      return this.companiesService.getCompanyById(this.userCandidateOrCompanyId).toPromise()
      .then(c => { this.senderData = c.CompanyObject })
    }
  }

  getCompanyMatches(): void {
    this.matchService
      .getMatchByComapnyId(this.userCandidateOrCompanyId)
    .pipe(
      switchMap(response => {
        this.matches = response.matches;
        return this.matches
      }),
      switchMap(_ => this.getQueriesData()),
      switchMap(_ => this.getSenderData()),
      switchMap(_ => this.getRecipientsData()),
    ).subscribe(() => {
    })
  }
  private x = 0;

  getQueriesData(): Promise<void> {
    if (this.x > 0) return Promise.resolve()
    this.x++
    const queryIds = [...new Set(this.matches.reduce((acc, m) => {
      acc.push(...m.matchedQueries)
      return acc;
    }, [] as string[]))]
    return (Promise as any).allSettled(queryIds.map(qId => this.queryService.getQueryById(qId).toPromise()))
      .then(queryPromises => {
        const queries = queryPromises
          .filter(q => q.status === "fulfilled")
          .map(q => q.value)
    
        this.matches.forEach(m => {
          this.queries[m._id] = queries.filter(q => m.matchedQueries.includes(q.query._id)).map(q => q.query)
        })
      })
  }

  getRecipientsData(): Promise<void> {
    return this.isCandidate() ? this.getRecipientsDataCompany() : this.getRecipientsDataCandidate()
  }

  getRecipientsDataCandidate(): Promise<void> {
    const ids = [...new Set(this.matches.map(m => m.candidateId))]
    return this.candidateService.getCandidatesByIds({ids}).toPromise()
      .then(candidates => {
        this.recipientsData = candidates.candidates.reduce((acc, c) => {
          acc[c._id!] = c;
          return acc;
        }, {})
      })
  }

  
  getRecipientsDataCompany(): Promise<void> {
    const ids = [...new Set(this.matches.map(m => m.companyId))]
    return Promise.all(ids.map(qId => this.companiesService.getCompanyById(qId).toPromise()))
      .then(companies => {
        this.recipientsData = companies.reduce((acc, c) => {
          acc[c.CompanyObject._id!] = c.CompanyObject;
          return acc;
        }, {})
      })
  }

  onClickDisplay(type: string): void {
    switch (type) {
      case this.activeType:
        this.isDisplayActiveMsgs = !this.isDisplayActiveMsgs;
        break;
      case this.archivedType:
        this.isDisplayArchivedMsgs = !this.isDisplayArchivedMsgs;
        break;
    }
  }

  setMsgUnseen(matchId): void {
    const match = this.matches.filter((match) => match._id === matchId)[0];
    let unreadMsg;
    if (this.isCandidate()) {
      unreadMsg = match.messages.filter(
        (msg) => msg.isCandidateSeen === false
      ).length;
      match?.messages.map((msg) => (msg.isCandidateSeen = true));
      if (unreadMsg > 0) {
        this.sharedDataService.currentUnreadTotalCandidateMsgsCount.pipe(take(1)).subscribe(
          (s) => {
            this.unreadMsgForAllMyMsgs = s;
            unreadMsg = 1;
            this.sharedDataService.changeUnreadTotalCandidateMsgsCount(s-unreadMsg);
          }
        );
      }
    } else {
      unreadMsg = match.messages.filter(
        (msg) => msg.isCompanySeen === false
      ).length;
      match?.messages.map((msg) => (msg.isCompanySeen = true));
    }
    this.unreadMsgForAllMyMsgs = this.unreadMsgForAllMyMsgs - unreadMsg;
      let totalCompanyMsgs;
      this.sharedDataService.currentUnreadTotalCompanyMsgsCount.subscribe(
        (totalMsg) => {
          totalCompanyMsgs = totalMsg - unreadMsg;
        }
      );
      this.sharedDataService.changeUnreadTotalCompanyMsgsCount(totalCompanyMsgs);
    this.sharedDataService.changeUnreadMsgsCount(this.unreadMsgForAllMyMsgs);

    this.matchService.updateMatch(matchId, match).subscribe(() => {
      this.getMatches()
    });
  }

  isCandidateCanSend(id: string): boolean {
    return (
      this.matches.filter((match) => match.companyId === id)[0]
        ?.candidateStatus === CandidateStatus.confirmed &&
      this.matches.filter((match) => match.companyId === id)[0]
        ?.companyStatus === CompanyStatus.confirmed
    );
  }

  isCompanyCanSend(id: string): boolean {
    return (
      this.matches.filter((match) => match.candidateId === id)[0]
        ?.candidateStatus === CandidateStatus.confirmed &&
      this.matches.filter((match) => match.candidateId === id)[0]
        ?.companyStatus === CompanyStatus.confirmed
    );
  }

  isCandidateConfirm(id: string): boolean {
    return (
      this.matches.filter((match) => match.companyId === id)[0]
        ?.candidateStatus === CandidateStatus.confirmed
    );
  }

  isCompanyConfirm(id: string): boolean {
    return (
      this.matches.filter((match) => match.candidateId === id)[0]
        ?.companyStatus === CompanyStatus.confirmed
    );
  }

  
  onClickReceiver(matchId, type: string): void {
    document.getElementById('chat').style.display = 'inline';
    this.displayBackArrow = true;
    this.setMsgUnseen(matchId);
    const match = this.matches.find(m => m._id === matchId)!
    this.selectedReceiver = {
      conversationData: [...match.messages!.reverse()],
      receiverData: {
        matchId, 
        name: this.getCandidateOrCompanyName(match),
        subName: this.queries[matchId][0].name,
        canSend: match.candidateStatus === CandidateStatus.confirmed && match.companyStatus === CompanyStatus.confirmed,
        isConfirm: this.isCandidate() ? match.candidateStatus === CandidateStatus.confirmed: match.companyStatus === CompanyStatus.confirmed,
        canConfirm: this.isCandidate() ? match.candidateStatus !== CandidateStatus.confirmed : match.companyStatus !== CompanyStatus.confirmed,
        canReject: this.isCandidate() ? match.candidateStatus !== CandidateStatus.rejected : match.companyStatus !== CompanyStatus.rejected,
        id: match?.companyId,
        msgType: type,
        img: "",
        location: (this.isCandidate() ? this.recipientsData[match.companyId]?.address?.country : this.recipientsData[match.candidateId]?.country) || 'Unknown location'
      }
    }
  }



  setCandidateStatus(data: MatchTypeModel, match: IMatch): void {
    if (data.type === MatchActionTypes.ACCEPT) {
      match.candidateStatus = CandidateStatus.confirmed;
    } else {
      match.candidateStatus = CandidateStatus.rejected;
    }
  }

  setCompanyStatus(data: MatchTypeModel, match: IMatch): void {
    if (data.type === MatchActionTypes.ACCEPT) {
      match.companyStatus = CompanyStatus.confirmed;
      this.emitCompanyMatch.emit(CompanyStatus.confirmed)
    } else {
      match.companyStatus = CompanyStatus.rejected;
      this.emitCompanyMatch.emit(CompanyStatus.rejected)

    }
  }

  updateMatch(data: MatchTypeModel, match: IMatch): void {
    this.matchService.updateMatch(data.id, match).subscribe((response) => {
      console.log("match status updated")
      this.hanldeMatchActions(data);
    });
  }
  hanldeMatchActions(data:MatchTypeModel){
    if(!this.selectedReceiver ||  !this.isCandidate()) return;
    switch(data.type){
      case MatchActionTypes.ACCEPT:
        this.selectedReceiver.receiverData.canConfirm=false;
        this.selectedReceiver.receiverData.isConfirm=true;
        this.selectedReceiver.receiverData.canSend=true;
        break;
      case MatchActionTypes.DISCARD:
        this.onClickBack();
        break;
    }
  }
  changeStatus(data: MatchTypeModel): void {
    const match = this.matches.filter(
      (match: IMatch) => match._id === data.id
    )[0];
    if (this.isCandidate()) {
      this.setCandidateStatus(data, match);
      this.updateMatch(data, match);
    } else {
      this.setCompanyStatus(data, match);
    }
  }

  onClickBack(): void {
    this.displayBackArrow = false;
    document.getElementById('chat').style.display = 'none';
  }

  objectIdToDate(objectId) {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  }
}
