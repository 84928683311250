import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DocumentModel } from '../../../shared/models/document.model';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnChanges {

  @Input() documents: DocumentModel[];
  constructor() {
  }

  ngOnChanges(): void {
  }

}
