import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { SkillExpertiseByCategoriesModel } from '../../../../shared/models/skillExpertiseByCategories.model';
import { SkillExpertiseByCategoryModel } from '../../../../shared/models/skillExpertiseByCategory.model';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CandidateSkillModel } from '../../../../shared/models/candiddateSkill.model';
import { CandidateExpertiseModel } from '../../../../shared/models/candiddateExpertise.model';
import { SkillExpertiseStatus } from '../../../../shared/enums/skillExpertiseStatus.enum';
import { SkillsExpertisesModel } from '../../../../shared/models/skillsExpertise.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-skill-expertise',
  templateUrl: './skill-expertise.component.html',
  styleUrls: ['./skill-expertise.component.scss'],
})
export class SkillExpertiseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() allSkillsExpertiseByCategories: SkillExpertiseByCategoriesModel;
  @Input() candidateSkillExpertise: CandidateModel;
  @Output() getDataEvent = new EventEmitter();

  dataByCategory: SkillExpertiseByCategoryModel[];
  type: string;
  isDisplayNew = false;
  skills: any[] = [];
  expertises: any[] = [];
  favoriteSkills: any = [];
  favoriteExpertises: any = [];
  candidateSkill: CandidateSkillModel[] = [];
  candidateExpertise: CandidateExpertiseModel[] = [];
  displayDescription = true;
  candidate: CandidateModel;
  private subscriptions = [];

  indicators = [
    { color: '#00ECCD', name: 'Validated' },
    //{ color: '#E8A117', name: 'In Validation' },
    { color: '#D4D5DE', name: 'Not Validated' },
    { color: '#212121', name: 'My Favorite' },
  ];
  skillExpertiseStatus = SkillExpertiseStatus;

  constructor(
    private techfaceProvider: TechfaceProvider,
    public spinner: NgxSpinnerService
  ) {}
  ngOnInit(): void {}

  ngOnChanges(): void {
    this.setEmpty();

    if (this.candidateSkillExpertise) {
      this.candidateSkillExpertise.displayIndicators =
        this.candidateSkillExpertise.displayIndicators === undefined
          ? true
          : this.candidateSkillExpertise?.displayIndicators;
      this.setSkillAndFavoriteSkill();
      this.setExpertiseAndFavoriteExpertise();
    }
  }

  /**
   * set empty skills,favoriteSkills array
   * set empty expertises,favoriteExpertises array
   */
  setEmpty(): void {
    this.skills = [];
    this.favoriteSkills = [];
    this.expertises = [];
    this.favoriteExpertises = [];
  }

  /**
   * push data to skills
   * @param skill
   * @param candidateSkill
   */
  addToSkills(
    skill: SkillsExpertisesModel,
    candidateSkill: CandidateSkillModel
  ): void {
    this.skills.push({
      ...skill,
      confirmationStatus: !candidateSkill.confirmationStatus
        ? SkillExpertiseStatus.NOT_CONFIRMED
        : candidateSkill.confirmationStatus,
      isfavorite: candidateSkill.isfavorite,
    });
  }

  /**
   * push data to favorit skills
   * @param skill
   * @param candidateSkill
   */
  addToFavoriteSkills(
    skill: SkillsExpertisesModel,
    candidateSkill: CandidateSkillModel
  ): void {
    this.favoriteSkills.push({
      ...skill,
      confirmationStatus: !candidateSkill.confirmationStatus
        ? SkillExpertiseStatus.NOT_CONFIRMED
        : candidateSkill.confirmationStatus,
      isfavorite: candidateSkill.isfavorite,
    });
  }

  /**
   * map to create skills and favorite skills
   */
  setSkillAndFavoriteSkill(): void {
    this.allSkillsExpertiseByCategories?.skillByCategory
    .filter(c => c.skillsExpertises.length > 0)
    .map(
      (skillByCategory) => {
        skillByCategory.skillsExpertises.map((element) => {
          this.candidateSkillExpertise.skills.map((skill) => {
            if (element._id === skill?.skillId) {
              if (!skill.isfavorite) {
                this.addToSkills(element, skill);
              } else {
                this.addToFavoriteSkills(element, skill);
              }
            }
          });
        });
      }
    );
  }

  /**
   * add to expertise
   * @param expertise
   * @param candidateExpertise
   */
  addToExpertise(
    expertise: SkillsExpertisesModel,
    candidateExpertise: CandidateExpertiseModel
  ): void {
    this.expertises.push({
      ...expertise,
      confirmationStatus: !candidateExpertise.confirmationStatus
        ? SkillExpertiseStatus.NOT_CONFIRMED
        : candidateExpertise.confirmationStatus,
      isfavorite: candidateExpertise.isfavorite,
    });
  }

  /**
   * add to favorite expertise
   * @param expertise
   * @param candidateExpertise
   */
  addToFavoriteExpertises(
    expertise: SkillsExpertisesModel,
    candidateExpertise: CandidateExpertiseModel
  ): void {
    this.favoriteExpertises.push({
      ...expertise,
      confirmationStatus: !candidateExpertise.confirmationStatus
        ? SkillExpertiseStatus.NOT_CONFIRMED
        : candidateExpertise.confirmationStatus,
      isfavorite: candidateExpertise.isfavorite,
    });
  }

  /**
   * map to create expertise and favorite expertise
   */
  setExpertiseAndFavoriteExpertise(): void {
    this.allSkillsExpertiseByCategories?.expertiseByCategory
    .filter(c => c.skillsExpertises.length > 0)
    .map(
      (expertiseByCategory) => {
        expertiseByCategory.skillsExpertises.map((element) => {
          this.candidateSkillExpertise.expertises.map((expertise) => {
            if (element._id === expertise.expertiseId) {
              if (!expertise.isfavorite) {
                this.addToExpertise(element, expertise);
              } else {
                this.addToFavoriteExpertises(element, expertise);
              }
            }
          });
        });
      }
    );
  }

  /**
   * call function when select new item from skill or expertise page
   * @param isDisplay :display new skill-expertise page or not
   * @param type skill or expertise
   */
  displayNewItem(isDisplay: boolean, type: string): void {
    this.isDisplayNew = isDisplay;
    this.type = type;
    switch (type) {
      case 'skill':
        {
          this.dataByCategory =
            this.allSkillsExpertiseByCategories.skillByCategory.filter(c => c.skillsExpertises?.length);
          this.bindItem();
          this.setSelectSkill();
        }
        break;
      case 'expertise': {
        this.dataByCategory =
          this.allSkillsExpertiseByCategories.expertiseByCategory.filter(c => c.skillsExpertises?.length);
        this.bindItem();
        this.setSelectExpertise();
      }
    }

    this.spinner.show();
    this.getDataEvent.emit();
  }

  /**
   * change select variable to true of skills
   */
  setSelectSkill(): void {
    this.dataByCategory.forEach((category) => {
      category.skillsExpertises.map((item) => {
        this.skills.concat(this.favoriteSkills).forEach((skill) => {
          if (item.id === skill._id) {
            item.selected = true;
            item.isfavorite = skill.isfavorite;
            (item as any).confirmationStatus = skill.confirmationStatus
          }
        });
      });
    });
  }

  /**
   * change select variable to true of expertises
   */
  setSelectExpertise(): void {
    this.dataByCategory.forEach((category) => {
      category.skillsExpertises.map((item) => {
        this.expertises.concat(this.favoriteExpertises).forEach((expertise) => {
          if (item.id === expertise._id) {
            item.selected = true;
            item.isfavorite = expertise.isfavorite;
            (item as any).confirmationStatus = expertise.confirmationStatus
          }
        });
      });
    });
  }

  /**
   * bind skills and expertises
   */
  bindItem(): void {
    this.dataByCategory = this.dataByCategory.map((data) => ({
      categoryId: data.categoryId,
      categoryName: data.categoryName,
      skillsExpertises: data.skillsExpertises.map((skillExpertise) => ({
        _id: skillExpertise?._id,
        id: skillExpertise?._id,
        name: skillExpertise.name,
        type: skillExpertise.type,
        category: skillExpertise.category,
        selected: false,
      })),
    }));
  }

  /**
   * when remove item
   * @param id of item
   * @param type of item (skill or expertise)
   */
  onRemoveData(id: string, type: string): void {
    switch (type) {
      case 'skill':
        this.removeSkill(id);
        break;
      case 'expertise':
        this.removeExpertise(id);
    }
    this.setCandidateObject();
    this.updateCandidate();
  }

  /**
   * remove skill from skill and favorite skill
   * @param id
   */
  removeSkill(id: string): void {
    console.log(id, this.skills)
    const indexSkill = this.skills.findIndex((skill) => skill._id === id);
    const indexFavoritSkill = this.favoriteSkills.findIndex((skill) => skill._id === id);
    if (indexSkill >= 0) {
      this.skills.splice(indexSkill, 1);
    }
    if (indexFavoritSkill >= 0) {
      this.favoriteSkills.splice(indexFavoritSkill, 1);
    }
  }

  /**
   * remove expertise from expertise and favorite expertise
   * @param id
   */
  removeExpertise(id: string): void {
    const indexExpertise = this.expertises.findIndex((exp) => exp._id === id);
    const indexFavoritExpertise = this.favoriteExpertises.findIndex((exp) => exp._id === id);
    if (indexExpertise >= 0) {
      this.expertises.splice(indexExpertise, 1);
    }
    if (indexFavoritExpertise >= 0) {
      this.favoriteExpertises.splice(indexFavoritExpertise, 1);
    }
  }
  /**
   * drop item from norml selection to favorite
   * @param event
   * @param type
   */
  drop(event: CdkDragDrop<string[]>): void {
    if (
      !(
        parseInt(event.container.id.split('-')[3]) % 2 === 0 &&
        event.container.data.length > 2 &&
        this.skills.length > 0
      )
    ) {
      if (event.previousContainer === event.container) {
        moveItemInArray(
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      } else {
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex
        );
      }
      this.setCandidateObject();
      this.updateCandidate();
    }
  }

  /**
   * set candidate  object to update candidate
   */
  setCandidateObject(): void {
    this.setCandidateSkillObject();
    this.setCandidateExpertise();
    this.candidate = {
      skills: this.candidateSkill,
      expertises: this.candidateExpertise,
    };
  }

  /**
   * set candidate skill object to update candidate
   */
  setCandidateSkillObject(): void {
    this.favoriteSkills.forEach((favoritSkill) => {
      favoritSkill.isfavorite = true;
    });
    this.skills.forEach((skill) => {
      skill.isfavorite = false;
    });
    this.candidateSkill = [];
    this.skills.concat(this.favoriteSkills).forEach((skill) => {
      this.candidateSkill.push({
        skillId: skill?._id,
        isfavorite: skill?.isfavorite,
        confirmationStatus: skill?.confirmationStatus,
      });
    });
  }

  /**
   * set candidate  expertise object to update candidate
   */
  setCandidateExpertise(): void {
    this.favoriteExpertises.forEach((favoritExpertise) => {
      favoritExpertise.isfavorite = true;
    });
    this.expertises.forEach((expertise) => {
      expertise.isfavorite = false;
    });
    this.candidateExpertise = [];
    this.expertises.concat(this.favoriteExpertises).forEach((expertise) => {
      this.candidateExpertise.push({
        expertiseId: expertise?._id,
        isfavorite: expertise?.isfavorite,
        confirmationStatus: expertise?.confirmationStatus,
      });
    });
  }

  /**
   * update candidate
   */
  updateCandidate(): void {
    const candidateObj: IUpdateCandidate = {
      id: localStorage.getItem('candidateId'),
      candidateObj: this.candidate,
    };

    this.subscriptions.push(
      this.techfaceProvider.updateCandidate(candidateObj).subscribe()
    );
  }

  /**
   * don't show how it work again
   */
  onClickDonotShowAgain(): void {
    this.candidate = { displayIndicators: false };
    this.updateCandidate();
    this.displayDescription = false;
  }
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
