<div class="skill-expertise-container">
  <div *ngIf="candidateSkillExpertise?.validating"
    class="border msg"
  >
    <div class="how-it-work">
      <div class="title">Validation currently running...</div>
    </div>
    <div class="description">
      We are currently validating your documents and are automatically detecting skills and expertise. 
      You can now or later manually add skills and expertise and we will validate them with the added documents if possible.
    </div>
  </div>
  <div class="skill-expertise-wrapper" cdkDropListGroup>
    <div class="line">
      <span>SKILLS</span>
      <div class="details border">
        <div>
          <div
            class="favorites"
            cdkDropList
            [cdkDropListData]="favoriteSkills"
            (cdkDropListDropped)="drop($event)"
          >
            <div *ngFor="let skill of favoriteSkills" cdkDrag>
              <div class="item">
                <div class="item-container">
                  <img
                    *ngIf="
                      skill.confirmationStatus ===
                      skillExpertiseStatus.NOT_CONFIRMED
                    "
                    src="assets/icons/star.svg"
                    alt="icon"
                  />
                  <img
                    *ngIf="
                      skill.confirmationStatus ===
                      skillExpertiseStatus.CONFIRMED
                    "
                    src="assets/icons/favorit-valid-icon.svg"
                    alt="icon"
                  />
                  <img
                    *ngIf="
                      skill.confirmationStatus ===
                      skillExpertiseStatus.PARTIALLY_CONFIRMED
                    "
                    src="assets/icons/favorit-invalid-icon.svg"
                    alt="icon"
                  />

                  {{ skill.name }}
                  <img
                    class="remove"
                    src="assets/icons/remove-item.svg"
                    alt="icon"
                    (click)="onRemoveData(skill._id, 'skill')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="max-favorites">
          <hr />
          <span> max 3 favorites</span>
        </div>
        <div
          cdkDropList
          [cdkDropListData]="skills"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="item" *ngFor="let skill of skills" cdkDrag>
            <div class="item-container">
              <div class="dot" [ngClass]="skill.confirmationStatus"></div>
              {{ skill.name }}
              <img
                class="remove"
                src="assets/icons/remove-item.svg"
                alt="icon"
                (click)="onRemoveData(skill._id, 'skill')"
              />
            </div>
          </div>
        </div>
        <div>
          <button
            class="add-btn btn btn-primary"
            (click)="displayNewItem(true, 'skill')"
          >
            Add new skill
          </button>
        </div>
      </div>
    </div>

    <div class="line">
      <span>EXPERTISE</span>
      <div class="details border">
        <div>
          <div
            class="favorites"
            cdkDropList
            [cdkDropListData]="favoriteExpertises"
            (cdkDropListDropped)="drop($event)"
          >
            <div *ngFor="let expertise of favoriteExpertises" cdkDrag>
              <div class="item">
                <div class="item-container">
                  <img
                    *ngIf="
                      expertise.confirmationStatus ===
                      skillExpertiseStatus.NOT_CONFIRMED
                    "
                    src="assets/icons/star.svg"
                    alt="icon"
                  />
                  <img
                    *ngIf="
                      expertise.confirmationStatus ===
                      skillExpertiseStatus.CONFIRMED
                    "
                    src="assets/icons/favorit-valid-icon.svg"
                    alt="icon"
                  />
                  <img
                    *ngIf="
                      expertise.confirmationStatus ===
                      skillExpertiseStatus.PARTIALLY_CONFIRMED
                    "
                    src="assets/icons/favorit-invalid-icon.svg"
                    alt="icon"
                  />
                  {{ expertise.name }}
                  <img
                    class="remove"
                    src="assets/icons/remove-item.svg"
                    alt="icon"
                    (click)="onRemoveData(expertise._id, 'expertise')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="max-favorites">
          <hr />
          <span> max 3 favorites</span>
        </div>
        <div
          cdkDropList
          [cdkDropListData]="expertises"
          (cdkDropListDropped)="drop($event)"
        >
          <div class="item" *ngFor="let expertise of expertises" cdkDrag>
            <div class="item-container">
              <div class="dot" [ngClass]="expertise.confirmationStatus"></div>
              {{ expertise.name }}
              <img
                class="remove"
                src="assets/icons/remove-item.svg"
                alt="icon"
                (click)="onRemoveData(expertise._id, 'expertise')"
              />
            </div>
          </div>
        </div>

        <div>
          <button
            class="add-btn btn btn-primary"
            (click)="displayNewItem(true, 'expertise')"
          >
            Add new expertise
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="candidateSkillExpertise?.displayIndicators && displayDescription"
    class="border msg"
  >
    <div class="how-it-work">
      <div class="title">How it works</div>
      <div class="dont-show" (click)="onClickDonotShowAgain()">
        Don’t show again
      </div>
    </div>
    <div class="description">
      We got these skills out from your documents. You can also add or remove them manually. 
      The indicators below are used to show if your documents validate the current skillset.
    </div>
    <div>
      <div class="indicators">
        <span class="title">Indicators</span>
        <div
          class="indicator"
          *ngFor="let indicator of indicators; let i = index"
        >
          <span
            *ngIf="i != 2"
            [style.background-color]="indicator.color"
          ></span>
          <img *ngIf="i == 2" src="assets/icons/star.svg" alt="icon" />
          {{ indicator.name }}
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDisplayNew">
  <div class="overlay"></div>
  <div class="new">
    <app-new-skill-expertise
      #newSkillExpertise
      [allDataByCategory]="dataByCategory"
      [candidateSkillExpertise]="candidateSkillExpertise"
      [type]="type"
      (closeEvent)="displayNewItem($event, '')"
    ></app-new-skill-expertise>
  </div>
</div>
<ngx-spinner></ngx-spinner>
