<div class="profile-image-description-container">
  <form
    [formGroup]="profileImageDescription"
    class="profile-image-description-wrapper"
    #myForm="ngForm"
  >
    <div class="line">
      <div class="line-title">Upload profile picture</div>
      <div>
        <label>Picture upload</label>
        <label *ngIf="!url" class="upload-file" for="upload-file">
          <ngx-file-drop
            dropZoneLabel="Click to upload picture or drop it here"
            (onFileDrop)="dropped($event)"
            (onFileOver)="fileOver($event)"
            (onFileLeave)="fileLeave($event)"
          >
          </ngx-file-drop>
        </label>
        <input id="upload-file" type="file" (change)="selectFile($event)" />
        <div class="img-container"><img [src]="url" *ngIf="url" /></div>
        <label *ngIf="url" class="edit-file btn btn-link" for="upload-file">
          <div>Change picture</div>
        </label>
      </div>
    </div>
    <div class="line">
      <div class="line-title">Description</div>
      <div>
        <label>Title</label>
        <div>
          <input
            matInput
            formControlName="title"
            name="title"
            id="title"
            placeholder="Please enter title"
          />
        </div>
      </div>
      <div>
        <label>Describe yourself in 1-2 sentences</label>
        <div>
          <textarea
            id="imageDescription"
            formControlName="imageDescription"
            name="imageDescription"
            bindLabel="imageDescription"
            [disabled]="false"
            [placeholder]="'Type here...'"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</div>
<ngx-spinner></ngx-spinner>
