<app-sign-in>
  <app-candidate-sign-in-email
    *ngIf="displayEmail"
    (displayEmitter)="setDispalyEmail($event)"
  ></app-candidate-sign-in-email>
  <app-candidate-sign-in-password
    *ngIf="displayPassword"
    (displayEmitter)="setDispalyPassword($event)"
    [candidateData]="candidateData"
  ></app-candidate-sign-in-password>
  <app-candidate-sign-in-confirm
    *ngIf="displayConfirm"
    [candidateData]="candidateData"
  ></app-candidate-sign-in-confirm>
</app-sign-in>
