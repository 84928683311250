import { Action, createAction, props } from '@ngrx/store';
import { IQuery } from '../interfaces/query.interface';
import { IUpdateQuery } from '../interfaces/updateQuery.interface';
import { CompanyResponseModel } from '../models/companyResponse.model';
import { QueryResponseModel } from '../models/queryResponse.model';
import { IMatch } from '../interfaces/match.interface';
import { ICandidate } from '../interfaces/candidate.interface';
import { MetaDataIdsModel } from '../models/metaDataIds.model';
import { MetaDataModel } from '../models/metaData.model';
import { UpdateCompanyModel } from '../models/updateCompany.model ';
import { QueryCandidateIdsModel } from '../models/queryCandidateIds.model';
import { CandidatesIdsModel } from '../models/candidatesIds.model';
import { UserResponseModel } from '../models/userResponse.model';
import { UserModel } from '../models/user.model';
import { ICompanyInformation } from '../interfaces/comapny.interface';
import { TokenModel } from '../models/token.model';
import { IUpdateUser } from '../interfaces/updateUser.interface';
import { CandidateModel } from '../models/candidate.model';
import { IUpdateCandidate } from '../interfaces/updateCandidate.interface';
import { SkillExpertiseByCategoriesModel } from '../models/skillExpertiseByCategories.model';

export const onGetCompanyById = createAction(
  '[Get Company By Id] onGetCompanyById',
  props<{ payload: string }>()
);
export const onGetCompanyByIdSuccess = createAction(
  '[Get Company By Id] onGetCompanyById Success',
  props<{ payload: ICompanyInformation }>()
);
export const onGetCompanyByIdFail = createAction(
  '[Get CaCompanyse By Id] onGetCompanyById Fail',
  props<{ payload: any }>()
);

export const onGetQueriesByCompanyId = createAction(
  '[Get Queries By Company Id] onGetQueriesByCompanyId',
  props<{ payload: string }>()
);
export const onGetQueriesByCompanyIdSuccess = createAction(
  '[Get Queries By Company Id] onGetQueriesByCompanyId Success',
  props<{ payload: QueryResponseModel[] }>()
);
export const onGetQueriesByCompanyIdFail = createAction(
  '[Get Queries By Company Id] onGetQueriesByCompanyId Fail',
  props<{ payload: any }>()
);

// getMatchesByQueryId
export const onGetMatchesByQueryId = createAction(
  '[Get Matches By Query Id] onGetMatchesByQueryId',
  props<{ payload: string }>()
);
export const onGetMatchesByQueryIdSuccess = createAction(
  '[Get Matches By Query Id] onGetMatchesByQueryId Success',
  props<{ payload: IMatch[] }>()
);
export const onGetMatchesByQueryIdFail = createAction(
  '[Get Matches By Query Id] onGetMatchesByQueryId Fail',
  props<{ payload: any }>()
);

// getMatchByQueryAndCandidateIds
export const onGetMatchByQueryAndCandidateIds = createAction(
  '[Get Match By Query Id and Candidate Id] onGetMatchByQueryAndCandidateIds',
  props<{ payload: QueryCandidateIdsModel }>()
);
export const onGetMatchByQueryAndCandidateIdsSuccess = createAction(
  '[Get Match By Query Id and Candidate Id] onGetMatchByQueryAndCandidateIds Success',
  props<{ payload: IMatch }>()
);
export const onGetMatchByQueryAndCandidateIdsFail = createAction(
  '[Get Match By Query Id and Candidate Id] onGetMatchByQueryAndCandidateIds Fail',
  props<{ payload: any }>()
);

// getMatchesByCompanyIdAndQueryId
export const onUpdateCompanyStatus = createAction(
  '[Update Company Id] onUpdateCompanyStatus',
  props<{ payload: UpdateCompanyModel }>()
);
export const onUpdateCompanyStatusSuccess = createAction(
  '[Update Company Id] onUpdateCompanyStatus Success',
  props<{ payload: IMatch }>()
);
export const onUpdateCompanyStatusFail = createAction(
  '[Update Company Id] onUpdateCompanyStatus Fail',
  props<{ payload: any }>()
);

// onGetCandidateById
export const onGetCandidateById = createAction(
  '[Get Candidate By Id] onGetCandidateById',
  props<{ payload: string }>()
);
export const onGetCandidateByIdSuccess = createAction(
  '[Get Candidate By Id] onGetCandidateById Success',
  props<{ payload: ICandidate }>()
);
export const onGetCandidateByIdFail = createAction(
  '[Get Candidate By Id] onGetCandidateById Fail',
  props<{ payload: any }>()
);
// onGetCandidatesByIds
export const onGetCandidatesByIds = createAction(
  '[Get Candidates By Ids] onGetCandidateById',
  props<{ payload: CandidatesIdsModel }>()
);
export const onGetCandidatesByIdsSuccess = createAction(
  '[Get Candidates By Ids] onGetCandidateById Success',
  props<{ payload: ICandidate[] }>()
);
export const onGetCandidatesByIdsFail = createAction(
  '[Get Candidates By Ids] onGetCandidateById Fail',
  props<{ payload: any }>()
);

// add candidate
export const onAddCandidate = createAction(
  '[Create new Candidate] onAddCandidate',
  props<{ payload: CandidateModel }>()
);
export const onAddCandidateSuccessfully = createAction(
  '[Create new Candidate] onAddCandidateSuccessfully',
  props<{ payload: ICandidate }>()
);
export const onAddCandidateFailed = createAction(
  '[Create new Candidate] onAddCandidateFailed',
  props<{ payload: any }>()
);
// add User
export const onAddUser = createAction(
  '[Create new User] onAddUser',
  props<{ payload: UserModel }>()
);
export const onAddUserSuccessfully = createAction(
  '[Create new User] onAddUserSuccessfully',
  props<{ payload: UserResponseModel }>()
);
export const onAddUserFailed = createAction(
  '[Create new User] onAddUserFailed',
  props<{ payload: any }>()
);
// Get all MetaData

export const onGetMetaData = createAction('[Get Skills] onGetSkills');
export const onGetMetaDataIsSuccessfully = createAction(
  '[Get Skills] onGetSkillsIsSuccessfully',
  props<{ payload: MetaDataModel }>()
);
export const onGetMetaDataIsFailed = createAction(
  '[Get Skills] onGetQueryFaonGetSkillsIsFailed',
  props<{ payload: any }>()
);

// Get all skill expertise by categories

export const onGetSkillExpertiseByCatefgories = createAction(
  '[Get Skills expertise by category ] onGetSkillExpertiseByCatefgories'
);
export const onGetSkillExpertiseByCatefgoriesSuccessfully = createAction(
  '[Get Skills expertise by category] onGetSkillExpertiseByCatefgoriesSuccessfully',
  props<{ payload: SkillExpertiseByCategoriesModel }>()
);
export const onGetSkillExpertiseByCatefgoriesFailed = createAction(
  '[Get Skills expertise by category] onGetSkillExpertiseByCatefgoriesFailed',
  props<{ payload: any }>()
);

// GetQueryMetaDataByIds
export const onGetQueryMetadataByIds = createAction(
  '[Get Query MetadataByIds] onGetQueryMetadataByIds',
  props<{ payload: MetaDataIdsModel }>()
);
export const onGetQueryMetadataByIdsSuccessfully = createAction(
  '[Get Query MetadataByIds] onGetQueryMetadataByIdsSuccessfully',
  props<{ payload: MetaDataModel }>()
);
export const onGetQueryMetadataByIdsFailed = createAction(
  '[Get Query MetadataByIds] ononGetQueryMetadataByIdsFailed',
  props<{ payload: any }>()
);

// SignIn

export const signIn = createAction(
  '[User] signin',
  props<{ payload: UserModel }>()
);
export const onSignInSuccessfully = createAction(
  '[User] onSigninSuccessfully',
  props<{ payload: UserResponseModel }>()
);
export const onSignInFailed = createAction(
  '[User] onSigninFailed',
  props<{ payload: UserResponseModel }>()
);

// token

export const getToken = createAction(
  '[User] getToken',
  props<{ payload: UserModel }>()
);
export const onGetTokenSuccessfully = createAction(
  '[User] onGetTokenSuccessfully',
  props<{ payload: TokenModel }>()
);
export const onGetTokenFailed = createAction(
  '[User] onGetTokenFailed',
  props<{ payload: TokenModel }>()
);
// New Query
export const onCreateNewQuery = createAction(
  '[Create new Query] onCreateNewQuery',
  props<{ payload: IQuery }>()
);
export const onCreateNewQuerySuccessfully = createAction(
  '[Create new Query] onCreateNewQuerySuccessfully',
  props<{ payload: QueryResponseModel }>()
);
export const onCreateNewQueryFailed = createAction(
  '[Create new Query] onCreateNewQueryFailed',
  props<{ payload: any }>()
);

// Update Query

export const onUpdateQuery = createAction(
  '[Update Query] onUpdateQuery',
  props<{ payload: IUpdateQuery }>()
);
export const onUpdateQuerySuccessfully = createAction(
  '[Update Query] onUpdateQuerySuccessfully',
  props<{ payload: QueryResponseModel }>()
);
export const onUpdateQueryFailed = createAction(
  '[Update Query] onUpdateQueryFailed',
  props<{ payload: QueryResponseModel }>()
);

// Get Query by Id

export const onGetQueryById = createAction(
  '[Get Query Id] onGetQueryById',
  props<{ payload: string }>()
);
export const onGetQueryByIdSuccessfully = createAction(
  '[Get Query Id] onGetQuerySuccessfully',
  props<{ payload: QueryResponseModel }>()
);
export const onGetQueryByIdFailed = createAction(
  '[Get Query Id] onGetQueryFailed',
  props<{ payload: QueryResponseModel }>()
);

// Get all metaData

export const onGetMetadata = createAction('[Get Metadata] onGetMetadata');
export const onGetMetadataSuccessfully = createAction(
  '[Get MetaData] onGetMetadataSuccessfully',
  props<{ payload: MetaDataModel }>()
);
export const onGetMetadataFailed = createAction(
  '[Get MetaData] onGetMetadataFailed',
  props<{ payload: any }>()
);

// Update User

export const onUpdateUser = createAction(
  '[Update User] onUpdateUser',
  props<{ payload: IUpdateUser }>()
);
export const onUpdateUserSuccessfully = createAction(
  '[Update User] onUpdateUserSuccessfully',
  props<{ payload: UserModel }>()
);
export const onUpdateUserFailed = createAction(
  '[Update User] onUpdateUserFailed',
  props<{ payload: any }>()
);

// Update Candidate

export const onUpdateCandidate = createAction(
  '[Update Candidate] onUpdateCandidate',
  props<{ payload: IUpdateCandidate }>()
);
export const onUpdateCandidateSuccessfully = createAction(
  '[Update Candidate] onUpdateCandidateSuccessfully',
  props<{ payload: ICandidate }>()
);
export const onUpdateCandidateFailed = createAction(
  '[Update Candidate] onUpdateCandidateFailed',
  props<{ payload: any }>()
);

// Get Metadata by Ids

export const onGetMetadataByIds = createAction(
  '[Get MetadataByIds] onGetMetadataByIds',
  props<{ payload: MetaDataIdsModel }>()
);
export const onGetMetadataByIdsSuccessfully = createAction(
  '[Get MetadataByIds] onGetMetadataByIdSuccessfully',
  props<{ payload: MetaDataModel }>()
);
export const onGetMetadataByIdsFailed = createAction(
  '[Get MetadataByIds] onGetMetadataByIdsFailedByIds',
  props<{ payload: any }>()
);

// Get Email by Candidate Id

export const onGetEmailByCandidateId = createAction(
  '[Get Email By Candidate Id] onGetEmailByCandidateId',
  props<{ payload: string }>()
);
export const onGetEmailByCandidateIdSuccessfully = createAction(
  '[Get Email By Candidate Id] onGetEmailByCandidateIdSuccessfully',
  props<{ payload: { userEmail: string } }>()
);
export const onGetEmailByCandidateIdFailed = createAction(
  '[Get Email By Candidate Id] onGetEmailByCandidateIdFailed',
  props<{ payload: any }>()
);

export interface IActionWithPayload<T> extends Action {
  payload: T;
}
