import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';

@Injectable()
export class NavigationAuthGuard implements CanActivate {

  /**
   *
   * @param navigationService service to navigate
   */
  constructor(private navigationService: NavigationService) {
  }

  /**
   * if there is no username in local storage navigate to login
   * if there is username in local storage return true to navigate to dashboard page
   */
  canActivate(): Observable<boolean> | boolean {
    const userName = localStorage.getItem('username');
    if (userName) {
      return true;
    } else {
      localStorage.clear();
      this.navigationService.navigateByUrl('login');
    }
  }
}
