import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Editor } from 'ngx-editor';
import { MatchTypeModel } from '../../../shared/models/matchType.model';
import { MatchesService } from '../../../shared/services/services/matches.service';

@Component({
  selector: 'app-chatting-area',
  templateUrl: './chatting-area.component.html',
  styleUrls: ['./chatting-area.component.scss'],
})
export class ChattingAreaComponent implements OnInit {
  @Input() data: any;
  @Output() getDataEmit = new EventEmitter();
  @Output() getStatusEmit = new EventEmitter();

  editor: Editor;
  msgText = '';

  constructor(private matchService: MatchesService) {}

  ngOnInit(): void {
    this.editor = new Editor();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
  onClickSend() {
    const msgData = {
      message: {
        senderId: localStorage.getItem('userId'),
        msgText: this.msgText,
        timestamp: new Date(),
      },
    };

    this.matchService
      .addMsg(msgData, this.data.receiverData.matchId)
      .subscribe((response) => {
        if (response) {
          this.getDataEmit.emit({msgData, matchId: this.data.receiverData.matchId});
          this.msgText = '';

        }
      });
  }
  changeStatus(data: MatchTypeModel) {
    this.getStatusEmit.emit(data);
  }

  
}
