<div class="cv-container">
  <div class="company-wrapper" *ngFor="let company of companiesByName;">
    <div class="company-info">
      <div class="circle"></div>
      <div class="line-container">
        <div class="line"></div>
      </div>
    </div>
    <div class="company">
      <div class="company-name">{{canSeeCandidate ? company.companyName : 'Company'}}</div>
      <div *ngFor="let companyDetails of company.companyDetails; let index = index">
        <div *ngIf="index===0" class="company-duration">
          <span *ngIf="company.totalDuration.years>0">{{company.totalDuration.years}} year<span
              *ngIf="company.totalDuration.years>1">s</span></span>
          <span *ngIf="company.totalDuration.months>0"> {{company.totalDuration.months}} month<span
              *ngIf="company.totalDuration.months>1">s</span></span>
          <span > ( {{companyDetails.from |date:'MM.yyyy'}} -
            {{companyDetails.to|date:'MM.yyyy'}} )</span>
        </div>
        <div class="company-role">{{companyDetails.role}}</div>
        <div class="company-location">{{companyDetails.location}}</div>
      </div>
    </div>
  </div>
</div>
