<div class="skills-container">
  <div class="skills-form">
      <p *ngIf="canEdit" [ngClass]="{'error': error}">
          Please select between 3 and 8 skills
      </p>
      <form class="example-form">
          <div class="form-group">
              <div *ngIf="canEdit">
                  <button class="btn btn-secondary" (click)="onAdd()">Add skill</button>
              </div>
              <div>
                  <mat-chip-list>
                      <mat-chip *ngFor="let element of skills" [removable]="canEdit && removable"
                          (removed)="remove(element)">
                          <mat-icon matChipRemove *ngIf="canEdit && removable"><img src="../../../assets/icons/times-circle.png">
                          </mat-icon>
                          <div class="chip-word">
                              {{element.name}}
                          </div>
                      </mat-chip>
                  </mat-chip-list>
              </div>
          </div>
      </form>
  </div>
</div>