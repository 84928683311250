<div class="doucument-upload-container">
  <form
    [formGroup]="documentUpload"
    #myForm="ngForm"
    class="doucument-upload-wrapper"
  >
    <div class="line">
      <div class="line-title">UPLOAD YOUR CV</div>
      <div>
        <label>CV</label>
        <label class="upload-file" for="upload-file">
          <div>
            <span class="upload-msg">
              <img src="assets/icons/icons8-upload-48.png" />
              Upload CV</span
            >
            <ngx-file-drop
              dropZoneLabel="or drop it here"
              (onFileDrop)="droppedCv($event)"
              (onFileOver)="fileOver($event)"
              (onFileLeave)="fileLeave($event)"
            >
            </ngx-file-drop>
          </div>
        </label>
        <div class="file-msg">File types for document upload: pdf / maximum 1 file and 15MB</div>
        <div *ngIf="cv?.name" class="file-name">
          <div>
            <img src="assets/icons/Vector.svg" />
            <a [href]="cv?.url" target="_blank">{{ cv.name }}</a>
          </div>
          <div>
            <img src="assets/icons/backet.svg" (click)="removeFile()" />
          </div>
        </div>

        <input id="upload-file" type="file" accept=".pdf" (change)="selectCvFile($event)" />
      </div>
    </div>
    <div class="line">
      <div class="line-title">UPLOAD ADDITIONAL FILES</div>
      <div>
        <label>Additional Files</label>
        <label class="upload-file" for="upload-files">
          <div>
            <span class="upload-msg">
              <img src="assets/icons/icons8-upload-48.png" />
              Upload additional files</span
            >
            <ngx-file-drop
              dropZoneLabel="or drop it here"
              (onFileDrop)="droppedAdditional($event)"
              (onFileOver)="fileOver($event)"
              (onFileLeave)="fileLeave($event)"
            >
            </ngx-file-drop>
          </div>
        </label>
        <div class="file-msg">File types for document upload: pdf / maximum 15MB</div>
        <div *ngFor="let file of additionalDocuments" class="file-name">
          <div>
            <img src="assets/icons/Vector.svg" />
            <a [href]="file?.url" target="_blank">{{ file?.name }}</a>
          </div>
          <div>
            <img
              src="assets/icons/backet.svg"
              (click)="removeAdditionalFile(file.id)"
            />
          </div>
        </div>
        <input
          id="upload-files"
          type="file"
          accept=".pdf"
          multiple
          (input)="selectAdditionalFiles($event)"
        />
      </div>
    </div>
  </form>
</div>
<ngx-spinner></ngx-spinner>
