import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IAboutCandidate } from '../../../shared/interfaces/candidate.interface';
import { CompanyStatus } from '../../../shared/enums/companyStatus.enum';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { CompanyCandidateStatusModel } from '../../../shared/models/companyCandidateStatus.model';
import { ActivatedRoute } from '@angular/router';
import { QueryCandidateIdsModel } from '../../../shared/models/queryCandidateIds.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-about-candidate',
  templateUrl: './about-candidate.component.html',
  styleUrls: ['./about-candidate.component.scss']
})
export class AboutCandidateComponent {
  publicBaseUrl = environment.modeso.publicAssetsBaseurl;

  @Input() canSeeCandidate: boolean;
  @Input() candidate: IAboutCandidate;
  @Input() candidateAndCompanyStatus: CompanyCandidateStatusModel;

  queryCandidateIdsObj: QueryCandidateIdsModel;

  constructor() {

  }


}
