<div class="login-page">
  <div class="login-image">
  </div>
  <div class="login-wrapper">
    <div class="login-form">
      <div class="login-page-title">
        <h1>Forgot Password</h1>
      </div>
      <p>Welcome to techface.careers.
        <span *ngIf="hasToken">Please set a new password.</span>
        <span *ngIf="!hasToken">Please enter your e-mail below to retrieve a link.</span>
      </p>
      <form  *ngIf="!hasToken"
        [formGroup]="emailForm"
        #myForm="ngForm"
        (ngSubmit)="submit(emailForm)"
        id="email-form"
      >
        <div class="form-group">
          <label>E-Mail</label>
          <div>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="email"
                name="email"
                id="email"
                placeholder="Please enter"
              />
              <mat-error
                class="error-msg"
                id="email-invalid"
                *ngIf="email.errors?.required"
              >
                enter correct email</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div class="submit-btn">
          <button class="btn btn-primary" type="submit">Reset Password</button>
          <button class="btn btn-link" (click)="backToLogin()">Back to Login</button>
        </div>
      </form>
      <form  *ngIf="hasToken"
        [formGroup]="passwordForm"
        #myForm="ngForm"
        (ngSubmit)="submit(passwordForm)"
        id="password-form">
        <div class="form-group">
          <label> Password </label>
          <div>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="password"
                placeholder="Please enter"
                name="password"
                id="password"
                formControlName="password"
              />
              <mat-error
                class="error-msg"
                id="password-invalid"
              >
              min. length of 12 characters, containing at least one upper- and lower case letter, and a number</mat-error
              >
              <mat-hint>min. length of 12 characters, containing at least one upper- and lower case letter, and a number</mat-hint>

            </mat-form-field>
          </div>
        </div>
        <div class="form-group">
          <label> Repeat Password </label>
          <div>
            <mat-form-field appearance="outline">
              <input
                matInput
                type="password"
                placeholder="Please enter"
                name="repeatPassword"
                id="repeatPassword"
                formControlName="repeatPassword"
              />
              <mat-error
                class="error-msg"
                id="password-invalid"
              >
              Password doesn't match</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="loginError" class="error-login">
          There was an error resetting your password. Please try again.
        </div>
        <div class="submit-btn">
          <button class="btn btn-primary" type="submit">Reset Password</button>
          <button class="btn btn-link" (click)="backToLogin()">Back to Login</button>
        </div>
      </form>
    </div>
    <div class="register-now">
      <div class="register-title">
        <h1>Any questions?</h1>
      </div>
      <div>
	       <a class="btn btn-secondary" href="mailto:support@techface.ch"> Contact us!</a>
      </div>
    </div>
    <div class="links">
      <a class="btn btn-link" href="https://www.techface.ch" target="_blank">techface.ch</a>
      <a class="btn btn-link" href="https://www.techface.ch/about-us/" target="_blank">About</a>
      <a class="btn btn-link" href="https://www.techface.ch/privacy-policy/" target="_blank">Privacy Policy</a>
      <a class="btn btn-link" href="https://www.techface.ch/imprint/" target="_blank">Imprint</a>
    </div>
  </div>
</div>
