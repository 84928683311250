import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MsgType } from '../../../shared/enums/msgType.enum';
import { ReceiverModel } from '../../../shared/models/receiver.model';
import { MatchActionTypes } from '../../../shared/models/matchType.model';

@Component({
  selector: 'app-receiver-data',
  templateUrl: './receiver-data.component.html',
  styleUrls: ['./receiver-data.component.scss'],
})
export class ReceiverDataComponent {
  @Input() receiverData: ReceiverModel;
  @Output() getStatusEmit = new EventEmitter();
  activeType = MsgType.active;
  acceptMatch=MatchActionTypes.ACCEPT;
  discardMatch=MatchActionTypes.DISCARD;

  constructor() {}

  onClick(state?: string) {
    const data = {
      type: state,
      id: this.receiverData.matchId,
    };
    this.getStatusEmit.emit(data);
  }
}
