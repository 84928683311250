import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TechfaceConfigService } from '../../../techface.config';
import { IUploadURL } from '../../models/uploadUrl.model';
import { BaseApiService } from './BaseApiService';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends BaseApiService {
  private server = '';
  private microserviceName = 'files';

  constructor(
    private http: HttpClient,
    @Inject(TechfaceConfigService) private config
  ) {
    super();
    this.server = config.apiGateway;
  }

  public getUploadUrl(filename): Observable<IUploadURL> {
    const url = `${this.server}${this.microserviceName}/url-for/${filename}`;
    return this.http.get<IUploadURL>(url);
  }

  public uploadFile(url: string, file, contentType: string): Observable<any> {
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': contentType,
    });
    // headers = headers.append('Accept', 'application/json');

    //fetch url
    return this.http.put(url, file, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }
}
