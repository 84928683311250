<div class="certificate-container">
  <div class="certificate" *ngFor="let certificate of certificates;">
    <img src="../../../../assets/icons/certificate.svg" />
    <div>
      <div class="certificate-name">{{certificate?.certificateName}}</div>
      <div class="certificate-info">
        <span class="certificate-date">{{certificate?.from |date:'dd.MM.yyyy'}}</span>
        <span *ngIf="certificate?.customInformation" class="certificate-customInfo"> | {{certificate?.customInformation}}</span>
      </div>
    </div>
  </div>
</div>
