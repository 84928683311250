<div class="skills-expertise-container">
  <div class="data-container" *ngIf="topMatchMetaData && topMatchMetaData.length > 0">
    <div class="title">{{ isCandidateMyself ? 'Favorites' : 'Top Matches' }}</div>
    <div class="flex">
      <div class="data match" *ngFor="let data of topMatchMetaData;">
        <span class="status {{data.status}}"></span>
        <span>{{data.name}} </span>
      </div>
    </div>
  </div>

  <div class="data-container" *ngIf="candidateMetaData && candidateMetaData.length > 0">
    <div class="title" *ngIf="topMatchMetaData && topMatchMetaData.length > 0">More {{title}}</div>
    <div class="flex">
      <div class="data" *ngFor="let data of candidateMetaData;">
        <span class="status {{data.status}}"></span>
        <span>{{data.name}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="topMatchMetaData && candidateMetaData && candidateMetaData.length === 0 && topMatchMetaData.length === 0">No {{title.toLowerCase()}} defined.</div>
</div>
