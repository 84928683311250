import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../shared/models/user.model';
import { NavigationService } from '../../shared/services/navigation/navigation.service';
import { BasePageComponent } from '../base-page/base-page.component';
import { TechfaceProvider } from '../../shared/providers/techface.provider';
import { UserService } from '../../shared/services/services/user.service';
import { map, mergeMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { UserResponseModel } from '../../shared/models/userResponse.model';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent
  extends BasePageComponent
  implements OnInit, OnDestroy {
  loginError = false;
  // login form
  loginForm: FormGroup;
  user: UserModel = {
    email: '',
    password: '',
  };

  subscriptions: any[] = [];
  /**
   *
   * @param fb FormBuilder
   * @param navigationService service to navigation
   * @param route active router
   */
  constructor(
    private fb: FormBuilder,
    private navigationService: NavigationService,
    public route: ActivatedRoute,
    private techfaceProvider: TechfaceProvider,
    private userService: UserService
  ) {
    super(route);
  }

  /**
   * login form default value and validation
   */
  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username_email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  /**
   * return username/email from login form
   */
  get username_email(): AbstractControl {
    return this.loginForm.get('username_email');
  }

  /**
   * return password from login form
   */
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }

  /**
   * when click login to submit username/email and password
   * if there is username/email and password set username in
   * local storage and navigate to dashboard page
   * @param form
   */
  submit(form: FormGroup): void {
    this.loginError = false;
    localStorage.clear();
    if (form.value.username_email && form.value.password) {
      this.user = {
        email: form.value.username_email,
        password: form.value.password,
      };
      let returnUser: UserResponseModel;
      this.subscriptions.push(
        this.techfaceProvider
          .signIn(this.user)
          .pipe(
            map((user) => {
              if (user && user.email.toLocaleLowerCase() === form.value.username_email.toLocaleLowerCase()) {
                if (user.verify) {
                  localStorage.setItem('username', user.email);
                  localStorage.setItem('firstname', user.username);
                  localStorage.setItem('userId', user._id);
                  returnUser = user;
                  return {
                    user,
                  };
                } else {
                  alert('please confirm your email before login');
                }
              }
            }),
            mergeMap(() => this.userService.getToken(this.user)),
            map(() => {
              if (returnUser?.companyId) {
                localStorage.setItem('companyId', returnUser.companyId);
                this.navigationService.navigateByUrl('dashboard');
              } else if (returnUser?.candidateId) {
                return {
                  user: returnUser,
                };
              }
            }),
            mergeMap(() =>
              returnUser?.candidateId
                ? this.techfaceProvider.getCandidateById(returnUser.candidateId)
                : EMPTY
            ),
            map((candidate) => {
              localStorage.setItem('candidateId', returnUser.candidateId);
              if (candidate) {
                if (candidate?.city && candidate?.country) {
                  this.navigationService.navigateByUrl('me');
                } else {
                  this.navigationService.navigateByUrl('me/edit');
                }
              }
            })
          )
          .subscribe()
      );
    }
    this.subscriptions.push(
      this.techfaceProvider.signInError().subscribe((error) => {
        if (error) {
          switch (error.status) {
            case 404: {
              this.loginError = true;
            }
          }
        }
      })
    );
  }
  registerAsCandidate(): void {
    this.navigationService.navigateByUrl('signup/candidate');
  }
  forgotPassword(): void {
    this.navigationService.navigateByUrl('forgot-password');
  }


  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscribe) => {
      subscribe.unsubscribe();
    });
  }
}
