<div class="queries-summary-container">

  <button class="btn btn-primary new-query" (click)="onClickFindQuery()">
      <div style="font-weight: bold; margin-bottom: 1em;">FIND AN EMPLOYEE</div>
      <div>by creating a new search query</div>
  </button>
  <div class="separate-line">
    <div class="vertical-line"></div>
  </div>
  <div class="summary-container">
    <div *ngFor="let querySummaryData of queriesSummaryData;" class="summary-entry">
      <div class="number">{{querySummaryData.number}}</div>
      <div class="number-description">
        {{querySummaryData.querySummaryDetails}}
      </div>
  </div>
  </div>

</div>
