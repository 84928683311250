<div class="content">
  <h1>Create your account</h1>

  <form
    [formGroup]="signInForm"
    #myForm="ngForm"
    (ngSubmit)="submit(signInForm)"
    id="login-form"
  >
    <div class="form-group">
      <label>Name*</label>
      <div>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="name"
            name="name"
            id="name"
            placeholder="Please enter"
          />
          <mat-error class="error-msg" id="name-invalid">
            enter correct name</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <label>Last Name*</label>
      <div>
        <mat-form-field appearance="outline">
          <input
            matInput
            formControlName="lastName"
            name="lastName"
            id="lastName"
            placeholder="Please enter"
          />
          <mat-error class="error-msg" id="lastName-invalid">
            enter correct last name</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="form-group">
      <label>E-mail*</label>
      <div>
        <mat-form-field appearance="outline">
          <input
            matInput
            type="email"
            formControlName="email"
            name="email"
            id="email"
            placeholder="Please enter"
          />
          <mat-error class="error-msg" id="email-invalid">
            enter correct email</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="submit-btn">
      <button class="btn btn-primary" type="submit">Set your password</button>
      <button class="btn btn-link" (click)="forgotPassword()">Forgot password</button>
    </div>
  </form>
</div>
<div class="content">
  <h1>Already have an account?</h1>
  <button class="btn btn-secondary" (click)="navigateToLogin()">Go to login</button>
</div>
