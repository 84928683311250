import { Component, HostListener, OnInit } from '@angular/core';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { IQuery } from '../../../shared/interfaces/query.interface';
import { SharedDataService } from '../../../shared/services/services/shared-data.service';
import { MatTableDataSource } from '@angular/material/table';
import { QueryStatus } from '../../../shared/enums/status.enum';
import { CompanyStatus } from '../../../shared/enums/companyStatus.enum';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { MatchesService } from '../../../shared/services/services/matches.service';
import { timer } from 'rxjs';
import { AutoRefreshTimer } from '../../../shared/enums/autoRefreshTimer.enum';

@Component({
  selector: 'app-latest-query',
  templateUrl: './latest-query.component.html',
  styleUrls: ['./latest-query.component.scss'],
})
export class LatestQueryComponent implements OnInit {
  displayedColumns: string[] = [
    'status',
    'name',
    'matches',
    'creationDate',
    'unreadMsgs',
  ];
  queries: IQuery[] = [];
  queriesIds: string[]= [];
  dataSource: any;

  constructor(
    private techfaceProvider: TechfaceProvider,
    private matchesService: MatchesService,
    private sharedDataService: SharedDataService,
    private navigationService: NavigationService
  ) {}

  @HostListener('unloaded')
  ngOnInit(): void {
      this.bindingQueries();
  }

  /**
   * get queries from store then binding it to queries.
   */
  bindingQueries(): void {
    this.techfaceProvider
      .getQueriesByCompanyById(localStorage.getItem('companyId'))
      .subscribe((queries) => {
        if (queries) {
          queries.forEach((query) => {
            this.queries.push({
              id: query._id,
              name: query.name,
              status: query.status,
              jobTitle: query.jobTitle,
              description: query.description,
              industries: query.industries,
              applicationDeadline: query.applicationDeadline,
              skills: query.skills,
              expertises: query.expertises,
              companyId: query.companyId,
              languages: query.languages,
              startDate: query.startDate,
            });
            this.queriesIds.push(query._id)
          });
          this.getMatchesForQueries();

      
          this.dataSource = new MatTableDataSource<IQuery>(this.queries);
          this.sharedDataService.changeOpenQueriesCount(
            this.getOpenQueriesCount()
          );
        }
      });
  }
  /**
   * count Open Queries.
   * @param query
   */
  getOpenQueriesCount(): number {
    return this.queries.reduce(
      (count, current) =>
        current.status === QueryStatus.active ? count + 1 : count,
      0
    );
  }

  getMatchesCount(): number {
    return this.queries.reduce(
      (count, current) =>
        current.status === QueryStatus.active
          ? count + (current.matches ? current.matches.length : 0)
          : count,
      0
    );
  }

  getConfirmedMatchesCount(): number {
    return this.queries.reduce(
      (count, current) =>
        current.status === QueryStatus.active
          ? count +
            (!current.matches
              ? 0
              : current.matches.reduce(
                  (confirmedCount, match) =>
                    match.candidateStatus === CompanyStatus.confirmed
                      ? confirmedCount + 1
                      : confirmedCount,
                  0
                ))
          : count,
      0
    );
  }

  /**
   * count matches and active conversation Queries.
   * @param query
   */
  getMatchesForQueries(): void {
    let unreadMsgs = 0;
    this.matchesService
      .getMatchesByQueriesIdAndCompanyId(this.queriesIds, localStorage.getItem('companyId')).subscribe(matchResponse => {
        this.queriesIds.forEach(queryId => {
          let matchesByqueryId = []
          matchResponse.matches.forEach(matches => matches.matchedQueries.forEach(matchedQueries => {
            if(matchedQueries === queryId) {
              matchesByqueryId.push(matches)
            }}))
          
          if (matchesByqueryId) {
            const matches = matchesByqueryId;
            matches.forEach((match) => {
              unreadMsgs += match.messages.filter(
                (msg) => msg.isCompanySeen === false
              ).length;
            });
            const query = this.queries.find(
              (queryItem) => queryItem.id === queryId
            );
            if (query) {
              query.matches = matches;
              query.unreadMsgs = unreadMsgs;
            }
          }
          this.sharedDataService.changeMatchesQueriesCount(
            this.getMatchesCount()
          );
          this.sharedDataService.changeActiveConversationQueriesCount(
            this.getConfirmedMatchesCount()
          );
        });
      })
  }

  goToQuery(query: IQuery) {
    this.navigationService.navigateByUrl(`queries/${query.id}`);
  }
}
