export const CANDIDATE_ACCEPT='accept';
export const CANDIDATE_DISCARD='discard';
export enum MatchActionTypes{
  ACCEPT='accept',
  DISCARD='discard',
}
export class MatchTypeModel {
  type: MatchActionTypes.ACCEPT | MatchActionTypes.DISCARD;
  id: string;
}
