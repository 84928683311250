import { Component, Input, OnInit } from '@angular/core';
import { CandidateUserModel } from '../../../shared/models/candidateUser.model ';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';

@Component({
  selector: 'app-candidate-sign-in-confirm',
  templateUrl: './candidate-sign-in-confirm.component.html',
  styleUrls: ['./candidate-sign-in-confirm.component.scss'],
})
export class CandidateSignInConfirmComponent implements OnInit {
  @Input() candidateData: CandidateUserModel;
  userErrorMsg: string;
  constructor(private navigationService: NavigationService) {}

  ngOnInit(): void {}

  onClickGoToLogin(): void {
    this.navigationService.navigateByUrl('login');
  }
}
