<div class="about-candidate-container">
  <div [ngClass]="{'no-image' : !canSeeCandidate || !candidate?.image}" class="img">
    <div *ngIf="!canSeeCandidate" class="lock-profile">
      Send connection request to candidate to view full profile
    </div>
    <img *ngIf="canSeeCandidate && candidate?.image" src="{{candidate?.image}}">
  </div>
  <div class="about-candidate-wrapper">
    <div *ngIf="canSeeCandidate" class="candidate-name">{{candidate?.firstName}} {{candidate?.lastName}}
    </div>
    <div class="candidate-title">{{candidate?.title}}</div>
    <div class="short-bio" [innerHTML]="candidate?.imageDescription"></div>
  </div>
</div>
