<div class="container">
  <div *ngIf="!isCandidateMyself()" class="header">
    <h1 *ngIf="canSeeCandidate()">
      {{ aboutCandidateObj?.firstName }} {{ aboutCandidateObj?.lastName }}
    </h1>
    <h1 *ngIf="!canSeeCandidate()">Candidate Profile</h1>
    <div class="actions">
      <button
        *ngIf="!isCandidateMyself() && !isDiscarded()"
        class="btn btn-secondary"
        (click)="companyStatusClick('REJECTED')"
      >
        <img src="../../../assets/icons/ban.png" /> No longer interested
      </button>
      <button
        *ngIf="!isCandidateMyself()"
        [ngClass]="{ disabled: communicationRequested() }"
        [disabled]="communicationRequested()"
        style="margin-left: 1em"
        class="btn btn-primary"
        (click)="companyStatusClick('CONFIRMED')"
      >
        <img src="../../../assets/icons/comment.png" />
        <span *ngIf="!communicationRequested()">Send connection request to candidate</span>
        <span *ngIf="communicationRequested() && !canSeeCandidate()"
          >Waiting for Candidate Approval</span
        >
        <span *ngIf="communicationRequested() && canSeeCandidate()"
          >Matched</span
        >
      </button>
    </div>
  </div>

  <div *ngIf="isCandidateMyself()" class="sub-nav">
    <div
      *ngFor="let step of steps; let index = index"
      [ngClass]="{ active: index === activeStep }"
      (click)="onClick(index)"
    >
      <div class="display">
        <a>{{ step }} </a
        ><span *ngIf="index === 1 && unreadMsgs > 0" class="dot"> {{ unreadMsgs }}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!isCandidateMyself()" class="sub-nav">
    <div
      *ngFor="let step of steps; let index = index"
      [ngClass]="{ active: index === activeStep }"
      (click)="onClick(index)"
    >
      <div class="display">
        <a>{{ step }} </a
        ><a *ngIf="index === 1 && unreadMsgs > 0" class="dot"> {{ unreadMsgs }}</a>
      </div>
    </div>
  </div>
  <div *ngIf="activeStep === 0" class="col1">
    <div class="card">
      <h2 class="card-title">
        About {{ canSeeCandidate() ? aboutCandidateObj?.firstName : "" }}
      </h2>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(1)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-about-candidate
          [candidateAndCompanyStatus]="candidateAndCompanyStatus"
          [candidate]="aboutCandidateObj"
          [canSeeCandidate]="canSeeCandidate()"
        ></app-about-candidate>
      </div>
    </div>
    <div class="card">
      <div class="card-title">Basic Information</div>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(7)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body basic-info">
        <div *ngIf="basicInfoOfCandidateObj?.availableFrom" class="details">
          <img src="../../../assets/icons/calendar.svg" />
          <span
            >Available from
            {{
              basicInfoOfCandidateObj?.availableFrom | date: "dd.MM.YYYY"
            }}</span
          >
        </div>
        <div *ngIf="basicInfoOfCandidateObj?.workPermit" class="details">
          <img src="../../../assets/icons/workpermit.svg" />
          <span> Work Permit: {{ basicInfoOfCandidateObj?.workPermit }}</span>
        </div>
        <div *ngIf="basicInfoOfCandidateObj?.availabiltyFrom" class="details">
          <img src="../../../assets/icons/clock.svg" />
          <span>
            From {{ basicInfoOfCandidateObj?.availabiltyFrom }}% to
            {{ basicInfoOfCandidateObj?.availabiltyTo }}%</span
          >
        </div>

        <div *ngIf="basicInfoOfCandidateObj?.preferredLocation" class="details">
          <img src="../../../assets/icons/location.svg" />
          <span>{{ basicInfoOfCandidateObj?.preferredLocation }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-title">Languages</div>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(0)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body language">
        <div
          class="details"
          *ngFor="let language of basicInfoOfCandidateObj?.languages"
        >
          <img src="../../../assets/icons/language.svg" />
          <span>{{ language }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="activeStep === 0" class="col2">
    <div class="card skills">
      <h2 class="card-title">Skills</h2>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(4)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-skills-expertise
          [topMatchMetaData]="topMatchSkills"
          [candidateMetaData]="candidateSkills"
          [isCandidateMyself]="isCandidateMyself()"
          [title]="'skills'"
        >
        </app-skills-expertise>
      </div>
    </div>
    <div class="card">
      <h2 class="card-title">Curriculum Vitae</h2>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(5)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-cv [canSeeCandidate]="canSeeCandidate()" [cv]="cvObj"></app-cv>
      </div>
    </div>
  </div>
  <div *ngIf="activeStep === 0" class="col3">
    <div class="card">
      <h2 class="card-title">Expertises</h2>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(4)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-skills-expertise
          [topMatchMetaData]="topMatchExpertises"
          [candidateMetaData]="candidateExpertises"
          [isCandidateMyself]="isCandidateMyself()"
          [title]="'Expertise'"
        >
        </app-skills-expertise>
      </div>
    </div>

    <div class="card">
      <h2 class="card-title">Certificates</h2>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(6)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-certificate *ngIf="canSeeCandidate()" [certificates]="certificatesObj"></app-certificate>
        <div *ngIf="!canSeeCandidate()">Send connection request to candidate to see certificates</div>
      </div>
    </div>
    <div class="card">
      <div class="card-title">Documents</div>
      <a *ngIf="isCandidateMyself()" class="edit" (click)="navigateToEdit(2)"
        ><img alt="Edit Candidate" src="../../../assets/icons/edit.svg"
      /></a>
      <div class="card-body">
        <app-documents *ngIf="canSeeCandidate()" [documents]="docsObj"></app-documents>
        <div *ngIf="!canSeeCandidate()">Send connection request to candidate to see documents</div>
      </div>
    </div>
  </div>
  <div 
   class="msg">
    <app-conversation
    [ngClass]="{ 'conv-display': activeStep === 0 }"
      #conversation
      [candidateId]="candidateId"
      (emitCompanyMatch)="companyStatusClick($event)"
    ></app-conversation>
  </div>
</div>
