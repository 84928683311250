<div class="latest-query-container">
  <h5 class="latest-query-title">LATEST QUERIES</h5>
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="status {{ element.status }}"></span>
        {{ element.status }}
      </mat-cell>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>

    <!-- Matches Column -->
    <ng-container matColumnDef="matches">
      <mat-header-cell *matHeaderCellDef> Matches </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.matches?.length || 0 }}
      </mat-cell>
    </ng-container>

    <!-- Creation Date Column -->
    <ng-container matColumnDef="creationDate">
      <mat-header-cell *matHeaderCellDef> Starting Date </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.startDate | date: "dd.MM.YYYY" }}
      </mat-cell>
    </ng-container>

    <!-- Unread msgs Column -->
    <ng-container matColumnDef="unreadMsgs">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="unread-mgs">{{ element.unreadMsgs }} New Messages</div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="goToQuery(row)"
    ></mat-row>
  </mat-table>
</div>
