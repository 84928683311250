export const environment = {
  production: true,
  modeso: {
    apiGateway: 'https://api.techface.modesoventures.com/api/',
    publicAssetsBaseurl: 'https://storage.googleapis.com/techface-doc-store/',
  },
  defaultLanguage: 'en-us',
  availableLanguages: ['en-us'],
  enableDebugging: '',
};
