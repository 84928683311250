import { Injectable } from '@angular/core';
import { IDuration } from '../../interfaces/duration.interface';

@Injectable({
  providedIn: 'root'
})
export class DurationService {

  constructor() { }

  getTotalDays(startDate: Date, endDate: Date): number {
    const diff = endDate.getTime() - startDate.getTime();
    return Math.floor(diff / (60 * 60 * 24 * 1000));
  }

  getDateDiff(totalDays: number): IDuration {
    const years = Math.floor(totalDays / 365);
    const restOfYears = Math.round((totalDays / 365 - years) * 365);
    const months = Math.floor(restOfYears / 30);
    return { years, months };
  }
}
