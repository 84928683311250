import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { TechfaceProvider } from '../../../shared/providers/techface.provider';
import { IQuery } from '../../../shared/interfaces/query.interface';
import { ActivatedRoute } from '@angular/router';
import { GetMetaDataService } from '../../../shared/services/metaData/metaData.service';
import { MetaDataIdsModel } from '../../../shared/models/metaDataIds.model';
import { NavigationService } from '../../../shared/services/navigation/navigation.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Types } from '../../../shared/enums/types.enum';

@Component({
  selector: 'app-query-overview',
  templateUrl: './query-overview.component.html',
  styleUrls: ['./query-overview.component.scss']
})
export class QueryOverviewComponent implements OnInit, OnDestroy {
  private readonly onDestroy = new Subject<void>();

  queryId: string;
  public query: any;
  constructor(
    private navigationService: NavigationService,
    private techfaceProvider: TechfaceProvider,
    private route: ActivatedRoute) { }

  @HostBinding("unload")
  ngOnInit(): void {
    this.getQuery();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  /**
   * get query and biding by call binding Query Data function
   */
  getQuery(): void {
    this.route.params
    .pipe(takeUntil(this.onDestroy))
    .subscribe(params => {
      this.queryId = params.queryId;
      this.techfaceProvider.getQueryById(this.queryId).subscribe(query => {
        if (query) {
          this.bindingQueryData(query);
          this.getMetaData(query);
        }
      });
    });
  }

  /**
   * pop up to display description
   */
  showJobDescription(): void {
    swal.fire(this.query.jobTitle, this.query.description);
  }

  editQuery(): void {
    this.navigationService.navigateByUrl(`queries/${this.queryId}/edit`);
  }

  /**
   * binding data
   * @param query
   */
  bindingQueryData(query: IQuery): void {
    this.query = {
      name: query.name,
      status: query.status,
      jobTitle: query.jobTitle,
      description: query.description,
      industries: [],
      availabilityNeeded: query.availabilityNeeded,
      languages: [], // query.languages,
      startDate: query.startDate,
      applicationDeadline: query.applicationDeadline,
      skills: [],
      expertises: [],
      companyId: query.companyId
    };
  }

  getMetaData(query: IQuery): void {
    this.techfaceProvider.getAllMetaData()
    .pipe(takeUntil(this.onDestroy))
    .subscribe(metadata => {
      if (metadata) {
        this.query.languages = query.languages.map((idObj) => metadata.languages.find(m => m._id === idObj.languageId)).map(o => o.name);
        this.query.skills = query.skills.map((idObj) => 
         metadata.skillsExpertises.find(m => m._id === idObj.skillId)).filter(skill => skill && skill.type === Types.SKILL)
         .map(o => o.name);
        this.query.expertises = query.expertises.map((idObj) =>
         metadata.skillsExpertises.find(m => m._id === idObj.expertiseId)).filter(expertise => expertise && expertise.type === Types.EXPERTISE)
         .map(o => o.name);
      }
    });
  }

}
