<div class="document-validation-contianer"
  *ngIf="(candidateData?.cvDocument && candidateData?.cvDocument.url !=='') && !validNewDocuments">
  <span>Thank you, {{ candidateData?.firstName }}!</span>
  <p>
    Your documents are fully validated and up-to-date.
  </p>
  <div class="validation-successful validation-height">
    <div class="validation-successful">
      <img src="assets/icons/validation.svg" alt="img" /><span>Validation successful.</span>
    </div>
  </div>
</div>
<div class="document-validation-contianer" *ngIf="!candidateData?.cvDocument || candidateData?.cvDocument.url ===''">
  <span>Hi, {{ candidateData?.firstName }}!</span>
  <p>
    Please upload your documents so that we can validate your skills and expertise.
  </p>
  <div class="validation-successful validation-height">
    <div class="validation-successful">
      <img src="assets/icons/validation.svg" alt="img" /><span>No documents to validate yet.</span>
    </div>
  </div>
</div>
<div class="document-validation-contianer"
  *ngIf="(candidateData?.cvDocument && candidateData?.cvDocument.url !=='') && validNewDocuments">
  <span>Thank you, {{ candidateData?.firstName }}!</span>
  <p>
    We need some time to process and verify your documents. You can still proceed with the setup your profile.
  </p>
  <div class="validation-successful">
    <div class="validation-successful">
      <img src="assets/icons/validation.svg" alt="img" /><span>Currently validating...</span>

    </div>
  </div>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>

</div>