import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password/forgot-password.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { PagesRouterModule } from '../app/pages/page-router.module';
import { Injector } from '@angular/core';
import { SharedModule } from './shared/module/shared/shared.module';
import { NavigationAuthGuard } from './shared/services/guards/NavigationAuthGuard.service';
import { ITechfaceConfig, TechfaceConfigService } from './techface.config';
import { CompaniesService } from './shared/services/services/companies.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TechfaceEffects } from './shared/effects/techface.effects';
import * as fromReducer from './shared/reducers/techface.reducer';
import { environment } from '../environments/environment';
import { DashboardGuard } from './shared/services/guards/DashboardGuard';

const techfaceConfig: ITechfaceConfig = {
  apiGateway: environment.modeso.apiGateway,
};

@NgModule({
  declarations: [AppComponent, LoginPageComponent, ForgotPasswordPageComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    PagesRouterModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    StoreModule.forFeature(fromReducer.techfaceFeatureKey, fromReducer.reducer),
    EffectsModule.forFeature([TechfaceEffects]),
    StoreModule.forRoot([]),
    EffectsModule.forRoot([TechfaceEffects]),
    PagesRouterModule.forRoot(techfaceConfig),
  ],
  providers: [NavigationAuthGuard, DashboardGuard],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
  public static forRoot(
    config: ITechfaceConfig
  ): ModuleWithProviders<AppModule> {
    return {
      ngModule: AppModule,
      providers: [
        CompaniesService,
        {
          provide: TechfaceConfigService,
          useValue: config,
        },
      ],
    };
  }
}
export let InjectorInstance: Injector;
