<div class="work-experience-container">
  <span>Thank you, {{ candidateWorkExperience?.firstName ? candidateWorkExperience?.firstName : "" }}!</span>
  <p style="margin-bottom: 2em;">
    We are currently working on a solution to retrieve your work experiences automatically from your documents. In the meantime, please add them manually.
  </p>
  <form [formGroup]="workExperience" #myForm="ngForm">
    <div formArrayName="cv">
      <div
        *ngFor="let cv of workExperience.get('cv')['controls']; let i = index"
      >
        <div class="title-remove">
          <div class="title">WORK EXPERIENCE {{ i + 1 }}</div>
          <div class="remove" (click)="onclickRemove(i)">x</div>
        </div>
        <div class="work-experience-wrapper" [formGroupName]="i">
          <div class="line">
            <div>
              <label>Employer</label>
              <div>
                <input
                  matInput
                  formControlName="company"
                  placeholder="Please enter employer"
                />
              </div>
            </div>
            <div class="dates">
              <div class="date-from">
                <label>From</label>
                <div>
                  <div>
                    <mat-form-field appearance="outline">
                      <input
                        formControlName="from"
                        id="from"
                        name="from"
                        matInput
                        [max]="maxDate"
                        [matDatepicker]="picker"
                        placeholder="Choose a date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker startView="year"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="date-to">
                <label>To</label>
                <div>
                  <mat-form-field appearance="outline">
                    <input
                      formControlName="to"
                      id="to"
                      name="to"
                      [min]="workExperience.value.cv[i].from"
                      [max]="maxDate"
                      matInput
                      [matDatepicker]="picker1"
                      placeholder="Choose a date"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="picker1"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker1 startView="year"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="line">
            <div>
              <label>Job Position</label>
              <div>
                <input
                  matInput
                  formControlName="role"
                  name="role"
                  id="role"
                  placeholder="Please enter jop position"
                />
              </div>
            </div>
            <div>
              <label>Location</label>
              <div>
                <input
                  matInput
                  formControlName="location"
                  name="location"
                  id="location"
                  placeholder="Please enter location"
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <button
        class="add-btn btn btn-secondary"
        (click)="onClickAddExperience()"
      >
        Add experience
      </button>
    </div>
  </form>
</div>
