import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MetaDataNameModel } from '../../shared/models/metaDataName.model';
import { SkillModel } from '../../shared/models/skill.model';
@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss']
})
export class SkillsComponent implements OnInit {
  @Output() skillEvent = new EventEmitter();
  @Output() addSkills = new EventEmitter();

  @Input() skills: MetaDataNameModel[];
  @Input() canEdit: boolean;
  @Input() error: boolean;

  removable = true;
  constructor() {
  }

  ngOnInit(): void {
  }


  onAdd(): void {
    this.addSkills.emit();
  }

  remove(mySelections): void {
    const index = this.skills.indexOf(mySelections);
    if (index >= 0) {
      this.skills.splice(index, 1);
    }
    this.skillEvent.emit(this.skills);

  }
}
