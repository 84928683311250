import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IUpdateCandidate } from '../../../../shared/interfaces/updateCandidate.interface';
import { CandidateModel } from '../../../../shared/models/candidate.model';
import { TechfaceProvider } from '../../../../shared/providers/techface.provider';

@Component({
  selector: 'app-work-experience',
  templateUrl: './work-experience.component.html',
  styleUrls: ['./work-experience.component.scss'],
})
export class WorkExperienceComponent implements OnInit, OnChanges {
  workExperience: FormGroup;
  @Input() candidateWorkExperience: CandidateModel;
  control: any;
  maxDate = new Date();
  constructor(
    private fb: FormBuilder,
    private techfaceProvider: TechfaceProvider
  ) {}
  ngOnChanges(): void {
    this.workExperience = this.fb.group({
      cv: this.fb.array([]),
    });
    this.control = <FormArray>this.workExperience.get('cv');
    this.patch();
  }

  ngOnInit(): void {}
  onClickAddExperience(): void {
    this.control.push(this.patchValues('', undefined, '', '', undefined));
  }
  onclickRemove(index): void {
    this.control.removeAt(index, 1);
  }

  patch(): void {
    this.candidateWorkExperience?.cv.forEach((cv) => {
      this.control.push(
        this.patchValues(cv.company, cv.from, cv.location, cv.role, cv.to)
      );
    });
  }

  patchValues(
    company: string,
    from: Date,
    location: string,
    role: string,
    to: Date
  ): any {
    return this.fb.group({
      company: [company],
      from: [from],
      location: [location],
      role: [role],
      to: [to],
    });
  }
  updateCandidate(): void {
    const candidateObj: IUpdateCandidate = {
      id: localStorage.getItem('candidateId'),
      candidateObj: {
        cv: this.workExperience.value.cv.filter(cv => !!cv.company),
      },
    };
    this.techfaceProvider.updateCandidate(candidateObj).subscribe();
  }

  submit(): void {
    this.updateCandidate();
  }
}
