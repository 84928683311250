import { NgModule } from '@angular/core';
import { LoginPageComponent } from '../app/pages/login-page/login-page.component';
import { Routes, RouterModule } from '@angular/router';
import { SignInCandidatePageComponent } from './pages/sign-in-candidate-page/sign-in-candidate-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password/forgot-password.component';

const routes: Routes = [
  { path: ':lang/login', component: LoginPageComponent },
  { path: ':lang/signup/candidate', component: SignInCandidatePageComponent },
  { 
    path: ':lang/forgot-password',
    children: [
      {
        path: '',
        component: ForgotPasswordPageComponent,
      },
      {
        path: ':token',
        component: ForgotPasswordPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
