import { Component } from '@angular/core';

@Component({
  selector: 'app-page-router',
  templateUrl: './page-router.component.html',
  styleUrls: ['./page-router.component.scss'],
})
export class PageRouterComponent {
  companyId: string;
  candidateId: string;
  constructor() {}
}
