<div class="faq-container">
    <h1>Frequently asked questions</h1>

    <h2>How do we define Skills?</h2>
    <p>When we talk about skills, we focus on domain independent skills that are required to fulfil certain job requirements. In some cases, these are also defined as soft skill, such as leadership, negotiation, or communication skills.</p>
  
    <h2>How do we define Expertise?</h2>
    <p>When we talk about expertise, we are focusing on domain-specific skill or knowledge. These are gained by education and or on the job training, such as Software Engineering, Data Analysis or JavaScript.</p>
 
    <h2>What does the red or green symbol next to a skill or expertise mean?</h2>
    <p>
        The color indicates if a skill or expertise has been validated. This means, in addition to what the candidate indicates in the CV, further proof has been provided and checked, such as reference letter or certificate.
        A star symbol indicates a favorite skill of the candidate.
    </p>
  
    <h2>What does &quot;Top Matches&quot; mean for skills and expertise?</h2>
    <p>These are the skill and expertise you have selected in your query. If the candidate has indicated to have the same skill or expertise, they are highlighted on the matched profile.</p>

    <h2>Why can’t I see the name or documents of a candidate?</h2>
    <p>If you can’t see the name or documents of a candidate, it means the match has not been accepted by the company and/or the candidate. Please check if you have confirmed the match. </p>

    <h2>What status can a match have?</h2>
    <p>Once you submitted a query, we will manually match the candidates to the query. This might take a day or two. On the top right of each Candidate Profile, you will see two buttons. </p>
    <p>The first one is to “Discard match” if you do not want to meet the candidate anymore.</p>
    <p>The second one provides you following options:</p>
        <ul>
            <li>Confirm Match: the candidate has been matched to your query, click on the button if you want to meet the candidate.</li>
            <li>Waiting for Candidate Approval: you have approved the match, but the candidate has not yet confirmed</li>
            <li>Matched: you will meet the candidate and have access to all information.</li>
        </ul>
    <h2>How can I delete my account?</h2>
    <p>Please send your request to <a href="mailto:support@techface.ch">support@techface.ch</a>. We will delete your account within 48 business hours. Keep in mind that you will lose all your profile data and messages and connections to companies.</p>
    <h2>How can I get support?</h2>
    <p>Please send us a message to <a href="mailto:support@techface.ch">support@techface.ch</a> for any further support.</p>
</div>