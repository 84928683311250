import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnChanges } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Options } from 'ng5-slider';
import { Avaibality } from '../../shared/enums/availability.enum';
import { BasicInfoModel } from '../../shared/models/basicInfo.model';
import { MetaDataModel } from '../../shared/models/metaData.model';
import { MetaDataNameModel } from '../../shared/models/metaDataName.model';

@Component({
  selector: 'app-basic-info',
  templateUrl: './basic-info.component.html',
  styleUrls: ['./basic-info.component.scss']
})
export class BasicInfoComponent implements OnChanges {

  @Output() basicInfoEvent = new EventEmitter();
  @ViewChild('basicInfoForm') public form: NgForm;
  @Input() basicInfo: BasicInfoModel;
  @Input() languageMetaData: MetaDataNameModel[];
  @Input() industryMetaData: MetaDataNameModel[];
  @Input() error: any = {};
  availabilty: FormControl;
  options: Options = {
    floor: 0,
    ceil: 100,
    step: 10,
    translate: (value: number): string => {
      return value + '%';
    },
  };


  public workPensum: number[] = [];

  startDate = new Date();

  constructor() {
  }

  ngOnChanges() {
    this.setWorkPensum();
  }

  setWorkPensum(): void {
      this.availabilty = new FormControl([
        this.basicInfo?.availabilityNeeded[0],
        this.basicInfo?.availabilityNeeded[1],
      ]);
      this.availabilty.valueChanges.subscribe(value => {
        this.basicInfo.availabilityNeeded = value;
        this.basicInfoEvent.emit(this.basicInfo);
      })
  }

  compareMetadata(a: MetaDataNameModel, b: MetaDataNameModel): boolean {
    return a && b && a.id === b.id;
  }

  onChange(): void {
    this.basicInfoEvent.emit(this.basicInfo);
  }

}
